import { applyMiddleware, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import authReducer from './auth/reducer';
import workOrderReducer from './workOrder/reducer';
import patrimonyReducer from './patrimony/reducer';
import marketReducer from './market/reducer';
import interventionReducer from './workOrder/intervention/reducer';
import userReducer from './user/reducer';
import statusReducer from './workOrder/status/reducer';
import workTypeReducer from './workType/reducer';
import consentReducer from './workOrder/consent/reducer';
import { all } from 'redux-saga/effects';
import { authSaga } from './auth/saga';
import { workOrderSaga } from './workOrder/saga';
import { patrimonySaga } from './patrimony/saga';
import { interventionSaga } from './workOrder/intervention/saga';
import { statusSaga } from './workOrder/status/saga';
import { consentSaga } from './workOrder/consent/saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { marketSaga } from './market/saga';
import { userSaga } from './user/saga';
import { workTypeSaga } from './workType/saga';

const reducer = combineReducers({
    auth: authReducer,
    workOrder: workOrderReducer,
    patrimony: patrimonyReducer,
    market: marketReducer,
    intervention: interventionReducer,
    status: statusReducer,
    user: userReducer,
    workType: workTypeReducer,
    consent: consentReducer,
});

const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState) => {
    // Create redux store
    const store = createStore(
        reducer,
        initialState,
        composeWithDevTools(applyMiddleware(sagaMiddleware))
    );
    sagaMiddleware.run(function* sagas() {
        yield all([
            authSaga(),
            workOrderSaga(),
            patrimonySaga(),
            marketSaga(),
            interventionSaga(),
            statusSaga(),
            userSaga(),
            workTypeSaga(),
            consentSaga(),
        ]);
    });

    return store;
};

export default configureStore();