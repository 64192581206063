import { CheckIcon, XIcon } from '@heroicons/react/outline';

const CloseButton = ({ clicked, name = 'Fermer' }) => {
  return (
    <button
      type="button"
      className="bg-transparent rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      onClick={clicked}
    >
      <span className="sr-only">{name}</span>
      <XIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
};

export default CloseButton;
