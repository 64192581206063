import api from '../../Api';

export function getMarkets({ page = 1, size = 15, workType = null, program = null }) {
  let url = `/markets?page[number]=${page}&page[size]=${size}&programs=${program}&include=service_provider,unit_price`;
  if (workType) {
    url += `&type[eq]=${workType}`
  }
  return api.get(url);
}

export function getMarket({ uid, include }) {
  return api.get(`/markets/${uid}${include ? '?include='+include.join(',') : ''}`);
}

export function getServiceProviders({ page = 1, size = 15 }) {
  return api.get(`/service-providers?page[number]=${page}&page[size]=${size}`);
}

export function getUnitPrices({ page, size = 1000, program, workType }) {
  return api.get(`/unit-price-lists?\
    page[number]=${page}&page[size]=${size}
    &programs=${program}\
    &type[eq]=${workType}\
    &is_active[eq]=1\
    &include=market,service_provider`);
}

export function getUnitPriceItems({ unitPriceUid ,page = 1, size = 15, moduleType, roomsNumber = null }) {
  let yourDate = new Date();
  let yourDate2 = yourDate.toISOString().split('T')[0];
  let url = `/unit-price-lists/${unitPriceUid}/items?page[number]=${page}\
    &page[size]=${size}&sort=label&start_at[lt]=${yourDate2}|null&end_at[gt]=${yourDate2}|null`;
  if (roomsNumber) url += `&rooms_number[eq]=${roomsNumber}|null`;
  if (moduleType) url += `&workable_is_${moduleType}[eq]=1`;
  url += '&include=must_select,cant_select,must_select_inherited,cant_select_inherited,list';
  return api.get(url);
}
