import { Badge } from '@components';

const WorkOrderBadges = ({ workOrder, abbreviate }) => {
  return (
    <>
      {workOrder.validation && workOrder.validation.is_valid ? 
        <Badge text={abbreviate ? 'vAPP' : 'Validé (APP)'} type="success" /> : <Badge text={abbreviate ? 'vAPP' : 'En attente (APP)'} type="warning" />}
      {workOrder.software_validation_status === 'validated' && 
        <Badge text={abbreviate ? 'vIW' : 'Validé (IW)'} type="success" />}
      {workOrder.software_validation_status === 'pending' &&
        <Badge text={abbreviate ? 'vIW' : 'En attente (IW)'} type="warning" />}
      {workOrder.software_validation_status === 'refused' &&
        <Badge text={abbreviate ? 'vIW' : 'Refusé (IW)'} type="error" />}
      {workOrder.consent ?
        <>
          {!workOrder.consent.email_sent_on && <Badge text="Non envoyé (Locataire)" type="warning" />}
          {workOrder.consent.email_sent_on && !workOrder.consent.refused_on && !workOrder.consent.consented_on && <Badge text="En attente (Locataire)" type="warning" />}
          {workOrder.consent.refused_on && <Badge text="Refusé (Locataire)" type="error" />}
          {workOrder.consent.consented_on && <Badge text="Accepté (Locataire)" type="success" />}
        </> :
        <></>
      }
        
    </>
  );
};

export default WorkOrderBadges;
