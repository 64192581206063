import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CommonDetails, Loader } from '@components';
import { 
  getModule,
  getFloor,
  getStaircase,
  getBuilding,
  getProgram
} from '@store/patrimony/actions';
import { isLoading } from '@store/patrimony/selector';
import { 
  selectedPatrimony,
} from '@store/patrimony/selector';
import { selectSingle, selectCurrent } from '@store/workOrder/selector';
import { setPatrimony } from '@store/workOrder/actions';

const CommonLoader = ({ 
  workable, 
  rowPadding, 
  colPadding, 
  onLoaded = null,
}) => {
  const [loadingPatrimonyStep, setLoadingPatrimonyStep] = useState(null);
  const [loadingPatrimonyRate, setLoadingPatrimonyRate] = useState(0);
  const [keepers, setKeepers] = useState([]);
  const dispatch = useDispatch();
  const current = useSelector(selectCurrent);
  const loading = useSelector(isLoading);

  const { 
    floor,
    staircase,
    building,
    program
  } = useSelector(selectedPatrimony);

  const loadPatrimonyData = () => {
    if (workable === 'program') {
      if (current.patrimony.program && (!program || program.uid !== current.patrimony.program.uid)) {
        dispatch(getProgram({ 
          uid: current.patrimony.program.uid, 
          include: ['buildings'] 
        }));
      }
    } else if (workable === 'building') {
      if (current.patrimony.building && (!building || building.uid !== current.patrimony.building.uid)) {
        dispatch(getBuilding({ 
          uid: current.patrimony.building.uid, 
          include: ['staircases','program'] 
        }));
      }
    } else if (workable === 'staircase') {
      if (current.patrimony.staircase && (!staircase || staircase.uid !== current.patrimony.staircase.uid)) {
        dispatch(getStaircase({ 
          uid: current.patrimony.staircase.uid,
          include: ['building','program'] 
        }));
      }
    }
  };

  const setWorkablePatrimony = ({ program,building,staircase }) => {
    onLoaded && onLoaded({
      program,
      building,
      staircase,
      floor:null,
      moduleData:null
    });
  };

  useEffect(() => {
    if (staircase && workable === 'staircase') {
      setWorkablePatrimony({
        staircase, 
        building: staircase.building, 
        program: staircase.program
      });
      setKeepers(staircase.keepers);
    } else if (building && workable === 'building') {
      setWorkablePatrimony({
        building, 
        program: building.program
      });
      setKeepers(building.keepers);
    } else if (program && workable === 'program') {
      setWorkablePatrimony({program});
      setKeepers(program.keepers);
    }
  }, [staircase, building, program, workable]);

  useEffect(() => {
    loadPatrimonyData();
  }, []);

  return (
    <div className="relative">
      <Loader loading={loading} />
      {current.patrimony &&
        <CommonDetails 
          program={current.patrimony.program} 
          building={current.patrimony.building} 
          staircase={current.patrimony.staircase}
          keepers={keepers}
          rowPadding={rowPadding} />
      }
    </div>
  );
};

export default CommonLoader;
