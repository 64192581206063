import {
  Form,
  FormSection,
  Textarea,
  Input,
  Checkbox,
  TaskCheckboxes,
  TaskSearch,
  Loader,
  Modal,
  Button,
} from '@/components'
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { selectCurrentTasks } from '@store/workOrder/selector';

const TaskSelection = ({ 
  label,
  options,
  moduleType,
  onChange,
  displayCheckboxes = false,
  maxSelection = null,
  toggleAllowed,
  cantSelect = [],
  mustSelect = [],
}) => {
  const [totalHT, setTotalHT] = useState(0);
  const [billable, setBillable] = useState(0);
  const [numberOfSelection, setNumberOfSelection] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mustSelectWarning, setMustSelectWarning] = useState(false);
  const workOrderTasks = useSelector(selectCurrentTasks);

  /*
   * Trigger option addition or removal on inherited options array
   * If option is checked, remove option
   * If option is uncheck and max selection isn't reached, then add option
   * See component StepTasks.jsx for onChange() trigger
   * 
   * /!\ If the option is a checkbox HTML element, we have to toggle it on DOM
   * otherwise there is no visual effect (vanilla javascript used)
   */
  const toggleTask = (id) => {
    let index = id.split('_')[1];
    let el = document.getElementById(id);
    if (options[index].checked) {
      options[index].checked = !options[index].checked;
      if (el) {
        el.checked = options[index].checked;
      }
      onChange(options);
    } else if (!maxSelection || numberOfSelection < maxSelection) {
      options[index].checked = !options[index].checked;
      if (el) {
        el.checked = options[index].checked;
      }
      onChange(options);
    }
  };

  /*
   * See component StepTasks.jsx for onChange() trigger
   */
  const setComments = (id, value) => {
    let index = id.split('_')[1];
    options[index].comments = value;
    onChange(options);
  };

  /*
   * See component StepTasks.jsx for onChange() trigger
   */
  const setQuantity = (id, value) => {
    let index = id.split('_')[1];
    options[index].quantity = value;
    onChange(options);
  };

  /*
   * Check if option removal is allowed - not mandatory for any other selected option
   * If other options are dependant on option, prevent event (don't remove)
   * and warn user with modal
   * See TaskCheckboxes.jsx for usage
   */
  const checkIfAllowedCheckbox = (event, option) => {
    if (event.isTrusted && option.checked) { // If event was fired by user action
      if (option.mustSelectFor && option.mustSelectFor.length > 0) {
        event.preventDefault();
        setMustSelectWarning(option);
      }
    }
  };

  /*
   * Check if option removal is allowed
   * If other options are dependant on option, prevent event
   * warn user with modal. Otherwise, perfom callback
   * See TaskSearch.jsx for usage
   */
  const checkIfAllowedSearch = (event, option, callback) => {
    // If event was fired by user action (isTrusted)
    if (event.isTrusted && option.checked) {
      if (option.mustSelectFor && option.mustSelectFor.length > 0) {
        event.preventDefault();
        setMustSelectWarning(option);
      } else {
        callback(option);
      }
    } else {
      callback(option);
    }
  };

  /*
   * Upon warning acceptation, remove target option and dependant options
   */
  const unselectRelated = () => {
    if (mustSelectWarning && mustSelectWarning.mustSelectFor) {
      // Remove dependant options
      mustSelectWarning.mustSelectFor.forEach(item => {
        var found = options.find(i => i.price.uid === item.price.uid);
        toggleTask(`task_${options.indexOf(found)}`);
      });
      // Remove target option
      var found = options.find(i => i.price.uid === mustSelectWarning.price.uid);
      toggleTask(`task_${options.indexOf(found)}`);
      // Hide modal
      setMustSelectWarning(false);
    }
  };

  useEffect(() => {
    if (options) {
      let totalTaskSelected = 0;
      let total = 0;
      let billable = 0;
      options.forEach((option, idx) => {
        let found = workOrderTasks.filter(task => task.uid === option.price.uid);
        if (option.checked) {
          totalTaskSelected += 1;
          total += (option.price.cost_excluding_tax * option.quantity);
          if (option.price.is_billable && moduleType.type === 'rented') {
            billable += (option.price.cost_excluding_tax * option.quantity) / 2;
          }
        }
        if (option.isMandatory && !option.checked && (!found || found.length === 0)) {
          toggleTask(`task_${idx}`);
        }
      });
      setNumberOfSelection(totalTaskSelected);
      setTotalHT(total);
      setBillable(billable);
    }
  }, [options]);

  return (
    <>
      {label && <label className="font-bold block text-sm font-medium">
        {label}
      </label>}
      <div className="mt-1 relative">
        <Loader show={loading} />
        <fieldset className="divide-y divide-gray-200">
          <legend className="sr-only">Préstations</legend>
          {displayCheckboxes ?
            <TaskCheckboxes 
              tasks={options} 
              onToggle={toggleTask} 
              onQuantityChange={setQuantity} 
              onCommentChange={setComments}
              disabled={maxSelection && numberOfSelection === maxSelection}
              isToggleAllowed={checkIfAllowedCheckbox}
              displayBillable={moduleType.type === 'rented'} />:
            <TaskSearch 
              tasks={options}
              onToggle={toggleTask} 
              onQuantityChange={setQuantity} 
              onCommentChange={setComments}
              disabled={maxSelection && numberOfSelection === maxSelection}
              isToggleAllowed={checkIfAllowedSearch}
              displayBillable={moduleType.type === 'rented'} />
          }
        </fieldset>
        <div className="flex justify-between items-end">
          <div className="text-gray-500 text-sm">
            {numberOfSelection} prestation(s) sélectionnée(s) {maxSelection && <> / {maxSelection < options.length ? maxSelection : options.length} autorisées</>}
          </div>
          <div>
            <div className="font-bold text-lg">Total: {totalHT.toFixed(2)} € HT</div>
          </div>
        </div>
      </div>
      <Modal show={mustSelectWarning ? true : false} onClose={() => setMustSelectWarning(false)}>
        <Loader show={loading} />
        Les prestations suivantes nécéssitent cette prestation :
        <ul>
          {mustSelectWarning && mustSelectWarning.mustSelectFor.map(item => (
            <li key={item.price.uid} className="font-bold">- {item.label} / {item.price.cost_excluding_tax} €</li>
          ))}
        </ul>
        <div className="text-red-500 font-bold">
          Si vous continuez, ces prestations seront également désélectionnées.
        </div>
        <div className="flex justify-evenly py-4">
          <Button bgColor="bg-primary-dark font-bold" clicked={unselectRelated}>Continuer</Button>
          <Button bgColor="bg-yellow-600" clicked={() => setMustSelectWarning(false)}>Annuler</Button>
        </div>
      </Modal>
    </>
  );
};
  
export default TaskSelection;
