import { useState } from 'react';
import { Button, Modal } from '@components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons';

const HelpBubble = ({ children, title }) => {
  const [showBubble, setShowBubble] = useState(false);
  return (
    <>
      <Button title={title} clicked={() => setShowBubble(true)}
        noShadow bgColor="bg-transparent" textColor="text-primary">
        <FontAwesomeIcon icon={faQuestionCircle} />
      </Button>
      <Modal show={showBubble} onClose={() => setShowBubble(false)} title={title}>
        {children}
      </Modal>
    </>
  );
};

export default HelpBubble;
