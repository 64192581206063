import {
  Form, FormSection, Textarea, Input, Checkbox, Combobox,
  Button, Badge, Alert, HelpBubble,
} from '@/components'
import debounce from 'lodash.debounce';
import { useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesSquare } from '@fortawesome/pro-duotone-svg-icons';
import { classNames } from '@services/utils.service';

const TaskSearch = ({ 
  tasks,
  onToggle,
  onQuantityChange,
  onCommentChange,
  disabled = false,
  isToggleAllowed,
  mustSelect,
  cantSelect,
  displayBillable = false,
}) => {
  const [filteredTasks, setFilteredTasks] = useState([]);

  const filterList = (value) => {
    setFilteredTasks(tasks.filter(task => task.label && task.label.indexOf(value) > -1));
  };

  /*
   * See TaskSelection.jsx for onToggle() usage
   */
  const toggleItem = (item) => {
    var index = tasks.findIndex(task => task.price.uid === item.price.uid);
    onToggle('task_'+index);
  };

  const debounceFilterList = useCallback(debounce(filterList, 500, true),
    [tasks]
  );

  return (
    <div className="pt-6">
      {!disabled ?
        <>
          <Combobox 
            label={<>Ajouter une prestation <span className="text-red-500">*</span></>}
            items={filteredTasks} 
            displayKey="label" 
            valueKey="uid"
            subDisplay={(item) => <div className="flex flex-row justify-between items-center">
              Type : {item.price.task_code}
              {item.disabled && item.cantSelectBecause && item.cantSelectBecause.length > 0 && 
                <div>
                  <HelpBubble title="Cette prestation est indisponible.">
                    Les prestations suivantes sont incompatibles avec cette prestation :
                    <ul>
                      {item.cantSelectBecause.map(item => (
                        <li key={item.price.uid}>- {item.label}</li>
                      ))}
                    </ul>
                  </HelpBubble>
                </div>
              }
            </div>}
            placeholder="Entrez le libellé d'une prestation" 
            onSearch={debounceFilterList}
            onSelection={(item) => toggleItem(item)} />
          <div className="text-xs text-gray-500">Cliquez sur une prestation dans la liste pour l'ajouter au bon de travaux.</div>
        </> :
        <Alert type="warning" text="Vous avez atteint le nombre maximum de prestations autorisées pour ce fournisseur."/>
      }
      <h2 className="text-sm font-medium text-gray-700 mt-2 pt-2">
        Prestations ajoutées
      </h2>
      <div key={tasks}>
        {tasks && tasks.map((option, index) => {
          if (option.checked) {
            return (
              <div key={index} className="border border-primary border-opacity-30 rounded-lg p-4 my-2">
                <div className="font-bold uppercase flex flex-row justify-between">
                  <span className="flex flex-col md:flex-row items-start md:items-center break-all">
                    <div className="text-primary">{option.label.trim()} {option.price.is_billable && displayBillable && <Badge text="Refacturable"/>} 
                    <span>{option.price.task_code && 
                        <><br/><div className="text-xs text-gray-600">Type : {option.price.task_code}</div></>}</span></div>
                  </span>
                  {/* See TaskSelection.jsx for isToggleAllowed() usage */}
                  <button 
                    type="button" 
                    onClick={(e) => isToggleAllowed(e,option,toggleItem)} 
                    className={classNames([
                      option.isMandatory ? 'opacity-60 cursor-not-allowed':'opacity-80 hover:opacity-100'
                    ])}
                    disabled={option.isMandatory ? 'disabled':null}>
                    <FontAwesomeIcon icon={faTimesSquare} 
                      className="text-2xl text-red-700"/>
                  </button>
                </div>
                <div className="flex flex-col md:flex-row">
                    <div className="flex items-center gap-3">
                      <div className="sm:col-span-1 flex items-end gap-2">
                        <div className="flex flex-col justify-center">
                          <Input
                            defaultValue={option.quantity}
                            type="number"
                            id={`quantity_${index}`}
                            name={`quantity_${index}`}
                            onChange={onQuantityChange}
                            placeholder="Quantités"
                            min={0}
                            disabled={option.price && option.price.is_quantity_locked ? true : false}
                          ></Input>
                        </div>
                        <div>{option.price.unit}</div>
                      </div>
                    </div>
                </div>
                <div className="pt-2">
                    <Input
                      type="text"
                      id={`comments_${index}`}
                      name={`comments_${index}`}
                      defaultValue={option.comments}
                      placeholder="Entrez ici vos observations concernant la prestation."
                      onChange={onCommentChange}
                    />
                </div>
              </div>);
            }
        })}
      </div>
    </div>
  );
};
  
export default TaskSearch;
