const Badge = ({ text, type = 'primary', ...props }) => {
  const styles = {
    primary: 'bg-primary-clear text-primary-darker',
    success: 'bg-green-100 text-green-900 border border-green-200',
    error: 'bg-red-100 text-red-800',
    warning: 'bg-amber-100 text-orange-900 border border-orange-200'
  };

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${styles[type]}`} {...props}>
      {text}
    </span>
  );
};

export default Badge;