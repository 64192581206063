import { NavLink, useLocation } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import Logo from '@assets/images/icon.png';
import {
  MenuIcon,
} from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTable } from '@fortawesome/pro-duotone-svg-icons';
import { CloseButton, Button } from '@components';

import { logout } from '@store/auth/actions';
import { selectUser } from '@store/user/selector';

const navigation = [
  { name: 'Tableau de bord', href: '/', icon: faHome },
  { name: 'Bons de travaux', href: '/bons-de-travaux', icon: faTable },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const location = useLocation();
  
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
  };

  const doLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  return (
  <>
    <Transition.Root show={sidebarOpened} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={toggleSidebar}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <CloseButton clicked={toggleSidebar} tite="Fermer le menu"/>
              </div>
            </Transition.Child>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="w-full h-20 w-20 mx-auto"
                  src={Logo}
                  alt="Workflow"
                />
              </div>
              <nav className="mt-5 px-2 space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    onClick={() => setSidebarOpened(false)}
                    exact="true"
                    className={isActive => classNames(
                      location.pathname === item.href
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                    )}
                  >
                    <FontAwesomeIcon icon={item.icon} aria-hidden="true" className="text-gray-500 mr-4 flex-shrink-0 group-hover:text-primary text-lg"/>
                    {item.name}
                  </NavLink>
                ))}
              </nav>
            </div>
            {user &&
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <a href="/" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={Logo}
                        alt=""
                      />
                    </div>
                    <button type="button" className="ml-3" onClick={doLogout}>
                      <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                        {user.firstname} {user.lastname}
                      </p>
                      <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">Déconnexion</p>
                    </button>
                  </div>
                </a>
              </div>
            }
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
      </Dialog>
    </Transition.Root>

    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="w-full h-10 w-10 mx-auto"
                src={Logo}
                alt="Workflow"
              />
            </div>
            <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  exact="true"
                  to={item.href}
                  className={(isActive => classNames(
                    location.pathname === item.href ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  ))}
                >
                  <FontAwesomeIcon icon={item.icon} aria-hidden="true" className="text-gray-500 mr-4 flex-shrink-0 h-6 w-6 group-hover:text-primary text-lg"/>
                  {item.name}
                </NavLink>
              ))}
            </nav>
          </div>
          {user &&
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <a href="/" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={Logo}
                      alt=""
                    />
                  </div>
                  <button
                    type="button" className="ml-3" onClick={doLogout}>
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      {user.firstname} {user.lastname}
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      Déconnexion
                    </p>
                  </button>
                </div>
              </a>
            </div>
          }
        </div>
      </div>
    </div>
    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
      <button
        type="button"
        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        onClick={() => setSidebarOpened(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  </>)
};

export default Header;
