import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Page, Table, Pagination, Button, WorkOrderBadges, Input } from '@components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faToolbox, faPenSquare, faTimesSquare } from '@fortawesome/pro-duotone-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getWorkOrders, resetCurrent } from '@store/workOrder/actions';
import { isLoading, selectError } from '@store/workOrder/selector';
import { fromStringToDatetime } from '@services/utils.service';
import { Paths } from '@/Router';
import { selectListData, selectListPaginationTotal } from '@store/workOrder/selector';
import debounce from 'lodash.debounce';
import { getInitialWorkable }  from '@services/workOrder.service';

const WorkOrderList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(null);
  const workOrderList = useSelector(selectListData);
  const loading = useSelector(isLoading, shallowEqual);
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemsPerPage = 15;
  const totalBT = 150;
  const total = useSelector(selectListPaginationTotal);
  const pageOffset = 0;
  const searchInputRef = useRef(null);

  const columns = [{
    title: 'code',
    key: 'code',
  },{
    title: 'Année',
    key: 'year'
  },{
    title: 'Type',
    format: (item) => {
      return (
        <span>{item.market?.work_type?.label || '-'}</span>
      );
    }
  },{
    title: 'Montant (€)',
    format: (item) => {
      return (
        <span>{item.validation?.total_excluding_tax || 0}</span>
      );
    }
  },
  {
    title: 'Statut',
    format: (item) => {
      return (
        <div className="flex flex-row items-center justify-center gap-2">
          <WorkOrderBadges workOrder={item} />
        </div>
      );
    }
  },
  {
    title: 'Créé le',
    format: (item) => {
      return fromStringToDatetime(item.created_at);
    }
  }];

  const actions = [
  // {
  //   label: <FontAwesomeIcon icon={faPenSquare}/>,
  //   className: 'hover:text-primary',
  //   callback: (event, item) => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     navigate(Paths.WORK_ORDER_UPDATE.replace(':uid', item.uid));
  //   },
  //   disable: (item) => {
  //     return item.is_valid;
  //   }
  // }
  ];

  const pageChange = (page) =>{
    setCurrentPage(page);
  };

  const goToCreate = () => {
    dispatch(resetCurrent());
    navigate(Paths.WORK_ORDER_CREATE);
  };

  const goToView = (item) => {
    navigate(Paths.WORK_ORDER_SINGLE.replace(':uid', item.uid));
  };

  const filterList = () => {
    getList();
  };

  const getList = () => {
    dispatch(getWorkOrders({
      page: currentPage,
      size: itemsPerPage,
      search
    }));
  };

  const updateSearch = useCallback(debounce((id, value) => {
    setCurrentPage(1);
    setSearch(value);
  }, 300));

  useEffect(() => {
    getList();
  }, [currentPage, search]);

  return (
    <Page title="Liste des bons de travaux">
      <Button className="mb-4" clicked={goToCreate}>Créer un bon de travaux</Button>
      <div className="mb-4">
        <Input 
          placeholder="Filtrer la liste par code"
          className="p-2 w-full"
          onChange={updateSearch}
          onClear={() => setSearch(null)}
          ref={searchInputRef}
          defaultValue={search}
        />
      </div>
      <Table 
        columns={columns} 
        actions={actions} 
        items={workOrderList}
        itemsPerPage={itemsPerPage}
        totalItems={totalBT}
        clicked={(item) => goToView(item)}
        loading={loading} />
      <Pagination 
        currentPageOffset={currentPage * itemsPerPage - itemsPerPage} 
        currentPageCount={workOrderList?.length} 
        totalResults={total} 
        pagesCount={Math.ceil(total / itemsPerPage)} 
        onPageChange={pageChange} 
        currentPage={currentPage} />
    </Page>
  );
};

export default WorkOrderList;
