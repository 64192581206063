import { useEffect } from 'react';
const CommonDetails = ({ 
  agency, 
  program, 
  building, 
  floor, 
  staircase,
  keepers,
  rowPadding, 
  ...props 
}) => {
  return (
    <>
      <div className="w-full">
        {program && 
          <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding : ''}`}>
            <div className="p-2">Programme</div>
            <div className="p-2 text-right">
              { program.code } - { program.label }<br/>
              { agency && agency.label }
            </div>
          </div>
        }
        {(building || floor || staircase) && 
          <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding :''}`}>
            <div className="p-2">Localisation</div>
            <div className="p-2 text-right">
              {building && <>{ building.label }{building.code ? '('+building.code+')':''}<br/></>}
              {staircase && <>
                { staircase.street_number } { staircase.street } <br/>
                { staircase.postal_code } { staircase.city }</>}
            </div>
          </div>
        }
        {keepers && keepers.length > 0 && 
          <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding:''}`}>
            <div className="p-2">Resid'Manager</div>
            <div className="p-2 text-right">
              {keepers.map(keeper => {
                return <div key={keeper.uid}>
                  {keeper.staff.firstname.substring(0,1)}. {keeper.staff.lastname} - <a href={`mailto:${keeper.staff.email}`}>{keeper.staff.email}</a>
                  
                </div>;
              })}
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default CommonDetails;
