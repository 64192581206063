import { useEffect, useState } from 'react';
import { 
  Page, Section, Loader, Button,
  Form, FormSection, DatePicker, Textarea,
  ErrorPanel, Alert,
  InterventionRecap, WorkOrderRecap, 
} from '@components';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Paths } from '@/Router';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { useForm } from "react-hook-form";
import { getFormError } from '@services/validation.service';
import { getIntervention, updateIntervention, saveIntervention, } from '@store/workOrder/intervention/actions';
import { 
  selectSingle, 
  selectError, 
  isLoading,
  isSaved,
} from '@store/workOrder/intervention/selector';

const Intervention = () => {
  const { control, register, handleSubmit, watch, formState: { errors }, setValue } = useForm({
    defaultValues: {
      start_at: null,
      end_at: null,
      comments: null
    }
  });
  const { workOrderUid, interventionUid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(isLoading, shallowEqual);
  const saved = useSelector(isSaved);
  const error = useSelector(selectError);
  const intervention = useSelector(selectSingle);

  useEffect(() => {
    dispatch(getIntervention({ workOrderUid, interventionUid }));
  }, []);

  useEffect(() => {
    if (intervention) {
      if (intervention.start_at) {
        setValue('start_at', intervention.start_at);
      }
      if (intervention.end_at) {
        setValue('end_at', intervention.end_at);
      }
    }
  }, [intervention]);

  const save = (data) => {
    dispatch(saveIntervention({ workOrderUid, interventionUid }));
  };

  return (
    <Page withLayout={false} title="Intervention">
      <Loader show={loading} />
      {error && error.response ? 
        <ErrorPanel status={error.response.status} /> :
        <>
          {intervention &&
            <>
              <Form className="relative pb-6"
                actions={
                  <Button type="submit">Envoyer</Button>
                }
                onSubmit={handleSubmit(save)}
                contentPadding="p-4"
                error={error}
                successMsg={saved ? "L'intervention a été mise à jour." : undefined}>
                <h1 className="text-2xl font-black text-primary-dark my-2 break-all">Prenez rendez-vous !</h1>
                <FormSection>
                  <div className="col-span-6 sm:col-span-3">
                    <DatePicker 
                      label="Début d'intervention"
                      placeholder="Cliquez pour choisir"
                      required
                      showTimeSelect
                      onChange={(date) => {
                        dispatch(updateIntervention({start_at:date}));
                        setValue('start_at', date);
                      }}
                      selected={intervention.start_at}
                      minTime={setHours(setMinutes(new Date(), 0), 8)}
                      maxTime={setHours(setMinutes(new Date(), 30), 18)} 
                      errors={errors.start_at}
                      control={control}
                      rules={{ 
                        required: getFormError('required', true),
                      }}
                      name="start_at" 
                      id="start_at"
                      />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <DatePicker 
                      label="Fin d'intervention"
                      placeholder="Cliquez pour choisir"
                      required
                      showTimeSelect
                      selected={intervention.end_at}
                      onChange={(date) => {
                        dispatch(updateIntervention({end_at:date}));
                        setValue('end_at', date);
                      }}
                      minTime={setHours(setMinutes(new Date(), 0), 8)}
                      maxTime={setHours(setMinutes(new Date(), 30), 18)} 
                      errors={errors.end_at}
                      control={control}
                      rules={{ 
                        required: getFormError('required', true),
                      }}
                      name="end_at" 
                      id="end_at"
                      />
                  </div>
                  <div className="flex flex-col col-span-6">
                    <Textarea label="Commentaires (max 500 caractères)"
                      rows={2}
                      errors={errors.comments}
                      onChange={(id, value) => dispatch(updateIntervention({comments:value}))}
                      placeholder="Quelque-chose à ajouter ? Écrivez vos commentaires ici."
                      defaultValue={intervention.comments}
                      register={register}
                      rules={{ 
                        maxLength: getFormError('maxLength', 500),
                      }}
                      name="comments" id="comments" />
                  </div>
                </FormSection>
              </Form>
              <Section className="relative">
                <h2 className="text-xl font-black text-primary-dark mt-4 break-all px-4">Détails du bon de travaux n°{intervention.work_order.code || 'N/A'}</h2>
                <p className="mx-4 mt-1 mb-4 max-w-2xl text-sm text-gray-500">
                  {intervention.work_order.label}
                </p>
                {intervention.work_order &&
                  <InterventionRecap 
                      workOrder={intervention.work_order} />
                }
              </Section>
            </>
          }
        </>
      }
    </Page>
  );
};

export default Intervention;
