import { useSelector, useDispatch } from 'react-redux';
import { 
  selectAuthState
} from '@store/auth/selector';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, createAccount, forgotPassword } from '@store/auth/actions';
import Logo from '@assets/images/logo.png';
import { Button, Form, FormSection, Input, Loader, Alert } from '@components';
import { useForm } from "react-hook-form";
import { Paths } from '@/Router';
import { getPattern, getFormError } from '@services/validation.service';

const Login = (props) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [displayType, setDisplayType] = useState('login');
  const [successMessage, setSuccessMessage] = useState(null);
  const { loading, error: apiError, isConnected, ready, forgotPassword: forgotPasswordSuccess } = useSelector(selectAuthState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isConnected) {
      navigate(Paths.DASHBOARD);
    }
  }, [isConnected]);

  const onSubmitLogin = (data) => {
    dispatch(login({ email: data.email, password: data.password }));
  };

  const onSubmitAccount = (data) => {
    dispatch(createAccount({ email: data.email, password: data.password }));
  };

  const onSubmitPassword = (data) => {
    dispatch(forgotPassword({ email: data.email }));
  };

  useEffect(() => {
    if (forgotPasswordSuccess) {
      setSuccessMessage(forgotPasswordSuccess);
    }
  }, [forgotPasswordSuccess]);

  return (
    <div className="min-h-full flex flex-col justify-center py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 relative">
          <div className="pb-4 sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto w-full" src={Logo} alt="Workflow" />
          </div>
          <Loader show={loading} />
          {displayType === 'login' && 
            <Form 
              onSubmit={handleSubmit(onSubmitLogin)}
              noBorder 
              contentPadding=""
              actions={
                <div className="flex flex-col">
                  <Button 
                    type="submit" 
                    className="w-full mb-2"
                    fullwidth
                  >Connexion</Button>
                  <Button type="submit" bgColor="bg-transparent" textColor="text-primary-darker" 
                    border="border-0" fullwidth
                    onClick={() => setDisplayType('account')}>Créer un compte</Button>
                </div>
              } 
              error={apiError}
              actionsWrapperClassName="flex flex-col"
              action="#" 
              method="POST">
              <FormSection>
                <div className="sm:col-span-6">
                  <Input type="email" label="Adresse email" 
                    placeholder="Entrez votre adresse email"
                    required 
                    onChange={(id, value) => setEmail(value)}
                    defaultValue={email}
                    errors={errors.email}
                    name="email"
                    register={register}
                    rules={{ 
                      required: getFormError('required', true), 
                      pattern: getPattern('email')
                    }}/>
                </div>
                <div className="sm:col-span-6">
                  <Input type="password" label="Mot-de-passe" 
                    placeholder="Entrez votre mot-de-passe"
                    required 
                    onChange={(id, value) => setPassword(value)}
                    defaultValue={password}
                    errors={errors.password}
                    name="password"
                    register={register}
                    rules={{ 
                      required: getFormError('required', true),
                    }} />
                </div>
                <div className="sm:col-span-6">
                  <div className="text-sm">
                    <button type="button" className="primary-link underline" onClick={() => setDisplayType('password')}>
                      Vous avez oublié votre mot-de-passe ?
                    </button>
                  </div>
                </div>
              </FormSection>
            </Form>
          }

          {displayType === 'password' && 
            <Form noBorder 
              error={apiError}
              actions={
                <>
                  <Button type="submit" bgColor="bg-transparent" textColor="text-primary-darker" 
                    border="border-primary-darker"
                    onClick={() => setDisplayType('login')}>Annuler</Button>
                  <Button type="submit">Envoyer</Button>
                </>
              } contentPadding="p-0" onSubmit={handleSubmit(onSubmitPassword)}>
              <FormSection title="Récupérez votre mot-de-passe" 
                subtitle="Entrez l'adresse email liée à votre compte dans le champs ci-dessous pour créer un compte. Si votre adresse email est reconnue, nous vous indiquerons la marche à suivre pour modifier votre mot-de-passe.">
                <div className="sm:col-span-6">
                  <Input type="email" label="Adresse email" 
                    placeholder="Entrez l'adresse email liée à votre compte"
                    name="email"
                    register={register}
                    required
                    defaultValue={email}
                    errors={errors.email}
                    onChange={(id, value) => setEmail(value)}
                    rules={{ 
                      required: getFormError('required', true), 
                      pattern: getPattern('email')
                    }} />
                </div>
              </FormSection>
              {successMessage && <Alert text={successMessage} type="success"/>}
            </Form>
          }

          {displayType === 'account' && 
            <Form noBorder 
              error={apiError}
              actions={
                <>
                  <Button type="submit" bgColor="bg-transparent" textColor="text-primary-darker" 
                    border="border-primary-darker"
                    onClick={() => setDisplayType('login')}>Annuler</Button>
                  <Button type="submit">Envoyer</Button>
                </>
              } contentPadding="p-0" onSubmit={handleSubmit(onSubmitAccount)}>
              <FormSection title="Créez un compte" 
                subtitle="Entrez votre adresse email professionnelle et un mot-de-passe sécurisé dans les champs ci-dessous pour créer un compte.">
                <div className="sm:col-span-6">
                  <Input type="email" label="Adresse email" 
                    placeholder="Entrez votre aresse email professionnelle"
                    name="email"
                    register={register}
                    required
                    defaultValue={email}
                    errors={errors.email}
                    onChange={(id, value) => setEmail(value)}
                    rules={{ 
                      required: getFormError('required', true), 
                      pattern: getPattern('email')
                    }} />
                </div>
                <div className="sm:col-span-6">
                  <Input type="password" label="Mot-de-passe" 
                    placeholder="Entrez votre mot-de-passe"
                    required 
                    onChange={(id, value) => setPassword(value)}
                    defaultValue={password}
                    errors={errors.password}
                    name="password"
                    register={register}
                    rules={{ 
                      required: getFormError('required', true),
                    }} />
                </div>
              </FormSection>
            </Form>
          }
        </div>
      </div>
    </div>
  );
}

export default Login;
