import { Button } from '@components';
import { Link } from 'react-router-dom';

const ButtonLink = ({ children, to, ...props }) => {
  return (
    <Link to={to}>
      <Button {...props}>
        {children}
      </Button>
    </Link>
  );
};

export default ButtonLink;
