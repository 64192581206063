import api from '../../Api';

export function me() {
    return api.get('/me');
}

export function workOrders({ page = 1, size = 15,
    filters = null, sort = '-created_at' }) {
    var url = `/me/work-orders?sort=${sort}&page[number]=${page}&page[size]=${size}&include=modules,floors,staircases,buildings,programs,market,validation`;
    if (filters) {
        filters && Object.keys(filters).forEach(key => {
            url += `&${key}=${filters[key]}`;
        });
    }
    return api.get(url);
}
