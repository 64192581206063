import * as constants from "./constants";

const initialState = {
  ready: false,
  isConnected: false,
  token: '',
  loading: false,
  error: null,
  forgotPassword: null,
  resetPassword: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.LOGIN:
        case constants.CREATE_ACCOUNT:
        case constants.GET_AUTH_USER:
        case constants.FORGOT_PASSWORD:
        case constants.RESET_PASSWORD:
          return startRequest(state);
          break;
        case constants.LOGOUT:
          return logout(state, action);
          break;
        case constants.LOGIN_SUCCESS:
          return loginSuccess(state, action);
          break;
        case constants.LOGIN_FAILURE:
          return loginFailure(state, action);
          break;
        case constants.SET_TOKEN:
          return setToken(state, action);
          break;
        case constants.SET_READY:
          return setReady(state);
          break;
        case constants.SET_LOGGED_IN:
          return setLoggedIn(state);
          break;
        case constants.SET_LOGGED_OUT:
          return setLoggedOut(state);
          break;
        case constants.CREATE_ACCOUNT_SUCCESS:
          return createAccountSuccess(state, action);
          break;
        case constants.CREATE_ACCOUNT_FAILURE:
          return createAccountFailure(state, action);
          break;
        case constants.FORGOT_PASSWORD_SUCCESS:
          return forgotPasswordSuccess(state, action);
          break;
        case constants.FORGOT_PASSWORD_FAILURE:
          return forgotPasswordFailure(state, action);
          break;
        case constants.RESET_PASSWORD_SUCCESS:
          return resetPasswordSuccess(state, action);
          break;
        case constants.RESET_PASSWORD_FAILURE:
          return resetPasswordFailure(state, action);
          break;
        default:
          return state;
    }
};

const startRequest = (state) => ({ 
  ...state, 
  error: null, 
  loading: true ,
  forgotPassword: null,
  resetPassword: null,
});
const logout = (state) => ({ 
  ...state, 
  isConnected: false, 
  token: null 
});
const loginSuccess = (state, { data: { access_token: token } }) => ({ 
  ...state, 
  loading: false, 
  token, 
  isConnected: true 
});
const loginFailure = (state, { error }) => ({ 
  ...state, 
  loading: false, 
  error 
});
const setToken = (state, { token }) => ({ 
  ...state, 
  token 
});
const setReady = (state) => ({ 
  ...state, 
  ready: true
});
const setLoggedIn = (state) => ({ 
  ...state, 
  isConnected: true
});
const setLoggedOut = (state) => ({ 
  ...state, 
  isConnected: false
});
const createAccountSuccess = (state, { data: { user, access_token: token } }) => ({ 
  ...state, 
  loading: false,
  user, 
  token, 
  isConnected: true 
});
const createAccountFailure = (state, { error }) => ({ 
  ...state, 
  loading: false,
  error
});
const forgotPasswordSuccess = (state, { data }) => ({ 
  ...state, 
  loading: false, 
  forgotPassword: data,
});
const forgotPasswordFailure = (state, { error }) => ({ 
  ...state, 
  loading: false, 
  error
});
const resetPasswordSuccess = (state, { data }) => ({ 
  ...state, 
  loading: false, 
  resetPassword: data,
});
const resetPasswordFailure = (state, { error }) => ({ 
  ...state, 
  loading: false, 
  error
});

export default reducer;