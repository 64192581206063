import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useState, useEffect, createElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-duotone-svg-icons';
import { 
  Page, Steps,Section,
  StepSettings,StepMarket,StepTasks,StepConfirm,StepSummary,
  Loader,Button,Modal, Alert,
} from '@components';
import { validateStep }  from '@services/validation.service';

import { useParams, useNavigate } from 'react-router-dom';
import { selectCurrent, isLoading, selectError, selectSingle, selectSingleData, 
} from '@store/workOrder/selector';
import { getWorkOrder, setWorkOrder, updateWorkOrder, setReady } from '@store/workOrder/actions';
import { selectSaved, selectCurrentIsReady } from '@store/workOrder/selector';
import { resetPatrimony } from '@store/patrimony/actions';
import { toast } from 'react-toastify';
import { selectUser } from '@store/user/selector';
import ReactJson from 'react-json-view'

const WordOrderCreate = (props) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const [currentStepKey, setCurrentStepKey] = useState('settings');
  const [previousStepKey, setPreviousStepKey] = useState();
  const [nextStepKey, setNextStepKey] = useState();
  const navigate = useNavigate();
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);

  const loading = useSelector(isLoading, shallowEqual);
  const error = useSelector(selectError);
  const { market } = useSelector(selectSingle);
  const workOrderData = useSelector(selectSingleData);
  const user = useSelector(selectUser);
  const saved = useSelector(selectSaved);
  const current = useSelector(selectCurrent);
  const ready = useSelector(selectCurrentIsReady);

  const completeStep = (stepKey) => {
    steps[stepKey].status = 'complete';
    setSteps([...steps]);
  };

  const onNext = () => {
    onStepChange(nextStepKey);
  };

  const onPrevious = () => {
    onStepChange(previousStepKey);
  };

  const doShowConfirm = () => {
    setShowConfirmSave(true);
  };

  useEffect(() => {
    if (error && error.response && error.response.data) {
      toast.error(`Une erreur s'est produite : ${error.response.data.message}`);
      // navigate(`${Paths.ERROR.replace(':status', error.response.status)}?ressource_id=${uid}&ressource_type=work_order&ressource_action=${error.response && error.response.config ? error.response.config.method:''}`);
    }
  }, [error]);

  useEffect(() => {
    if (uid && ((workOrderData && workOrderData.uid !== uid) || !workOrderData)) {
      dispatch(resetPatrimony());
      dispatch(getWorkOrder({ uid, include: [
        'modules', 'floors', 'buildings', 'staircases', 
        'programs', 'details', 'market', 'unit_price',
        'validation', 'full_contract',
      ] }));
    } else {
      dispatch(setReady());
    }
  }, [uid]);

  useEffect(() => {
    if (workOrderData && workOrderData.uid === uid && market.data && !ready) {
      dispatch(setWorkOrder({ workOrder: workOrderData, market }));
    }
  }, [workOrderData, market]);

  useEffect(() => {
    let stepsCopy = {...steps};
    let lastActive = null;
    Object.keys(stepsCopy).map((key, index) => {
      if (validateStep(key, current)) {
        stepsCopy[key].status = 'complete'
        lastActive = Object.keys(stepsCopy)[index + 1];
      } else {
        stepsCopy[key].status = undefined;
      }
      if (lastActive) {
        stepsCopy[lastActive].status = 'active';
      }
      return stepsCopy[key];
    });
    setSteps({
      ...stepsCopy,
    });
    if (current.patrimony && current.patrimony.contract && current.patrimony.contract.tenants && current.patrimony.contract.tenants.length) {
      current.patrimony.contract.tenants.forEach(tenant => {
        if (tenant.tenant && tenant.tenant.email) {
          setHasEmail(true);
        }
      });
    }
  }, [current]);

  const [steps, setSteps] = useState({
    settings: {
      order: 1,
      name: 'Configuration',
      element: StepSettings,
      status: 'complete',
    },
    market: {
      order: 2,
      element: StepMarket,
      name: 'Marché',
    },
    tasks: {
      order: 3,
      element: StepTasks,
      name: 'Préstations',
    },
    validate: {
      order: 4,
      element: StepConfirm,
      name: 'Confirmation',
    },
  });

  const onStepChange = (stepKey) => {
    setCurrentStepKey(stepKey);
  }; 

  const update = () => {
    dispatch(updateWorkOrder({ uid }));
    setShowConfirmSave(false);
  };

  useEffect(() => {
    let keys = Object.keys(steps);
    let index = Object.keys(steps).indexOf(currentStepKey);
    setPreviousStepKey(keys[index - 1] || null);
    setNextStepKey(keys[index + 1] || null);
  }, [currentStepKey]);

  useEffect(() => {
    if (saved) toast.success("Le bon de travaux a été sauvegardé.");
  }, [saved]);

  return (
    <Page title="Créer un bon de travaux">
      <Section>
        <Loader show={loading} /> 
        <Steps 
          steps={steps} 
          onStepChange={onStepChange} 
          currentStepKey={currentStepKey} />
      </Section>
      <div className="my-4">
        {ready && !hasEmail && current?.patrimony?.moduleType?.type === 'rented' &&
          <Alert text={<>Le locataire du logement sélectionné n'a pas d'adresse email valide.<br/>
          Si le bon de travaux nécessite un consentement de la part du locataire, il ne pourra pas être validé.</>}/>
        }
        <div className="relative">
          {uid && <Button clicked={doShowConfirm}
            className="absolute right-2 top-2 z-[20] gap-2"
            fontWeight="font-medium" 
            bgColor="bg-transparent" 
            textColor="text-primary"
            disabled={!validateStep('validate', current)}>
            <span>Sauvegarder</span>
            <FontAwesomeIcon icon={faSave} className="text-xl" />
          </Button>}
          {createElement(steps[currentStepKey].element, {
            onNext: nextStepKey ? onNext : null,
            onPrevious: previousStepKey ? onPrevious : null,
            onComplete: completeStep,
            key: steps[currentStepKey].element,
          })}
        </div>
      </div>

{/*      <Section className="p-4 break-words overflow-scroll">
        <ReactJson id="json-pretty" src={current} collapsed={true} name="Bon de travaux"/>
      </Section>
*/}
      <Modal 
        show={showConfirmSave} 
        onClose={() => setShowConfirmSave(false)}
        title={uid ? "Voulez-vous sauvegarder vos modifications ?":"Voulez-vous créer le bon de travaux ?"}>
        <Loader show={loading} />
        <div>Cette action est définitive. Voulez-vous continuer ?</div>
        {user && user.delegation < current.total &&
          <div className="text-red-500 font-bold">
            Ce bon de travaux devra être validé par un supérieur.
          </div>
        }
        <div className="text-sm py-2">Cliquez sur <span className="bg-primary-dark text-white font-bold">Continuer</span> pour accepter le changement et passer à l'étape suivante, sur <span className="bg-yellow-600 text-white">Annuler</span> pour fermer la fenêtre.</div>
        <div className="flex justify-evenly py-4">
          <Button bgColor="bg-primary-dark font-bold" clicked={update}>Continuer</Button>
          <Button bgColor="bg-yellow-600" clicked={() => setShowConfirmSave(false)}>Annuler</Button>
        </div>
      </Modal>
    </Page>
  );
}

export default WordOrderCreate;
