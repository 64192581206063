import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, FormSection, Input, Loader, Alert } from '@components';
import { Paths } from '@/Router';
import { getPattern, getFormError } from '@services/validation.service';
import { selectAuthResetPassword, selectAuthState } from '@store/auth/selector';
import { resetPassword } from '@store/auth/actions';
import Logo from '@assets/images/logo.png';

const ResetPassword = () => {
  const { token } = useParams();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [email, setEmail] = useState();
  const [displayType, setDisplayType] = useState('login');
  const resetPasswordResult  = useSelector(selectAuthResetPassword);
  const { loading, error: apiError, isConnected } = useSelector(selectAuthState);
  const [successMessage, setSuccessMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (resetPasswordResult) {
      setSuccessMessage(resetPasswordResult);
    }
  }, [resetPasswordResult]);

  const goToLogin = () => {
    navigate(Paths.LOGIN);
  };

  const onSubmit = (data) => {
    dispatch(resetPassword({...data, token}));
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 relative">
          <div className="pb-4 sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto w-full" src={Logo} alt="Workflow" />
          </div>
          <Loader show={loading} />
          <Form noBorder 
            error={apiError}
            actions={
              <>
                <Button type="submit" bgColor="bg-transparent" textColor="text-primary-darker" 
                  border="border-primary-darker"
                  clicked={goToLogin}>Me connecter</Button>
                <Button type="submit" disabled={resetPasswordResult}>Envoyer</Button>
              </>
            } contentPadding="p-0" onSubmit={handleSubmit(onSubmit)}>
            {!resetPasswordResult ?
              <FormSection title="Réinitialisez votre mot-de-passe" 
                subtitle="Entrez votre adresse email et votre nouveau mot-de-passe dans les champs ci-dessous pour modifier votre mot-de-passe.">
                <div className="sm:col-span-6">
                  <Input type="email" label="Adresse email" 
                    placeholder="Entrez votre aresse email professionnelle"
                    name="email"
                    register={register}
                    required
                    defaultValue={email}
                    errors={errors.email}
                    onChange={(id, value) => setEmail(value)}
                    rules={{ 
                      required: getFormError('required', true), 
                      pattern: getPattern('email')
                    }} />
                </div>
                <div className="sm:col-span-6">
                  <Input type="password" label="Nouveau mot-de-passe" 
                    placeholder="Entrez votre mot-de-passe"
                    required 
                    onChange={(id, value) => setPassword(value)}
                    defaultValue={password}
                    errors={errors.password}
                    name="password"
                    min={8}
                    register={register}
                    rules={{ 
                      required: getFormError('required', true), 
                      minLength: getFormError('minLength', 8)
                    }} />
                </div>
                <div className="sm:col-span-6">
                  <Input type="password" label="Confirmation du nouveau mot-de-passe" 
                    placeholder="Entrez à nouveau votre mot-de-passe"
                    required 
                    onChange={(id, value) => setPasswordConfirmation(value)}
                    errors={errors.password_confirmation}
                    name="password_confirmation"
                    defaultValue={passwordConfirmation}
                    register={register}
                    rules={{ 
                      required: getFormError('required', true),
                      validate: (value) => value == password || "Les mot-de-passes ne sont pas identiques."
                    }} />
                </div>
              </FormSection>
            : <Alert text={resetPasswordResult} type="success"/>}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
