import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { finaliseWorkOrder } from '@store/workOrder/actions';
import { selectFinalise } from '@store/workOrder/selector';
import { Modal, Button, Form, FormSection, Input, DatePicker } from '@components';
import { useForm } from "react-hook-form";
import { getFormError } from '@services/validation.service';
import { addDays } from "date-fns";

const WorkOrderFinalise = ({ triggerElement, workOrder, onFinalise, beforeOpen, modalZIndex = 'z-10' }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [showModal, setShowModal] = useState(false);
  const [comments, setComments] = useState(null);
  const [startedAt, setStartedAt] = useState(new Date((new Date()).valueOf() + 1000*3600*24));
  const [endedAt, setEndedAt] = useState(addDays(new Date(), 10));
  const finaliseResult = useSelector(selectFinalise);
  const TriggerElement = triggerElement;

  const onSubmitFinalise = (data) => {
    dispatch(finaliseWorkOrder({ 
      uid: workOrder.uid,
      comments,
      startedAt: startedAt.toISOString().split('T')[0],
      endedAt: endedAt.toISOString().split('T')[0],
    }));
  };

  useEffect(() => {
    if (errors) {
      setShowModal(false);
    }
  }, [errors]);

  useEffect(() => {
    if (finaliseResult) {
      setShowModal(false);
      onFinalise(finaliseResult);
    }
  }, [finaliseResult]);

  return (
    <>
      <div onClick={() => {
        beforeOpen && beforeOpen();
        setShowModal(true);
      }}>
        {triggerElement}
      </div>
      <Modal 
        show={showModal} title="Finaliser le bon de travaux"
        onClose={() => setShowModal(false)}
        zIndex={modalZIndex}>
        <Form 
          onSubmit={handleSubmit(onSubmitFinalise)}
          noBorder contentPadding="py-4 min-h-[400px] sm:min-h-[300px]"
          actions={<>
              <Button bgColor="bg-transparent" textColor="text-primary" border="border-primary" clicked={() => setShowModal(false)}>Annuler</Button>
              <Button type="submit">Confirmer</Button>
            </>
          }>
          <FormSection className="text-left">
            <div className="col-span-6 sm:col-span-3">
              <DatePicker 
                className="w-full"
                label="Date de début réel"
                selected={startedAt}
                minDate={new Date()}
                onChange={(date) => setStartedAt(date)} />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <DatePicker 
                label="Date de fin réel"
                className="w-full"
                selected={endedAt}
                minDate={startedAt}
                onChange={(date) => setEndedAt(date)} />
            </div>
            <div className="col-span-6">
              <Input name="comments" id="comments" label="Commentaires"
                onChange={(id, value) => setComments(value)}
                errors={errors.comments}
                defaultValue={comments}
                register={register} placeholder="Entrez vos commentaires"
                rules={{ 
                  maxLength: getFormError('maxLength', 190),
                }}  />
            </div>
          </FormSection>
          {/*<div className="flex justify-evenly mt-4 pt-4">
            
          </div>*/}
        </Form>
      </Modal>
    </>
  );
};

export default WorkOrderFinalise;
