import * as constants from "./constants";

export const getConsentByToken = ({ token }) => {
    return {
        type: constants.GET_CONSENT_BY_TOKEN,
        token
    };
};

export const getConsentByTokenSuccess = (data) => {
    return {
        type: constants.GET_CONSENT_BY_TOKEN_SUCCESS,
        data
    };
};

export const getConsentByTokenFailure = (error) => {
    return {
        type: constants.GET_CONSENT_BY_TOKEN_FAILURE,
        error
    };
};

export const updateConsent = ({ consent, token }) => {
    return {
        type: constants.UPDATE_CONSENT,
        consent,
        token,
    };
};

export const updateConsentSuccess = (data) => {
    return {
        type: constants.UPDATE_CONSENT_SUCCESS,
        data
    };
};

export const updateConsentFailure = (error) => {
    return {
        type: constants.UPDATE_CONSENT_FAILURE,
        error
    };
};

export const sendConsent = ({ uid }) => {
    return {
        type: constants.SEND_CONSENT,
        uid,
    };
};

export const sendConsentSuccess = (data) => {
    return {
        type: constants.SEND_CONSENT_SUCCESS,
        data
    };
};

export const sendConsentFailure = (error) => {
    return {
        type: constants.SEND_CONSENT_FAILURE,
        error
    };
};

export const resetConsent = () => {
    return {
        type: constants.RESET_CONSENT,
    };
};
