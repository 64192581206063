import { useState, useEffect } from 'react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox as ComboboxUI } from '@headlessui/react'
import { classNames } from '@services/utils.service'

const Combobox = ({ 
  items,
  onSelection,
  onSearch,
  displayKey,
  formatDisplay = null,
  subDisplayKey = null,
  formatSubDisplay = null,
  valueKey,
  selectedItem = null,
  label,
  placeholder = null,
  useIndexAsValue = false,
  subDisplay,
  disabled,
}) => {
  const [itemsFixed, setItemsFixed] = useState(items);
  const [selectedItemFixed, setSelectedItemFixed] = useState(selectedItem);

  const getValue = (item) => {
    var splitted = displayKey.split('.');
    if (typeof(splitted) === 'object' && splitted.length > 1) {
      var selectedValue = null;
      for (let i = 0; i < splitted.length; i++) {
        if (!selectedValue) {
          selectedValue = item[splitted[i]];
        } else selectedValue = selectedValue[splitted[i]];
      }
      return selectedValue;
    } else {
      return item[displayKey];
    }
  };

  const getItemsValues = () => {
    setItemsFixed(items.map((option, key) => {
      if (formatDisplay) {
        option.optionValue = formatDisplay(option);
      } else {
        option.optionValue = getValue(option);
      }
      return option;
    }));
  };

  useEffect(() => {
    if (items && items.length) {
      getItemsValues();
    } else if (itemsFixed.length > 0) {
      setItemsFixed([]);
    }
  }, [items]);

  return (
    <ComboboxUI as="div" value={selectedItem} onChange={onSelection}>
      <ComboboxUI.Label className="font-bold block text-sm font-medium text-gray-700">{label}</ComboboxUI.Label>
      <div className="relative mt-1">
        <ComboboxUI.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => {
            if (!selectedItem || (event.target.value !== selectedItem[valueKey])) {
              onSearch(event.target.value)
            }
          }}
          displayValue={(item) => {
            return item ? (formatDisplay ? formatDisplay(item) : getValue(item)) : null
          }}
          placeholder={placeholder}
        />
        <ComboboxUI.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxUI.Button>

        {itemsFixed && itemsFixed.length > 0 && (
          <ComboboxUI.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {itemsFixed.map((item, key) => (
              <ComboboxUI.Option
                key={key}
                value={useIndexAsValue ? key : item}
                disabled={(item.disabled || item.checked) ? true : false}
                className={({ active }) =>
                  classNames(
                    'relative select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    item.disabled ? 'bg-gray-100 text-gray-600 cursor-not-allowed':'cursor-pointer',
                    item.checked ? 'cursor-not-allowed':''
                  )
                }
              >
                {({ active }) => (
                  <>
                    <span className={classNames('block truncate', item.checked && 'font-semibold')}>
                      {item.optionValue}<br/>
                      <span className="text-gray-700 text-sm">{item[subDisplayKey]}</span>
                      {subDisplay && <span className="text-gray-700 text-sm">{ subDisplay(item)}</span>}
                    </span>

                    {item.checked && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxUI.Option>
            ))}
          </ComboboxUI.Options>
        )}
      </div>
    </ComboboxUI>
  )
}

export default Combobox;