import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Paths } from '@/Router';
import { 
  TasksPriceTable,
  Button,
  Form,
  Loader,
  Modal, Section,
  ModuleDetails, CommonDetails
} from '@components';

import { selectCurrent, selectSingleTasksSaved, selectSaved, 
  isLoading, selectSingleData, selectError } from "@store/workOrder/selector";
import { createWorkOrder, updateWorkOrder } from '@store/workOrder/actions';
import { selectUser } from '@store/user/selector';

import { toDateString, fromStringToDate } from '@/services/utils.service';
import { validateStep } from '@services/validation.service';

const StepConfirm = ({ onPrevious }) => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const [isBillable, setIsBillable] = useState(false); 
  const [showConfirmNext, setShowConfirmNext] = useState(false);
  const current = useSelector(selectCurrent);
  const tasksSaved = useSelector(selectSingleTasksSaved);
  const workOrderSaved = useSelector(selectSaved);
  const savedWorkOrder = useSelector(selectSingleData);
  const error = useSelector(selectError);
  const user = useSelector(selectUser);

  const create = () => {
    if (!uid) {
      dispatch(createWorkOrder());
    } else {
      dispatch(updateWorkOrder({ uid }));
    }
  };

  const doShowConfirm = () => {
    showConfirmNext(true);
  };

  useEffect(() => {
    if (current.patrimony.moduleType && current.patrimony.moduleType.type === 'rented') {
      setIsBillable(true);
    }
  }, [current.tasks, current.patrimony.moduleType]);

  useEffect(() => {
    if (workOrderSaved) {
      navigate(Paths.WORK_ORDER_SAVED.replace(':uid', savedWorkOrder.uid));
    }
  }, [navigate, savedWorkOrder, tasksSaved, workOrderSaved]);

  return (
    <Form actions={
      <>
        <Button 
          disabled={!onPrevious}
          clicked={onPrevious || undefined} 
          bgColor="bg-transparent" 
          textColor="text-gray-500" 
          border="border-primary-darker">Précédent</Button>
        <Button 
          fontWeight="bold" 
          disabled={!validateStep('validate', current)}
          clicked={() => setShowConfirmNext(true)}>Valider & {uid ? "modifier" : "créer"}</Button>
      </>
    } contentPadding="p-0">
      <Loader show={loading} />

      {current &&
        <div>
          <h2 className="text-lg font-bold text-primary-darker uppercase m-4">Configuration</h2>
          <div className="w-full">
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40">
              <div className="p-6">Descriptif du BT</div>
              <div className="p-6 text-right">{ current.description }</div>
            </div>
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40">
              <div className="p-6">Type de travaux</div>
              <div className="p-6 text-right">{ current.workType?.label }</div>
            </div>
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40">
              <div className="p-6">Date d'éxécution</div>
              <div className="p-6 text-right">du { toDateString(current.startAt) } au { toDateString(current.endAt) }</div>
            </div>
          </div>
        </div>
      }
      
      {current.unitPrice &&
        <div className="mt-6">
          <h2 className="text-lg font-bold text-primary-darker uppercase m-4">Fournisseur</h2>
          <div className="w-full">
            {current.unitPrice.market && <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40">
              <div className="p-6">Marché</div>
              <div className="p-6 text-right">
                { current.unitPrice.market.label } { current.unitPrice.market.lot_code && ` / ${current.unitPrice.market.lot_code}`}<br/> 
                Jusqu'au { fromStringToDate(current.unitPrice.market.lot_end_date) }<br/>
                {current.unitPrice.market.work_type && <>{current.unitPrice.market.work_type.label}<br/></>}
                {current.unitPrice.market.software_key}
              </div>
            </div>}
            {current.unitPrice.service_provider && <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40">
              <div className="p-6">Fournisseur</div>
              <div className="p-6 text-right">{ current.unitPrice.service_provider.label }</div>
            </div>}
          </div>
        </div>
      }

      <div className="mt-6">
        <h2 className="text-lg font-bold text-primary-darker uppercase m-4">Patrimoine</h2>
        {current.patrimony.module &&
          <>
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
              <div className="p-2">Type</div>
              <div className="p-2 text-right">Logement</div>
            </div>

            <ModuleDetails 
              {...current.patrimony}
              moduleData={current.patrimony.module}
              contract={current.patrimony.module.current_contract && current.patrimony.module.current_contract.contract}
              rowPadding="px-6"/>
          </>
        }

        {!current.patrimony.module &&
          <>
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
              <div className="p-2">Type</div>
              <div className="p-2 text-right">Partie commune</div>
            </div>

            <CommonDetails 
              {...current.patrimony}
              rowPadding="px-6" />
          </>
        }
      </div>

      {current.tasks &&
        <div className="mt-6">
          <h2 className="text-lg font-bold text-primary-darker uppercase m-4">Prestations</h2>
          <TasksPriceTable 
            products={current.tasks} 
            isBillable={isBillable} />
        </div>
      }

      <Modal 
        show={showConfirmNext} 
        onClose={() => setShowConfirmNext(false)}
        title={uid ? "Voulez-vous sauvegarder vos modifications ?":"Voulez-vous créer le bon de travaux ?"}>
        <Loader show={loading} />
        <div>Cette action est définitive. Voulez-vous continuer ?</div>
        {user && user.delegation < current.total &&
          <div className="text-red-500 font-bold">
            Ce bon de travaux devra être validé par un supérieur.
          </div>
        }
        <div className="text-sm py-2">Cliquez sur <span className="bg-primary-dark text-white font-bold">Continuer</span> pour accepter le changement et passer à l'étape suivante, sur <span className="bg-yellow-600 text-white">Annuler</span> pour fermer la fenêtre.</div>
        <div className="flex justify-evenly py-4">
          <Button bgColor="bg-primary-dark font-bold" clicked={create}>Continuer</Button>
          <Button bgColor="bg-yellow-600" clicked={() => setShowConfirmNext(false)}>Annuler</Button>
        </div>
      </Modal>
    </Form>
  );
};

export default StepConfirm;