import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const Pagination = ({ currentPageOffset, currentPageCount, totalResults, pagesCount, currentPage, onPageChange }) => {
  const [pagesDisplay, setPagesDisplay] = useState([]);

  useEffect(() => {
    let temp = [];
    if (pagesCount > 7) {
      temp = createPageArray(currentPage, pagesCount);
    } else {
      temp = Array.from({length: pagesCount}, (_, index) => index + 1);
    }
    setPagesDisplay(temp);
  }, [pagesCount, currentPage]);

  const createPageArray = (currentPage, lastPage, delta = 1) => {
    const range = Array(lastPage)
        .fill()
        .map((_, index) => index + 1);

    return range.reduce((pages, page) => {
        // allow adding of first and last pages
        if (page === 1 || page === lastPage) {
            return [...pages, page];
        }

        // if within delta range add page
        if (page - delta <= currentPage && page + delta >= currentPage) {
            return [...pages, page];
        }

        // otherwise add 'gap if gap was not the last item added.
        if (pages[pages.length - 1] !== '...') {
            return [...pages, '...'];
        }

        return pages;
    }, []);
  }

  return (
    <div className="py-3 flex items-center justify-between border-t border-gray-200">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          type="button"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          Précédent
        </button>
        <button
          type="button"
          disabled={pagesCount === currentPage}
          onClick={() => onPageChange(currentPage + 1)}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          Suivant
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Affiche <span className="font-medium">{currentPageOffset + 1}</span> à <span className="font-medium">{currentPageOffset + currentPageCount}</span> de{' '}
            <span className="font-medium">{totalResults}</span> résultats
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
            <button
              type="button"
              disabled={currentPage === 1}
              onClick={() => onPageChange(currentPage - 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Précédent</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {pagesDisplay.map((page, index) => {
              return (
                <button
                  key={index}
                  type="button"
                  onClick={() => onPageChange(page)}
                  disabled={(page === currentPage || page === '...') ? 'disabled' : undefined}
                  aria-current={page === currentPage ? 'page' : undefined}
                  className={`${page === currentPage ? 'bg-primary text-white font-bold': 'bg-white hover:bg-gray-50 text-gray-500 font-bold'} border-gray-300 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {page}
                </button>
              );
            })}
            <button
              type="button"
              disabled={pagesCount === currentPage}
              onClick={() => onPageChange(currentPage + 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Suivant</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
