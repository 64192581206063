import * as constants from './constants';

export const login = (data) => {
    return {
        type: constants.LOGIN,
        data
    };
};

export const loginSuccess = (data) => {
    return {
        type: constants.LOGIN_SUCCESS,
        data,
    };
};

export const loginFailure = ({ error }) => {
    return {
        type: constants.LOGIN_FAILURE,
        error,
    };
};

export const logout = () => {
    return {
        type: constants.LOGOUT,
    };
};

export const setToken = ({ token }) => {
    return {
        type: constants.SET_TOKEN,
        token
    };
};

export const setReady = () => {
    return {
        type: constants.SET_READY,
    };
};

export const setLoggedIn = () => {
    return {
        type: constants.SET_LOGGED_IN,
    };
};

export const setLoggedOut = () => {
    return {
        type: constants.SET_LOGGED_OUT,
    };
};

export const createAccount = ({ email, password }) => {
    return {
        type: constants.CREATE_ACCOUNT,
        email,
        password,
    };
};

export const createAccountSuccess = (data) => {
    return {
        type: constants.CREATE_ACCOUNT_SUCCESS,
        data,
    };
};

export const createAccountFailure = ({ error }) => {
    return {
        type: constants.CREATE_ACCOUNT_FAILURE,
        error
    };
};

export const forgotPassword = ({ email }) => {
    return {
        type: constants.FORGOT_PASSWORD,
        email,
    };
};

export const forgotPasswordSuccess = ({ data }) => {
    return {
        type: constants.FORGOT_PASSWORD_SUCCESS,
        data,
    };
};

export const forgotPasswordFailure = ({ error }) => {
    return {
        type: constants.FORGOT_PASSWORD_FAILURE,
        error
    };
};

export const resetPassword = ({ email, password, password_confirmation, token }) => {
    return {
        type: constants.RESET_PASSWORD,
        email,
        password,
        password_confirmation,
        token,
    };
};

export const resetPasswordSuccess = ({ data }) => {
    return {
        type: constants.RESET_PASSWORD_SUCCESS,
        data,
    };
};

export const resetPasswordFailure = ({ error }) => {
    return {
        type: constants.RESET_PASSWORD_FAILURE,
        error
    };
};
