import { 
  Button, Modal, Loader,
  FormSection, Form,
  Select, Textarea, Input, Combobox,
  ModuleLoader,
  SearchCommon,
  CommonLoader,
} from '@components';
import debounce from 'lodash.debounce';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { 
  getModules,
  removeModule
} from '@store/patrimony/actions';
import { 
  resetMarket
} from '@store/market/actions';
import { 
  selectModules, 
  isLoading as selectLoadingPatrimony, 
  selectedPatrimony,
} from '@store/patrimony/selector';
import { selectCurrent, isLoading, selectCurrentIsReady } from '@store/workOrder/selector';
import { setSettings, setPatrimony, setMarket, setTasks } from '@store/workOrder/actions';
import { getModuleTypes } from '@services/patrimony.service';
import { validateStep } from '@services/validation.service';
import { getWorkTypes } from '@store/workType/actions';
const moduleTypes = getModuleTypes();

const StepSettings = ({ onComplete, onNext, onPrevious }) => {
  const [showConfirmNext, setShowConfirmNext] = useState(false);
  const [searchCode, setSearchCode] = useState(null);
  const dispatch = useDispatch();
  const modules = useSelector(selectModules);
  const current = useSelector(selectCurrent);
  const patrimonyIsLoading = useSelector(selectLoadingPatrimony, shallowEqual);
  const loading = useSelector(isLoading);
  const ready = useSelector(selectCurrentIsReady);

  const goToMarket = () => {
    onNext();
  }

  const confirmNext = () => {
    goToMarket();
  };

  const loadModules = () => {
    dispatch(getModules({
      page: 1,
      size: 10,
      moduleType: current.patrimony.moduleType ? current.patrimony.moduleType.type : null,
      search: searchCode,
    }))
  };

  const debounceLoadModules = useCallback(debounce(loadModules, 500, true),
    [searchCode, current.moduleType]
  );

  const updateSearch = useCallback(debounce((value) => {
    setSearchCode(value);
  }, 300));

  const changeModuleType = (type) => {
    if (type === 'common') {
      onSettingsChange({ workable: null });
    } else {
      onSettingsChange({ workable: 'module' });
    }
    dispatch(removeModule()); // Remove loaded module data
    dispatch(resetMarket()); // Remove loaded market data
    loadWorkTypes(type);
    onPatrimonyChange({ moduleType:type,moduleData:null, });
    dispatch(setTasks({tasks:[]}));
    dispatch(setMarket({
      workType: null,
      unitPrice: null,
      endAt: current.endAt,
      startAt:current.startAt,
      market: null,
    }));
  };

  const loadWorkTypes = (type) => {
    dispatch(getWorkTypes({ moduleType: type.type }));
  };

  const selectModule = (item) => {
    onSettingsChange({ workable: 'module' });
    onPatrimonyChange({ moduleData: item });
  };

  const onSettingsChange = ({ 
    description = current.description, 
    workable = current.workable, 
  }) => {
    dispatch(setSettings({
      description,
      workable,
    }))
  };

  const displayTenants = item => {
    let tenants = '';
    if (item.contracts && item.contracts.length > 0) {
      if (item.contracts[0] && item.contracts[0].contract && item.contracts[0].contract.tenants && item.contracts[0].contract.tenants.length > 0) {
        item.contracts[0].contract.tenants?.map((tenant,i) => {
          tenants += `${tenant.tenant.lastname} ${tenant.tenant.firstname}`
          if ((i+1) < item.contracts[0].contract.tenants.length) {
            tenants += ', ';
          }
        });
      }
    }
    return tenants;
  }

  const onPatrimonyChange = ({ 
    moduleType = current.patrimony.moduleType, 
    moduleData = current.patrimony.module,
    program = current.patrimony.program,
    building = current.patrimony.building,
    staircase = current.patrimony.staircase,
    floor = current.patrimony.floor,
    contract = current.patrimony.contract,
  }) => {
    dispatch(setPatrimony({
      ...current.patrimony,
      moduleType,module:moduleData,program,building,staircase,floor,contract,
    }));
  };

  const getTenantNames = (moduleData) => {
    let display = "";
    if (moduleData.current_contract && moduleData.current_contract.contract) {
      moduleData.current_contract.contract.tenants.forEach((contractTenant, index) => {
        if (contractTenant.tenant) {
          display += contractTenant.tenant.lastname + ' ' + contractTenant.tenant.firstname;
          if ((index+1) < moduleData.current_contract.contract.tenants.length) {
            display += ', ';
          }
        }
      });
    }
    return display;
  };

  useEffect(() => {
    const { description, moduleType, module: moduleData } = current;
    if (moduleType && ready) {
      onSettingsChange({ description });
      onPatrimonyChange({ moduleType, moduleData });
    }
  }, []);

  useEffect(() => {
    if (current.patrimony.moduleType && searchCode) {
      loadModules();
    }
  }, [searchCode, current.patrimony.moduleType]);

  return (
    <>
      {ready &&
        <Form actions={
          <>
            <Button 
              disabled={!onPrevious}
              clicked={onPrevious || undefined} 
              bgColor="bg-transparent" 
              textColor="text-gray-500" 
              border="border-gray-300">Précédent</Button>
            <Button 
              fontWeight="bold" 
              disabled={!validateStep('settings', current)}
              clicked={confirmNext || undefined}>Suivant</Button>
          </>
        } loading={loading || patrimonyIsLoading}
        autoComplete="off">
          <FormSection 
            title="Général" 
            subtitle="Cette étape permet de renseigner les informations générales du bon de travaux.">
            <div className="sm:col-span-6">
              <Textarea 
                label="Description"
                name="description"
                defaultValue={current.description}
                onChange={(id, value) => onSettingsChange({ description: value })}
                rows={3}
                placeholder="Décrivez brièvement l'objet du bon de travaux"
                required
              />
            </div>
          </FormSection>
          <FormSection 
            title="Patrimoine"
            subtitle="Renseignez le type de patrimoine puis recherchez le module ou la partie commune concerné par ce bon de travaux."
            className="pt-8 relative">
            <div className="sm:col-span-6">
              <Select 
                label="Type de patrimoine" 
                name="locationTypes"
                options={moduleTypes}
                displayedProperty="name"
                onSelection={(item) => changeModuleType(item)}
                selected={current.patrimony.moduleType}
                placeholder="Sélectionnez un type de module"
                className="w-full"
                required />
            </div>
            <div className="sm:col-span-6">
              { current.patrimony && current.patrimony.moduleType &&
                <>
                  { current.patrimony.moduleType.type === 'common' ?
                    <SearchCommon 
                      onWorkableChange={(workable) => onSettingsChange({ workable })}
                      onPatrimonyChange={onPatrimonyChange} /> :
                    <>
                      { modules && <Combobox 
                        items={modules.data} 
                        selectedItem={current.patrimony.module} 
                        onSelection={(item) => {
                          selectModule(item)}
                        }
                        onSearch={(value) => updateSearch(value)}
                        displayKey="code" 
                        subDisplay={(item) => getTenantNames(item)}
                        valueKey="uid" 
                        label={<>Rechercher un module <span className="text-red-500">*</span></>}
                        placeholder="Entrez le code d'un module ou le nom d'un locataire" /> }
                    </>
                  }
                </>
              }

              <div className="py-4">
                {current && current.workable === 'module' && current.patrimony.module && 
                  <div className="relative">
                    <label className="mb-1 font-bold block text-sm font-medium text-gray-700">Module séléctionné</label>
                    <ModuleLoader 
                      module={current.patrimony.module.uid} 
                      onLoaded={onPatrimonyChange}
                      showCurrentContract={true} />
                  </div>
                }

                {current && (['program', 'building', 'staircase', 'floor'].indexOf(current.workable) !== -1) &&
                  <div className="relative">
                    <label className="mb-1 font-bold block text-sm font-medium text-gray-700">Partie commune séléctionnée</label>
                    <CommonLoader 
                      workable={current.workable} 
                      onLoaded={onPatrimonyChange} />
                  </div>
                }
              </div>
            </div>
          </FormSection>
          <Modal 
            show={showConfirmNext} 
            onClose={() => setShowConfirmNext(false)}
            title="Le module a été modifié.">
            <div>En poursuivant, vous supprimez les éléments séléctionnés dans les étapes suivantes. Voulez-vous continuer?</div>
            <div className="text-sm py-2">Cliquez sur <span className="bg-primary-dark text-white font-bold">Continuer</span> pour accepter le changement et passer à l'étape suivante, sur <span className="bg-yellow-600 text-white">Annuler</span> pour fermer la fenêtre.</div>
            <div className="flex justify-evenly py-4">
              <Button bgColor="bg-primary-dark font-bold" clicked={goToMarket}>Continuer</Button>
              <Button bgColor="bg-yellow-600">Annuler</Button>
            </div>
          </Modal>
        </Form>
      }
    </>
  );
};

export default StepSettings;