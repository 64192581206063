const Textarea = ({ 
  label, 
  placeholder, 
  defaultValue = "", 
  rows = 1, 
  name, 
  onChange, 
  required = false,
  register,
  rules,
  errors,
  ...props 
}) => {
  const textChanged = (e) => {
    onChange(e.target.id, e.target.value);
  };

  return (
    <>
      {label && <label htmlFor={name} className="font-bold block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>}
      <div className="mt-1">
        {register && rules ?
          <>
            <textarea
              name={name}
              placeholder={placeholder}
              rows={rows}
              onChange={textChanged}
              className={`shadow-sm block w-full sm:text-sm rounded-md${errors ? ' border border-red-500 hover:border-red-500':' border-gray-300'}`}
              defaultValue={defaultValue}
              {...register(name, {...rules, onChange: textChanged})}
              {...props}
            ></textarea>
            {errors && <div className="text-red-500">{errors.message}</div>}
          </>:
          <textarea
            name={name}
            placeholder={placeholder}
            rows={rows}
            onChange={textChanged}
            className={`shadow-sm block w-full sm:text-sm rounded-md${errors ? ' border border-red-500 hover:border-red-500':' border-gray-300'}`}
            defaultValue={defaultValue}
            {...props}
          ></textarea>
        }
      </div>
    </>
  );
};

export default Textarea;
