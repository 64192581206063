import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateWorkOrder } from '@store/workOrder/actions';
import { selectCurrentValidation, selectError, isLoading } from '@store/workOrder/selector';
import { Modal, Button, Form, FormSection, Input, DatePicker } from '@components';
import { useForm } from "react-hook-form";
import { getFormError } from '@services/validation.service';
import { addDays } from "date-fns";
import { toast } from 'react-toastify';

const WorkOrderValidate = ({ triggerElement, workOrder, onValidate, beforeOpen, modalZIndex = 'z-10' }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const TriggerElement = triggerElement;
  const validation = useSelector(selectCurrentValidation);
  const error = useSelector(selectError);
  const loading = useSelector(isLoading);

  const validate = () => {
    dispatch(validateWorkOrder({ uid: workOrder.uid }));
  };

  useEffect(() => {
    if (validation.is_valid) {
      setShowModal(false);
      toast.success('Le bon de travaux a été validé.');
      onValidate && onValidate();
    }
  }, [validation]);

  useEffect(() => {
    if (error && error.response && error.response.data) {
      toast.error(`Une erreur s'est produite : ${error.response.data.message}`);
    }
  }, [error]);

  return (
    <>
      <div onClick={() => {
        beforeOpen && beforeOpen();
        setShowModal(true);
      }}>
        {triggerElement}
      </div>
      <Modal 
        show={showModal} title="Valider le bon de travaux"
        onClose={() => setShowModal(false)}
        zIndex={modalZIndex}>
        Cette action déclenchera les actions suivantes :
        <ul>
          <li>- Le bon de travaux sera synchronisé avec Immoware</li>
          <li>- Un email de confirmation sera envoyé au Resid'Manager et à ses supérieurs</li>
        </ul>
        <div className="font-bold text-lg mt-4">Voulez-vous continuer ?</div>
        <div className="flex justify-evenly mt-4">
          <Button bgColor="bg-transparent" textColor="text-primary" border="border-primary" clicked={() => setShowModal(false)}>Annuler</Button>
          <Button clicked={validate}>Confirmer</Button>
        </div>
      </Modal>
    </>
  );
};

export default WorkOrderValidate;
