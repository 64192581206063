import { format as regularFormat, parseISO } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime, format as tzFormat, toDate, formatInTimeZone } from 'date-fns-tz';

export const toDateString = (date, pattern = 'dd/MM/yyyy', timezone = 'Europe/Paris') => {
  return tzFormat(date, pattern, { timeZone: timezone });
}

export const fromStringToDate = (date, pattern = 'dd/MM/yyyy') => {
  try {
    return regularFormat(parseISO(date), pattern);
  } catch {
    return date;
  }
} 

export const fromStringToDatetime = (date, pattern = 'dd/MM/yyyy H:mm:s') => {
  try {
    return regularFormat(parseISO(date), pattern);
  } catch {
    return date;
  }
};

export const fromDateToUTCString = (date, pattern = 'dd/MM/yyyy H:mm:s') => {
  try {
    const time = date.toISOString();
    const parsedTime = parseISO(time);

    const formatInTimeZone = (date, fmt, tz) =>
      tzFormat(utcToZonedTime(date, tz), 
             fmt, 
             { timeZone: tz });

    return formatInTimeZone(parsedTime, pattern, "UTC");
  } catch (error) {
    return date;
  }
};

export const truncate = (text, maxLength, withEllipsis) => {
  if (!text) return null;
  if (text.length < maxLength) {
      return text;
  }

  return text.slice(0, maxLength) + (withEllipsis?'...':'')
};

export const mergeWithoutDuplicates = (array1, array2) => {
  array1.forEach((item1, index) => {
    let found = array2.find((i) => i.uid === item1.uid);
    if (found) array1.splice(index, 1);
  });
  let array3 = array1.concat(array2);
  return array3;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}
