import { useState, useEffect } from 'react';
import { Page, ErrorPanel } from '@components';
import { useParams } from 'react-router-dom';

const ErrorPage = () => {
  const { status } = useParams();
  const { search } = window.location;
  const [ressourceID, setRessourceID] = useState(null);
  const [ressourceType, setRessourceType] = useState(null);
  const [ressourceAction, setRessourceAction] = useState(null);

  useEffect(() => {
    if (search) {
      let params = new URLSearchParams(search);
      setRessourceType(params.get('ressource_type'));
      setRessourceID(params.get('ressource_id'));
      setRessourceAction(params.get('ressource_action'));
    }
  }, []);

  return <Page>
    <ErrorPanel 
      status={status} 
      ressourceType={ressourceType}  
      ressourceID={ressourceID} 
      ressourceAction={ressourceAction} />
  </Page>;
};

export default ErrorPage;
