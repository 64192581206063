export const orderTasks = (tasks) => {
  let ordered = [];
  tasks.forEach(task => {
    if (task.label === 'base') {
      ordered.unshift(task);
    } else {
      ordered.push(task);
    }
  });
  return ordered;
};