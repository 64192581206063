import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Breadcrumb } from '@components';

const PageHeader = ({ breadcrumbs, withLayout, title, ...props }) => {
  const navigate = useNavigate();

  return (
    <div {...props}>
      {withLayout && <div>
        <nav className="sm:hidden" aria-label="Back">
          <a href="/" className="flex items-center text-sm font-medium text-gray-400 hover:text-gray-200" onClick={() => navigate(-1)}>
            <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500" aria-hidden="true" />
            Retour
          </a>
        </nav>
      </div>}
      <div className="hidden sm:block">
        {breadcrumbs &&
          <Breadcrumb breadcrumbs={breadcrumbs} currentTitle={title} />
        }
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-primary sm:text-3xl sm:truncate">
            {title}
          </h2>
        </div>
      {/*  <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
          >
            Edit
          </button>
          <button
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
          >
            Publish
          </button>
        </div>*/}
      </div>
    </div>
  );    
};

export default PageHeader;
