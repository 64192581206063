export const LOGIN = '[AUTH] LOGIN';
export const LOGOUT = '[AUTH] LOGOUT';
export const LOGIN_SUCCESS = '[AUTH] LOGIN_SUCCESS';
export const LOGIN_FAILURE = '[AUTH] LOGIN_FAILURE';

export const SET_TOKEN = '[AUTH] SET_TOKEN';
export const SET_READY = '[AUTH] SET_READY';
export const SET_LOGGED_IN = '[AUTH] SET_LOGGED_IN';
export const SET_LOGGED_OUT = '[AUTH] SET_LOGGED_OUT';

export const GET_AUTH_USER = '[AUTH] GET_AUTH_USER';
export const GET_AUTH_USER_SUCCESS = '[AUTH] GET_AUTH_USER_SUCCESS';
export const GET_AUTH_USER_FAILURE = '[AUTH] GET_AUTH_USER_FAILURE';

export const CREATE_ACCOUNT = '[AUTH] CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = '[AUTH] CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = '[AUTH] CREATE_ACCOUNT_FAILURE';

export const FORGOT_PASSWORD = '[AUTH] FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = '[AUTH] FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = '[AUTH] FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD = '[AUTH] RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = '[AUTH] RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = '[AUTH] RESET_PASSWORD_FAILURE';
