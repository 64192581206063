import { 
  Button,
  FormSection,
  Form,
  TaskSelection,
  TaskSearch,
  Loader,
} from '@components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnitPriceItems, isLoading } from '@store/market/selector';
import { getUnitPriceItems } from '@store/market/actions';
import { setTasks as setTasksAction } from '@store/workOrder/actions';
import { selectCurrentTasks, selectCurrent } from '@store/workOrder/selector';
import { validateStep } from '@services/validation.service';
import { mergeWithoutDuplicates } from '@services/utils.service';
import { orderTasks } from '@services/market.service';
import ReactJson from 'react-json-view';

const StepTasks = ({ onComplete, onNext, onPrevious }) => {
  const [tasks, setTasks] = useState([]);
  const dispatch = useDispatch();
  const unitPriceItems = useSelector(selectUnitPriceItems);
  const workOrderTasks = useSelector(selectCurrentTasks);
  const { unitPrice, patrimony } = useSelector(selectCurrent);
  const loading = useSelector(isLoading);
  const [savedMustSelect, setSavedMustSelect] = useState([]);
  const [savedCantSelect, setSavedCantSelect] = useState([]);

  const goToServices = () => {
    onNext();
  }

  const onTaskChange = (items) => {
    onChangeTasks({ updatedTasks: [...items] });
  };

  const prepareTasks = (items) => {
    let { data } = unitPriceItems;
    data = orderTasks(data);
    let preparedTasks = [];
      data.forEach(price => {
        const isMandatory = (price.is_mandatory && price.rooms_number && price.rooms_number == patrimony.module.rooms_number) ? true : false;
        let found = false;
        if (items && items.length) {
          found = items.find(task => task.price && task.price.uid === price.uid);
        }
        preparedTasks.push(prepareTask({
          taskDisplayed: price,
          taskInSelection: found,
          isMandatory
        }));
    });
    setTasks(preparedTasks);
  };

  const prepareTask = ({ taskDisplayed, taskInSelection, isMandatory }) => {
    return {
      label: taskDisplayed.label,
      uid: taskInSelection ? taskInSelection.uid : undefined,
      price: taskDisplayed,
      quantity: taskInSelection ? taskInSelection.quantity : (taskDisplayed && taskDisplayed.default_quantity ? taskDisplayed.default_quantity : 0) ,
      checked: taskInSelection ? true : false,
      disabled: isMandatory ? true : false,
      comments: taskInSelection ? taskInSelection.comments : '',
      cantSelect: mergeWithoutDuplicates(taskDisplayed.cant_select, taskDisplayed.cant_select_inherited),
      mustSelect: taskDisplayed.must_select,
      isMandatory,
      billableAmountWithTaxes: taskInSelection ? taskInSelection.billable_amount_with_taxes : null,
    };
  };

  const onChangeTasks = ({ updatedTasks }) => {
    dispatch(setTasksAction({
      tasks: updatedTasks.filter(task => task.checked)
    }));
  };

  const initRestrictions = () => {
    let mustSelect = [];
    let cantSelect = [];
    tasks.forEach((item, idx) => {
      if (item.checked) {
        item.mustSelect.forEach(i => {
          mustSelect.push({
            source: item,
            target: i
          });
        });
        item.cantSelect.forEach(i => {
          cantSelect.push({
            source: item,
            target: i
          });
        });
      }
    });
    applyRestrictions(mustSelect,cantSelect);
  };

  const applyRestrictions = (mustSelect, cantSelect) => {
    let changed = false;
    tasks.forEach((item, idx) => {
      mustSelect.forEach(mustSelectItem => {
        if (mustSelectItem.target.uid === item.price.uid) {
          if (!item.checked) {
            tasks[idx].checked = true;
            tasks[idx].disabled = false;
            changed = true;
          }
          if (!tasks[idx].mustSelectFor) {
            tasks[idx].mustSelectFor = [];
          }
          tasks[idx].mustSelectFor.push(mustSelectItem.source);
        }
      });
      cantSelect.forEach(cantSelectItem => {
        if (cantSelectItem.target.uid === item.price.uid) {
          if (!item.disabled && !item.checked) {
            if (!item.checked) {
              tasks[idx].checked = false;
              tasks[idx].disabled = true;
              changed = true;
            } else {

            }
          }
          if (!tasks[idx].cantSelectBecause) {
            tasks[idx].cantSelectBecause = [];
          }
          tasks[idx].cantSelectBecause.push(cantSelectItem.source);
        }
      });
    });
    setSavedMustSelect(mustSelect);
    setSavedCantSelect(cantSelect);
  };

  useEffect(() => {
    initRestrictions();
  }, [tasks]);

  useEffect(() => {
    if (unitPrice) {
      dispatch(getUnitPriceItems({
        unitPriceUid: unitPrice.uid,
        size:10000,
        moduleType: patrimony.moduleType.type,
        roomsNumber: patrimony.moduleType.type !== 'common' && patrimony.module ? patrimony.module.rooms_number : null
      }));
    }
  }, [unitPrice, unitPrice?.service_provider]);

  useEffect(() => {
    if (unitPriceItems) {
      prepareTasks(workOrderTasks);
    }
  }, [unitPriceItems, workOrderTasks]);

  return (
    <Form actions={
      <>
        <Button 
          disabled={!onPrevious}
          clicked={onPrevious || undefined} 
          bgColor="bg-transparent" 
          textColor="text-gray-500" 
          border="border-gray-300">Précédent</Button>
        <Button 
          fontWeight="bold" 
          disabled={!validateStep('tasks',{tasks:workOrderTasks})}
          clicked={goToServices || undefined}>Suivant</Button>
      </>
    } loading={loading} autoComplete="off">
      <FormSection
        title="Choix des prestations" 
        subtitle="Cette étape permet de choisir les services du fournisseurs.">
        <div className="sm:col-span-6">
          {/*<ReactJson id="json-pretty" src={savedMustSelect} collapsed={true} name="mustSelect"/>
          <ReactJson id="json-pretty" src={savedCantSelect} collapsed={true} name="cantSelect"/>*/}
          {patrimony.moduleType && tasks &&
            <TaskSelection
              options={tasks}
              onChange={onTaskChange}
              moduleType={patrimony.moduleType}
              displayCheckboxes={unitPrice?.display_checkboxes}
              maxSelection={unitPrice?.max_details_number}
              mustSelect={savedMustSelect}
              cantSelect={savedCantSelect}
            ></TaskSelection>
          }
        </div>
      </FormSection>
    </Form>
  );
};

export default StepTasks;