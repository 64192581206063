import * as constants from "./constants";

export const getWorkTypes = ({ moduleType }) => {
    return {
        type: constants.GET_WORK_TYPES,
        moduleType,
    };
};

export const getWorkTypesSuccess = ({ data }) => {
    return {
        type: constants.GET_WORK_TYPES_SUCCESS,
        data,
    };
};

export const getWorkTypesFailure = ({ error }) => {
    return {
        type: constants.GET_WORK_TYPES_FAILURE,
        error,
    };
};
