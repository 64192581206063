import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as api from './api';
import * as patrimonyApi from '../patrimony/api';
import { 
    selectCurrent, 
    selectSingleTasksData,
} from './selector';
import { select, call, all } from 'redux-saga/effects';
import * as actions from './actions';
import * as constants from './constants';
import { getMarket } from '../market/api';
import { 
    getWorkType, 
    prepareTasks,
    getSelectedWorkable,
    getInitialWorkable
} from '@services/workOrder.service';
import { 
    getModuleType, 
} from '@services/patrimony.service';
import * as patrimonyActions from '../patrimony/actions';
import { getWorkTypes } from '../workType/actions';

export function* workOrderSaga() {
    yield takeLatest(constants.GET_WORK_ORDERS, getWorkOrders);
    yield takeLatest(constants.GET_WORK_ORDER, getWorkOrder);
    yield takeLatest(constants.GET_PUBLIC_WORK_ORDER, getWorkOrderPublic);
    yield takeLatest(constants.GET_WORK_ORDER_TASKS, getWorkOrderTasks);
    yield takeLatest(constants.GET_WORK_ORDER_MARKET, getWorkOrderMarket);
    yield takeLatest(constants.GET_WORK_ORDER_PATRIMONY, getWorkOrderPatrimony);
    yield takeLatest(constants.CREATE_WORK_ORDER, createWorkOrder);
    yield takeLatest(constants.SET_WORK_ORDER, setWorkOrder);
    yield takeLatest(constants.UPDATE_WORK_ORDER, updateWorkOrder);
    yield takeLatest(constants.FINALISE_WORK_ORDER, finaliseWorkOrder);
    yield takeLatest(constants.DELETE_WORK_ORDER, deleteWorkOrder);
    yield takeLatest(constants.SET_TASKS, calculateTotalCost);
    yield takeLatest(constants.VALIDATE_WORK_ORDER, validateWorkOrder);
}

function* getWorkOrders({ params }) {
    try {
        const { data: payload } = yield api.getWorkOrders(params);
        yield dispatch(actions.getWorkOrdersSuccess(payload));
    } catch (e) {
        yield dispatch(actions.getWorkOrdersFailure(e));
    }
}

function* getWorkOrder({ uid, include }) {
    try {
        const { data: workOrder } = yield api.getWorkOrder({ uid, include });
        yield dispatch(actions.getWorkOrderSuccess(workOrder));
        yield dispatch(actions.getWorkOrderMarket(workOrder.market));
        if (workOrder.details && workOrder.details.length) {
            yield dispatch(actions.getWorkOrderTasksSuccess({data: workOrder.details}));
        }
    } catch (e) {
        yield dispatch(actions.getWorkOrderFailure(e));
    }
}

function* getWorkOrderPublic({ uid, include }) {
    try {
        const { data: workOrder } = yield api.getWorkOrderPublic({ uid, include });
        yield dispatch(actions.getPublicWorkOrderSuccess(workOrder));
    } catch (e) {
        yield dispatch(actions.getPublicWorkOrderFailure(e));
    }
}

function* getWorkOrderTasks({ uid }) {
    try {
        const { data: payload } = yield api.getWorkOrderTasks({ uid });
        yield dispatch(actions.getWorkOrderTasksSuccess(payload));
    } catch (e) {
        yield dispatch(actions.getWorkOrderTasksFailure(e));
    }
}

function* getWorkOrderMarket({ uid }) {
    try {
        const { data: payload } = yield getMarket({ 
            uid, 
            include: [
                'service_provider',
                'unit_prices',
                'work_type',
            ] 
        });
        yield dispatch(actions.getWorkOrderMarketSuccess(payload));
    } catch (e) {
        yield dispatch(actions.getWorkOrderTasksFailure(e));
    }
}

function* getWorkOrderPatrimony({ uid }) {
    try {
        yield dispatch(actions.getWorkOrderPatrimonySuccess());
    } catch (e) {
        yield dispatch(actions.getWorkOrderPatrimonyFailure(e));
    }
}

function* createWorkOrder() {
    try {
        const workOrderData = yield prepareWorkOrderData();
        const { data: payload } = yield api.createWorkOrder(workOrderData);
        yield dispatch(actions.createWorkOrderSuccess(payload));
    } catch (e) {
        yield dispatch(actions.createWorkOrderFailure(e));
    }
}

function* setWorkOrder({ workOrder, market = null }) {
    try {
        let moduleType = null;
        if (workOrder.modules && workOrder.modules.length && workOrder.modules[0].vacant_since) {
          moduleType = getModuleType('vacant');
        } else if (workOrder.modules && workOrder.modules.length && !workOrder.modules[0].vacant_since) {
          moduleType = getModuleType('rented');
        } else {
          moduleType = getModuleType('common');
        }
        yield dispatch(getWorkTypes({moduleType: moduleType.type}));
        yield dispatch(actions.setSettings({
          description: workOrder.label,
          workable: getInitialWorkable(workOrder),
        }));
        yield dispatch(actions.setPatrimony({
          moduleType,
          module: workOrder.modules && workOrder.modules.length ? workOrder.modules[0] : null,
          program: workOrder.programs && workOrder.programs.length ? workOrder.programs[0] : null,
          building: workOrder.buildings && workOrder.buildings.length ? workOrder.buildings[0] : null,
          staircase: workOrder.staircases && workOrder.staircases.length ? workOrder.staircases[0] : null,
          floor: workOrder.floors && workOrder.floors.length ? workOrder.floors[0] : null,
          contract: workOrder.full_contract,
        }));
        yield dispatch(actions.setMarket({
          workType: market ? market.data.work_type : null,
          unitPrice: {
              ...workOrder.unit_price, 
              service_provider: market ? market.data.service_provider : null,
              market: market ? market.data : null
          },
          startAt: new Date(workOrder.estimated_start), 
          endAt: new Date(workOrder.estimated_end),
        }));
        yield dispatch(actions.setTasks(
            {tasks: prepareTasks(workOrder.details)}
        ));
        yield dispatch(actions.setConsent(
            {consent: workOrder.consent}
        ));
        yield dispatch(actions.setValidation({validation: workOrder.validation}));
        yield dispatch(patrimonyActions.resetPatrimony());
        yield dispatch(actions.setWorkOrderSuccess());
    } catch (error) {
        console.warn(error)
    }
}

function* updateWorkOrder({ uid }) {
    try {
        const { tasks } = yield select(selectCurrent);
        const oldTasks = yield select(selectSingleTasksData);
        const workOrderData = yield prepareWorkOrderData();
        const { data: payload } = yield api.updateWorkOrder({
            uid,
            ...workOrderData
        });
        yield dispatch(actions.updateWorkOrderSuccess(payload));
    } catch (e) {
        yield dispatch(actions.updateWorkOrderFailure(e));
    }
}

function* prepareWorkOrderData() {
    yield dispatch(actions.resetSingle());
    const { 
        description, 
        patrimony,
        unitPrice, 
        startAt, endAt,
        tasks
    } = yield select(selectCurrent);
    let workOrderData = {
        estimated_end: endAt, 
        estimated_start: startAt, 
        unit_price_list: unitPrice.uid, 
        label: description,
    };
    const workable = getSelectedWorkable({
        module: patrimony.module,
        program: patrimony.program, 
        building: patrimony.building, 
        staircase: patrimony.staircase, 
        floor: patrimony.floor,
    });
    if (!workable) {
        throw new Error ("Work order has no workable.");
    } else {
        workOrderData[workable.key] = workable.value;
    }
    if (workable.key === 'modules' && patrimony.module && patrimony.module.current_contract) {
        workOrderData.contract = patrimony.module.current_contract.contract.uid;
    } else {
        workOrderData.contract = null;
    }
    
    workOrderData.details = tasks.map(task => {
        let line = {
            uid: task.uid,
            comments: task.comments,
            unit_price_item: task.price.uid,
            quantity: task.quantity,
        }

        if (task.price.is_billable && patrimony.moduleType.type === 'rented') {
            if (task.quantity == 0) {
                task.quantity = 1;
            }
            let totalLine = task.quantity * task.price.cost_excluding_tax * (1 + (task.price.tva / 100));
            line.billableAmountWithTaxes = totalLine >= 50 ? (totalLine / 2) : 0;
        }

        return line;
    });

    return workOrderData;
};

function* finaliseWorkOrder({ uid, startedAt, endedAt, comments }) {
    try {
        const { data: payload } = yield api.finaliseWorkOrder({ uid, startedAt, endedAt, comments });
        yield dispatch(actions.finaliseWorkOrderSuccess(payload));
    } catch (e) {
        yield dispatch(actions.finaliseWorkOrderFailure(e));
    }
}

function* validateWorkOrder({ uid }) {
    try {
        const { data: payload } = yield api.validateWorkOrder({ uid });
        yield dispatch(actions.validateWorkOrderSuccess(payload));
    } catch (e) {
        yield dispatch(actions.validateWorkOrderFailure(e));
    }
}

function* deleteWorkOrder({ uid }) {
    try {
        const { data: payload } = yield api.deleteWorkOrder({ uid });
        yield dispatch(actions.deleteWorkOrderSuccess(payload));
    } catch (e) {
        yield dispatch(actions.deleteWorkOrderFailure(e));
    }
}

function* calculateTotalCost({ tasks }) {
   var total = 0;
   if (tasks && tasks.length > 0) {
     tasks.forEach((item) => {
       if (item.price) {
           total += parseFloat(item.price.cost_excluding_tax) * item.quantity;
       }
     });
   }
   yield dispatch(actions.setTotalCost({ amount: total }));
}
