import api from '@/Api';
import { fromDateToUTCString } from '@services/utils.service';
import { zonedTimeToUtc, utcToZonedTime, format as tzFormat, formatInTimeZone } from 'date-fns-tz';

export function getIntervention({ workOrderUid, interventionUid }) {
    return api.get(`/work-orders/${workOrderUid}/interventions/${interventionUid}`);
}

export function updateIntervention({ workOrderUid, interventionUid, intervention: {
    start_at, end_at, comments
} }) {
    if (start_at > end_at) {
        throw "Erreur : La date de début doit être inférieure à la date de fin.";
    }
    return api.put(`/work-orders/${workOrderUid}/interventions/${interventionUid}`, {
        start_at: fromDateToUTCString(start_at, 'yyyy-MM-dd HH:mm:ss'), end_at: fromDateToUTCString(end_at, 'yyyy-MM-dd HH:mm:ss'), comments
    });
}
