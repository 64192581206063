// import API from 'API';
// import {getRandomInt} from "../../shared/number";
import { getYear, format } from 'date-fns';
import api from '../../Api';

export function getWorkOrders({ 
    page, size = 15, search = null, isValid = null, staffId = null }) {
    return api.get(`/work-orders?page[number]=${page}&sort=-created_at&page[size]=${size}${isValid !== null ? ('&is_valid[eq]='+isValid) : ''}&include=validation${search?'&code[like]='+search:''}${staffId?'&staff_id='+staffId:''}&include=modules,staircases,buildings,programs,validation,market,consent`);
}

export function getWorkOrder({ uid, include }) {
    return api.get(`/work-orders/${uid}${include ?`?include=${include.join(',')}`:''}`);
}

export function getWorkOrderPublic({ uid, include }) {
    return api.get(`/work-orders/${uid}/public${include ?`?include=${include.join(',')}`:''}`);
}

export function getWorkOrderTasks({ uid }) {
    return api.get(`/work-orders/${uid}/details`);
}

export function createWorkOrder({ 
    label, estimated_start, estimated_end, unit_price_list, 
    modules, programs, buildings, staircases, floors, year, details,
    contract
}) {
    return api.post(`/work-orders`, { 
        label, 
        estimated_start: estimated_start ? format(estimated_start, 'yyyy-MM-dd') : estimated_start,
        estimated_end: estimated_end ? format(estimated_end, 'yyyy-MM-dd') : estimated_end, 
        unit_price_list, 
        year: 2024,
        modules, programs, buildings, staircases, floors, details, contract,
    });
}

export function createWorkOrderTask({ workOrderUid, task: { price, quantity, comments } }) {
    return api.post(`/work-orders/${workOrderUid}/details`, {
        unit_price_item: price.uid,
        quantity,
        comments
    });
}

export function updateWorkOrder({ uid,
    label, estimated_start, estimated_end, unit_price_list, 
    modules, programs, buildings, staircases, floors, year, details,
    contract,
}) {
    return api.put(`/work-orders/${uid}`, { 
        label, 
        estimated_start: estimated_start ? format(estimated_start, 'yyyy-MM-dd') : estimated_start,
        estimated_end: estimated_end ? format(estimated_end, 'yyyy-MM-dd') : estimated_end, 
        unit_price_list, 
        year,
        modules, programs, buildings, staircases, floors, details, contract,
    });
}

export function updateWorkOrderTask({ workOrderUid, taskUid, task: { price, quantity, comments } }) {
    return api.put(`/work-orders/${workOrderUid}/details/${taskUid}`, {
        quantity,
        comments
    });
}

export function deleteWorkOrderTask({ workOrderUid, taskUid }) {
    return api.delete(`/work-orders/${workOrderUid}/details/${taskUid}`);
}

export function finaliseWorkOrder({ uid, comments, startedAt, endedAt }) {
    return api.post(`/work-orders/${uid}/finalise`, { comments, started_at: startedAt, ended_at: endedAt });
}

export function validateWorkOrder({ uid }) {
    return api.post(`/work-orders/${uid}/validate`);
}

export function deleteWorkOrder({ uid }) {
    return api.delete(`/work-orders/${uid}`);
}