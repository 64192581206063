import * as constants from "./constants";
import * as api from './api';
import * as actions from './actions';
import { setConsent } from '@store/workOrder/actions';
import { takeLatest, put as dispatch } from 'redux-saga/effects';

export function* consentSaga() {
    yield takeLatest(constants.GET_CONSENT_BY_TOKEN, getConsentByToken);
    yield takeLatest(constants.UPDATE_CONSENT, updateConsent);
    yield takeLatest(constants.SEND_CONSENT, sendConsent);
}

function* getConsentByToken({ token }) {
    try {
        const { data: payload } = yield api.getConsentByToken({ token });
        yield dispatch(actions.getConsentByTokenSuccess(payload));
    } catch (e) {
        yield dispatch(actions.getConsentByTokenFailure(e));
    }
}

function* updateConsent({ consent, token }) {
    try {
        const { data: payload } = yield api.updateConsent({ consent, token });
        yield dispatch(actions.updateConsentSuccess(payload));
    } catch (e) {
        yield dispatch(actions.updateConsentFailure(e));
    }
}

function* sendConsent({ uid }) {
    try {
        const { data: payload } = yield api.sendConsent({ uid });
        yield dispatch(actions.sendConsentSuccess(payload));
        yield dispatch(setConsent({ consent: payload }));
    } catch (e) {
        yield dispatch(actions.sendConsentFailure(e));
    }
}
