import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { 
  Dashboard, 
  Login, 
  WorkOrderList, 
  WorkOrderCreate, 
  WorkOrderSingle, 
  Intervention, 
  WorkOrderStatus,
  WorkOrderConfirmation,
  ErrorPage,
  ResetPassword,
  WorkOrderConsent,
} from '@pages';
import { NotFound, Header, Footer, PrivateRoute } from '@components';

export const Paths = {
  LOGIN: '/login',
  DASHBOARD: '/',
  WORK_ORDER_LIST: '/bons-de-travaux',
  WORK_ORDER_SINGLE: '/bons-de-travaux/:uid',
  WORK_ORDER_CREATE: '/bons-de-travaux/nouveau',
  WORK_ORDER_UPDATE: '/bons-de-travaux/:uid/modifier',
  WORK_ORDER_STATUS: '/bons-de-travaux/:uid/status',
  WORK_ORDER_SAVED: '/bons-de-travaux/:uid/confirmation',
  RESET_PASSWORD: '/recuperation-mot-de-passe/:token',
  PUBLIC_INTERVENTION: '/public/bons-de-travaux/:workOrderUid/interventions/:interventionUid',
  PUBLIC_WORK_ORDER_STATUS: '/public/bons-de-travaux/:uid/status',
  PUBLIC_WORK_ORDER_CONSENT: '/public/bons-de-travaux/consentement',
  ERROR: '/erreur/:status',
};

export const Router = () => {
  return (
    <Routes>
      <Route path="*" element={
        <PrivateRoute component={NotFound}/>}/>
      <Route component={NotFound} />
      <Route path={Paths.LOGIN} element={<Login />} />
      <Route path={Paths.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={Paths.PUBLIC_INTERVENTION} element={<Intervention />} />
      <Route path={Paths.PUBLIC_WORK_ORDER_STATUS} element={<WorkOrderStatus noLayout isPublic />} />
      <Route path={Paths.PUBLIC_WORK_ORDER_CONSENT} element={<WorkOrderConsent noLayout isPublic />} />
      <Route exact path={Paths.DASHBOARD} element={
        <PrivateRoute component={Dashboard} />} 
      />
      <Route path={Paths.WORK_ORDER_LIST} element={
        <PrivateRoute component={WorkOrderList} />} />
      <Route path={Paths.WORK_ORDER_SINGLE} element={
        <PrivateRoute component={WorkOrderSingle} />} />
      <Route path={Paths.WORK_ORDER_CREATE} element={
        <PrivateRoute component={WorkOrderCreate} />} />
      <Route path={Paths.WORK_ORDER_UPDATE} element={
        <PrivateRoute component={WorkOrderCreate} />} />
      <Route path={Paths.WORK_ORDER_STATUS} element={
        <PrivateRoute component={WorkOrderStatus} />} />
      <Route path={Paths.WORK_ORDER_SAVED} element={
        <PrivateRoute component={WorkOrderConfirmation} />} />
      <Route path={Paths.ERROR} element={
        <PrivateRoute component={ErrorPage} />} />
    </Routes>
  );
};
