export const validateStep = (step, workOrder) => {
    let valid = false;
    switch (step) {
        case 'settings':
            valid = validateSettings(workOrder);
            break;
        case 'market':
            valid = validateMarket(workOrder);
            break;
        case 'tasks':
            valid = validateTasks(workOrder);
            break;
        case 'validate':
            valid = validateWorkOrder(workOrder);
            break;
        default:
            valid = false;
    }
    return valid;
};

export const validateString = (value, rule) => {
    switch (rule) {
        case 'email':
            return false;
    }
};

export const getPattern = (type) => {
    if (type === 'email') {
        return {
            value: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
            message: 'Cet adresse email est invalide.'
        };
    } else {
        return null;
    }
};

export const getFormErrorMessages = () => {
    return {
        required: "Ce champs est requis",
        email: "Cet email est invalide.",
        minLength: "Ce champs doit contenu au minimum :value caractères.",
        maxLength: "Ce champs doit contenir au maximum :value caractères.",
    };
};

export const getFormError = (type, value) => {
    if (!type) {
        return null;
    }
    return {
        value,
        message: getFormErrorMessages()[type] ? getFormErrorMessages()[type].replace(':value', value) : null
    };
};

const validateSettings = (workOrder) => {
    let valid = workOrder.description && 
        workOrder.patrimony.program;
    if (workOrder.patrimony && workOrder.patrimony.moduleType && workOrder.patrimony.moduleType.type !== 'common') {
        valid = workOrder.patrimony.floor && workOrder.patrimony.module;
    }
    return valid;
}

const validateMarket = (workOrder) => {
    return workOrder.unitPrice && workOrder.endAt && workOrder.startAt;
}

const validateTasks = (workOrder) => {
    return workOrder.tasks && workOrder.tasks.length;
}

const validateWorkOrder = (workOrder) => {
    return validateSettings(workOrder) && validateMarket(workOrder) && validateTasks(workOrder);
}
