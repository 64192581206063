export const getModuleTypes = () => {
  return [
    {
      type: 'vacant',
      name: 'Logement vacant'
    },
    {
      type: 'rented',
      name: 'Logement occupé'
    },
    {
      type: 'common',
      name: 'Partie commune'
    }
  ];
}

export const getModuleType = (type) => {
  let found = getModuleTypes().filter(item => item.type === type);
  return found ? found[0] : null;
};