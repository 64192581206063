import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as api from './api';
import * as actions from './actions';
import * as constants from './constants';

export function* workTypeSaga() {
    yield takeLatest(constants.GET_WORK_TYPES, getWorkTypes);
}

function* getWorkTypes({ moduleType }) {
    try {
        const { data } = yield api.getWorkTypes({ moduleType });
        yield dispatch(actions.getWorkTypesSuccess({ data }));
    } catch (e) {
        yield dispatch(actions.getWorkTypesFailure({ error: e }));
    }
}
