import { Page, Button, Section, Loader, Alert, Modal} from '@components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getConsentByToken, updateConsent } from '@store/workOrder/consent/actions';
import { selectConsent, isLoading, selectError } from '@store/workOrder/consent/selector';
import { toDateString, fromStringToDate } from '@/services/utils.service';

const WorkOrderConsent = ({ noLayout }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const error = useSelector(selectError);
  const loading = useSelector(isLoading);
  const consent = useSelector(selectConsent);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [choice, setChoice] = useState(false);
  const [refused, setRefused] = useState(false);

  const getNotFoundErrorMessage = () => {
    return <div className="text-center">
      <h2 className="font-bold text-lg mb-4">Le bon de travaux n'existe pas ou le consentement a expiré.</h2>
      Si vous avez reçu plusieurs emails de demande de consentement pour ce bon de travaux, veuillez utiliser le lien du dernier email reçu.<br/>
      <div className="text-sm mt-4">Si le problème persiste vous pouvez contacter notre équipe sur <a href="https://www.lesresidences.fr/nous-contacter" target="_blank" className="font-bold">notre formulaire de contact en ligne</a>.</div>
    </div>;
  };

  useEffect(() => {
    if (!searchParams.has('token') || searchParams.get('token').trim() === '') {
      setErrorMessage(getNotFoundErrorMessage());
    } else {
      dispatch(getConsentByToken({ token: searchParams.get('token') }));
    }
  }, []);

  useEffect(() => {
    if (error && error.response) {
      if (error.response.status === 404) {
        setErrorMessage(
          getNotFoundErrorMessage());
      } else {
        setErrorMessage('Une erreur est survenue.');
      }
    }
  }, [error]);

  const triggerAction = () => {
    setShowConfirm(false);
    dispatch(updateConsent({
      consent: choice,
      token: searchParams.get('token'),
    }));
  };

  const changeMind = () => {
    setChoice(true);
    setShowConfirm(true);
  };

  return (<>
    <Loader show={loading} />
    <Page title="Erreur" withLayout={!noLayout} hideHeader={true}>
      {errorMessage && <Alert text={errorMessage} />}
      {consent && 
        <Section className="p-4">
          Référence du bon de travaux : {consent.work_order_code}<br/>
          Montant des travaux qui vous sera refacturé : {consent.billable_amount_with_taxes} € TTC
          {!consent.consented_on ?
            <div className="flex items-center justify-between mt-4">
              <Button 
                bgColor="bg-green-600"
                fontWeight="font-bold"
                clicked={() => {
                  setChoice(true);
                  setShowConfirm(true);
                }}>J'accepte</Button>
              <Button 
                bgColor="bg-transparent" 
                textColor="text-red-600" 
                fontWeight="font-bold"
                clicked={() => {
                  setChoice(false);
                  setShowConfirm(true);
                }}>Je refuse</Button>
            </div> :
            <Alert text={`Merci, vous avez donné votre consentement le ${fromStringToDate(consent.consented_on)}.`} type="success"/>
          }
          {consent.refused_on && 
            <Alert text={
              <>
                Vous avez refusé la refacturation de ce bon de travaux le {fromStringToDate(consent.refused_on)}.<br/>
                Vous avez changé d'avis ? <button type="button" onClick={changeMind}>Cliquez ici.</button>
              </>} type="warning"/>}
        </Section>
      }
      <Modal 
        show={showConfirm} 
        onClose={() => setShowConfirm(false)}
        title="Confirmez votre choix :">
        <div className="flex flex-col items-center">
          <Button 
            className="text-lg"
            bgColor="bg-transparent" 
            textColor={choice ? "text-green-600" : "text-red-600"} 
            clicked={triggerAction}
            fontWeight="font-bold">{choice ? 'Je donne mon consentement':'Je refuse'}</Button>
        </div>
      </Modal>
    </Page>
  </>);
};

export default WorkOrderConsent;