import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaperClipIcon } from '@heroicons/react/solid';
import { TasksPriceTable, ModuleLoader, CommonLoader, WorkOrderBadges, } from '@components';
import { fromStringToDate, toDateString, fromStringToDatetime } from '@services/utils.service';
import { getCurrentStatus, getInitialWorkable } from '@services/workOrder.service';
import { getDetails, setPatrimony } from '@store/workOrder/actions';
import { 
  selectDetails, 
  isLoading as WOIsLoading, 
  selectSingleDetailsData,
  selectCurrent } from '@store/workOrder/selector';

const WorkOrderRecap = ({ 
    workOrder = {}, 
    market = {}, 
    moduleData = {}, 
    tasks = [], 
    patrimony = {},
    onLoadingStart,
    onLoadingEnd,
    showCurrentContract = true,
  }) => {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const [isBillable, setIsBillable] = useState(false);
  const [workableType, setWorkableType] = useState();
  const current = useSelector(selectCurrent);

  useEffect(() => {
    onLoadingStart && onLoadingStart();
  }, []);

  useEffect(() => {
    if (workOrder) {
      setWorkableType(getInitialWorkable(workOrder));
    }
    setReady(true);
  }, [workOrder]);
  
  useEffect(() => {
    if (current.patrimony.moduleType.type === 'rented') {
      setIsBillable(true);
    }
  }, [tasks, current.patrimony.moduleType]);

  const onPatrimonyChange = ({ 
    moduleType = current.patrimony.moduleType, 
    moduleData = current.patrimony.module,
    program = current.patrimony.program,
    building = current.patrimony.building,
    staircase = current.patrimony.staircase,
    floor = current.patrimony.floor,
  }) => {
    dispatch(setPatrimony({
      ...current.patrimony,
      moduleType,module:moduleData,program,building,staircase,floor,
    }));
  };

  useEffect(() => {
    if (workOrder && current && current.patrimony) {
      onLoadingEnd && onLoadingEnd();
    }
  }, [workOrder, current]);

  return (
    <>
      {ready && workOrder.label && <>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-2xl font-black text-primary-dark my-2 break-all">Bon de travaux n°{workOrder.code || 'N/A'}</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {workOrder.label} <br/>
            {workOrder.description}
          </p>
          <div className="flex flex-row mt-5 gap-1">
            <WorkOrderBadges workOrder={workOrder} />
          </div>
        </div>

        { workOrder && tasks && 
          <div>
            <div className="w-full">
              <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Date d'éxécution estimée</div>
                <div className="p-2 text-right">du { fromStringToDate(workOrder.estimated_start) } au { fromStringToDate(workOrder.estimated_end) }</div>
              </div>
              {workOrder.started_at && <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Date d'éxécution réelle</div>
                <div className="p-2 text-right">du { fromStringToDate(workOrder.started_at) } au { fromStringToDate(workOrder.ended_at) }</div>
              </div>}
              <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Statut</div>
                <div className="p-2 text-right">
                  {workOrder.ended_at ? 
                    `Terminé le ${fromStringToDate(workOrder.ended_at)}`:
                    'Non terminé'
                  }
                </div>
              </div>
            </div>
          </div>
        }

        <div className="mt-6">
          <h2 className="text-lg font-bold text-primary-darker uppercase my-2 px-4 py-2">Patrimoine</h2>
          { workableType === 'module' && 
            <>
              <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Type</div>
                <div className="p-2 text-right">Logement</div>
              </div>
              <ModuleLoader 
                onLoaded={onPatrimonyChange}
                module={workOrder.modules[0].uid}
                showCurrentContract={showCurrentContract}
                rowPadding="px-6 py-2" />
            </>
          }

          {(['program', 'building', 'staircase', 'floor'].indexOf(workableType) !== -1) &&
            <>
              <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Type</div>
                <div className="p-2 text-right">Partie commune</div>
              </div>
              <CommonLoader workable={workableType} 
                onLoaded={onPatrimonyChange}
                rowPadding="px-6 py-2" />
            </>
          }
          {/*<div className="w-full">
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
              <div className="p-2">Type</div>
              <div className="p-2 text-right">{ getInitialWorkable(workOrder) }</div>
            </div>
            {patrimony.program && 
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
              <div className="p-2">Programme</div>
              <div className="p-2 text-right">{ patrimony.program.label }</div>
            </div>}
            {patrimony.building &&
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
              <div className="p-2">Batiment</div>
              <div className="p-2 text-right">{ patrimony.building.label }</div>
            </div>}
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
              <div className="p-2">Adresse</div>
              {patrimony.staircase && 
                <div className="p-2 text-right">
                  {patrimony.staircase.street_number} {patrimony.staircase.street} <br/>
                  {patrimony.staircase.postal_code}, {patrimony.staircase.city}
                </div>
              }
            </div>
            {patrimony.floor &&
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
              <div className="p-2">Etage</div>
              <div className="p-2 text-right">{patrimony.floor.number}</div>
            </div>}
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
              <div className="p-2">Gardien</div>
              <div className="p-2 text-right"></div>
            </div>
          </div>*/}
        </div>

        {market && market.data && 
          <div className="mt-6">
            <h2 className="text-lg font-bold text-primary-darker uppercase my-2 px-4 py-2">Marché et fournisseur</h2>
            <div className="w-full">
              <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Marché</div>
                <div className="p-2 text-right break-all">
                  { market.data.label } { market.data.lot_code && ` / ${market.data.lot_code}`}<br/> 
                  Jusqu'au { fromStringToDate(market.data.lot_end_date) }<br/>
                  {market.data.work_type && <>{market.data.work_type.label}<br/></>}
                  {market.data.software_key}
                </div>
              </div>
              {market.data.service_provider &&
              <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Fournisseur</div>
                <div className="p-2 text-right break-all">{ market.data.service_provider.label }</div>
              </div>}
            </div>
          </div>
        }

        {current.consent && 
          <div className="mt-6">
            <h2 className="text-lg font-bold text-primary-darker uppercase my-2 px-4 py-2">Consentement</h2>
            <div className="w-full">
              <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Envoyé le</div>
                <div className="p-2 text-right break-all">
                  { current.consent.email_sent_on ? fromStringToDatetime(current.consent.email_sent_on) : "N/A" }
                </div>
              </div>
              {current.consent.email_sent_on && <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Envoyé à</div>
                <div className="p-2 text-right break-all">
                  { current.consent.email_sent_to ? fromStringToDatetime(current.consent.email_sent_to) : "N/A" }
                </div>
              </div>}
              {current.consent.consented_on && <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Accepté le</div>
                <div className="p-2 text-right break-all">
                  { fromStringToDatetime(current.consent.consented_on) }
                </div>
              </div>}
              {current.consent.refused_on && <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Refusé le</div>
                <div className="p-2 text-right break-all">
                  { fromStringToDatetime(current.consent.refused_on) }
                </div>
              </div>}
              {current.consent.email_error &&<div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
                <div className="p-2">Erreur d'envoi</div>
                <div className="p-2 text-right break-all">
                  { current.consent.email_error ? "Oui" : "Non" }
                </div>
              </div>}
            </div>
          </div>
        }

        {tasks && tasks.length > 0 &&
          <>
            <h2 className="text-lg font-bold text-primary-darker uppercase my-2 px-4 py-2">Prestations</h2>
            <div className="bg-white">
              <TasksPriceTable products={tasks} isBillable={isBillable} />
            </div>
          </>
        }
      </>}
    </>
  );
};

export default WorkOrderRecap;