import { Section, Loader, Alert } from '@components';
import { useEffect } from 'react';

const Form = ({ 
  children, 
  actions, 
  className, 
  loading, 
  error, 
  contentPadding = "p-8", 
  noBorder, 
  actionsWrapperClassName, 
  successMsg,
  ...props 
}) => {

  return (
    <form className={`relative ${className ? (' '+className) : ''}`} noValidate {...props}>
      <Loader show={loading} />
      <Section className={`space-y-8 divide-y divide-gray-200 ${contentPadding}`} noBorder={noBorder}>
        {children}
      </Section>
      {error && error.response &&
        <Alert type="error" text={
          error.response.data ? error.response.data.message || error.response.data : null
        }/>
      }
      {successMsg && 
        <Alert text={successMsg} type="success" />}
      <div className="pt-5">
        <div className={`${actionsWrapperClassName ? actionsWrapperClassName : 'flex justify-between'}`}>
          {actions}
        </div>
      </div>
    </form>
  );
};

export default Form;
