import * as constants from "./constants";

const initialState = {
  loading: false,
  error: null,
  single: null,
  saved: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_INTERVENTION:
        case constants.SAVE_INTERVENTION:
          return onLoading(state, action);
        case constants.GET_INTERVENTION_FAILURE:
        case constants.SAVE_INTERVENTION_FAILURE:
          return onFailure(state, action);
        case constants.GET_INTERVENTION_SUCCESS:
          return onGetSuccess(state, action);
        case constants.SAVE_INTERVENTION_SUCCESS:
          return onSaveSuccess(state, action);
        case constants.UPDATE_INTERVENTION:
          return onUpdateSuccess(state, action);
        default:
          return state;
    }
};

const onLoading = (state) => ({ 
  ...state, 
  loading: true,
  error: null,
  saved: false,
});

const onFailure = (state, { error }) => ({ 
  ...state, 
  loading: false,
  error: error,
  saved: false,
});

const onSaveSuccess = (state, { data }) => ({
  ...state, 
  loading: false,
  error: null,
  single: {
    ...data,
    start_at: data.start_at && new Date(data.start_at),
    end_at: data.end_at && new Date(data.end_at),
  },
  saved: true,
});

const onGetSuccess = (state, { data }) => ({
  ...state, 
  loading: false,
  error: null,
  single: {
    ...data,
    start_at: data.start_at && new Date(data.start_at),
    end_at: data.end_at && new Date(data.end_at),
  },
  saved: false,
});

const onUpdateSuccess = (state, { data }) => ({
  ...state, 
  loading: false,
  error: null,
  single: {
    ...state.single,
    ...data,
  },
  saved: false,
});

export default reducer;