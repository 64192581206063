import { takeLatest, put as dispatch } from 'redux-saga/effects';
import { removeItem, keys } from '@services/localStorage.service';
import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import { setReady, setLoggedIn, setLoggedOut } from '@store/auth/actions';

export function* userSaga() {
    yield takeLatest(constants.GET_USER_PROFILE, getUserProfile);
    yield takeLatest(constants.GET_USER_WORK_ORDERS, getUserWorkOrders);
    yield takeLatest(constants.GET_USER_PENDING_WORK_ORDERS, getUserPendingWorkOrders);
}

function* getUserProfile() {
    try {
        const { data: payload } = yield api.me();
        yield dispatch(actions.getUserProfileSuccess({ user: payload }));
        yield dispatch(setLoggedIn());
    } catch (error) {
        removeItem(keys.TOKEN);
        yield dispatch(actions.getUserProfileFailure({ error }));
        yield dispatch(setLoggedOut());
    }
    yield dispatch(setReady());
}

function* getUserWorkOrders({ page, size, filters, sort }) {
    try {
        const { data } = yield api.workOrders({ page, size, filters, sort });
        yield dispatch(actions.getUserWorkOrdersSuccess(data));
    } catch (error) {
        yield dispatch(actions.getUserWorkOrdersFailure({ error }));
    }
}


function* getUserPendingWorkOrders({ page, size, sort }) {
    try {
        const { data } = yield api.workOrders({ 
            page, 
            size, 
            sort,
            filters: {
                'validation[is_valid][eq]': 0
            } 
        });
        yield dispatch(actions.getUserPendingWorkOrdersSuccess(data));
    } catch (error) {
        yield dispatch(actions.getUserPendingWorkOrdersFailure({ error }));
    }
}
