import { 
  Button, 
  Select,
  FormSection,
  Form,
  DatePicker,
  Modal,
  MarketDetails,
} from '@components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getUnitPrices } from '@store/market/actions';
import { selectUnitPrices, isLoading } from '@store/market/selector';
import { setMarket as setWorkOrderMarket, setTasks } from '@store/workOrder/actions';
import { selectCurrent } from '@store/workOrder/selector';
import { selectWorkTypes } from '@store/workType/selector';
import { validateStep } from '@services/validation.service';

const StepMarket = ({ onComplete, onNext, onPrevious }) => {
  const dispatch = useDispatch();
  const unitPrices = useSelector(selectUnitPrices, shallowEqual);
  const marketIsLoading = useSelector(isLoading);
  const current = useSelector(selectCurrent);
  const [sortedUnitPrices, setSortedUnitPrices] = useState([]);
  const workTypes = useSelector(selectWorkTypes);

  const goToServices = () => {
    onNext();
  };

  const confirmNext = () => {
    goToServices();
  };

  const onMarketChange = ({ 
    workType = current.workType, 
    unitPrice = current.unitPrice,
    market = current.market,
    endAt = current.endAt,
    startAt = current.startAt,
  }) => {
    dispatch(setTasks({tasks: []}));
    dispatch(setWorkOrderMarket({workType,unitPrice,endAt,startAt,market,}));
  };

  const sortUnitPrices = () => {
    var sorted = unitPrices.data.sort((a, b) => {
      if (a.service_provider.label < b.service_provider.label) {
        return -1;
      }
      if (a.service_provider.label > b.service_provider.label) {
        return 1;
      }
      return 0;
    });
    setSortedUnitPrices(sorted);
  };

  useEffect(() => {
    if(current.workType) {
      onMarketChange({unitPrice: null,workType: current.workType});
      dispatch(getUnitPrices({ 
        page: 1, 
        workType: current.workType.code, 
        program: current.patrimony.program.uid ? current.patrimony.program.uid : null,
      }));
    }
  }, [current.workType]);

  useEffect(() => {
    if (current.unitPrice) {
      onMarketChange({unitPrice: current.unitPrice});
    } else if (unitPrices.data.length === 1) {
      onMarketChange({unitPrice: unitPrices.data[0]});
    } else if (unitPrices.data.length === 0) {
      onMarketChange({unitPrice: null});
    }
    if (unitPrices.data && unitPrices.data.length > 0) {
      sortUnitPrices();
    } else {
      setSortedUnitPrices([]);
    }
  }, [unitPrices]);

  useEffect(() => {
    onMarketChange({
      workType:current.workType,
      startAt:current.startAt,
      endAt:current.endAt
    });

    if (unitPrices && unitPrices.data.length > 0) {
      sortUnitPrices();
    }
  }, []);

  return (
    <>
      <Form actions={
        <>
          <Button 
            disabled={!onPrevious}
            clicked={onPrevious || undefined} 
            bgColor="bg-transparent" 
            textColor="text-gray-500" 
            border="border-gray-300">Précédent</Button>
          <Button 
            fontWeight="bold" 
            disabled={!validateStep('market', current)}
            clicked={confirmNext || undefined}>Suivant</Button>
        </>
      } loading={marketIsLoading}>
        <FormSection
          title="Marché" 
          subtitle="Cette étape permet de renseigner le type des travaux, ainsi que les dates d'execution.">
          <div className="sm:col-span-6">
            <Select 
              label="Type de travaux" 
              name="workType"
              options={workTypes} 
              displayedProperty="label"
              selected={current.workType}
              onSelection={(workType) => onMarketChange({workType})}
              placeholder="Sélectionnez un type de travaux"
              className="w-full"
              disabled={current.patrimony.program ? false : true}
              required />
          </div>
          <div className="sm:col-span-3">
            <DatePicker 
              label="Date de début d'éxécution"
              selected={current.startAt}
              maxDate={current.endAt}
              onDateChange={(date) => onMarketChange({startAt:date})}
              required />
          </div>
          <div className="sm:col-span-3">
            <DatePicker 
              label="Date de fin d'éxécution"
              selected={current.endAt}
              minDate={current.startAt}
              onDateChange={(date) => onMarketChange({endAt:date})}
              required />
          </div>
          { sortedUnitPrices && current.workType &&
            <div className="sm:col-span-6">
              <Select 
                label="Fournisseur" 
                name="serviceProvider"
                options={sortedUnitPrices}
                disabled={sortedUnitPrices.length === 0}
                disabledMessage="Aucun fournisseur disponible pour le type de travaux sélectionné."
                displayedProperty="service_provider.label"
                formatDisplay={(item) => {
                  return <>{item.service_provider.label} - {item.label}</>
                }}
                onSelection={(unitPrice) => onMarketChange({unitPrice})}
                selected={current.unitPrice}
                placeholder="Sélectionnez un fournisseur"
                className="w-full" />
            </div>
          }
        </FormSection>
        <FormSection>
          <div className="col-span-6">
            <MarketDetails unitPrice={current.unitPrice} />
          </div>
        </FormSection>
      </Form>
    </>
  );
};

export default StepMarket;