import { useEffect, useState } from 'react';
import { 
  Page, 
  Section, 
  WorkOrderRecap, 
  Loader, 
  Button, 
  ButtonLink,
  WorkOrderFinalise,
  WorkOrderValidate,
  Alert,
  Modal,
  SlideOver,
} from '@components';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Paths } from '@/Router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler, faClipboardCheck, faComments, 
  faCommentCheck, faCalendarDay, faTrash, faCog, faThumbsUp,
  faEnvelope } from '@fortawesome/pro-duotone-svg-icons';
import { getWorkOrder, setWorkOrder, deleteWorkOrder } from '@store/workOrder/actions';
import { 
  selectSingle, 
  selectError, 
  isLoading, 
  selectSingleDetailsData,
  selectWorkOrderState,
  selectCurrentIsReady,
  selectDeleted,
  selectCurrentValidation,
  selectCurrent,
} from '@store/workOrder/selector';
import { 
  getMarket
} from '@store/market/actions';
import { 
  sendConsent,
  resetConsent,
} from '@store/workOrder/consent/actions';
import { 
  isLoading as isConsentLoading,
  selectConsent,
  selectError as selectConsentError,
} from '@store/workOrder/consent/selector';
import ReactJson from 'react-json-view';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

const WorkOrderSingle = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const loading = useSelector(isLoading, shallowEqual);
  const error = useSelector(selectError);
  const { data: workOrderData, tasks, market } = useSelector(selectSingle);
  const workOrderState = useSelector(selectWorkOrderState);
  const ready = useSelector(selectCurrentIsReady);
  const deleted = useSelector(selectDeleted);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const validation = useSelector(selectCurrentValidation);
  const consentLoading = useSelector(isConsentLoading);
  const consentResult = useSelector(selectConsent);
  const consentError = useSelector(selectConsentError);
  const { patrimony } = useSelector(selectCurrent);
  const [hasValidEmail, setHasValidEmail] = useState(false);

  const goToUpdate = () => {
    navigate(Paths.WORK_ORDER_UPDATE.replace(':uid', uid));
  };

  const goToFinalise = () => {
    setShowModal(true);
  };

  const goToComment = () => {
    navigate(Paths.WORK_ORDER_STATUS.replace(':uid', uid));
  };

  const getWorkOrderData = () => {
    dispatch(getWorkOrder({ uid, include: [
      'modules','programs','buildings','staircases','floors',
      'details', 'intervention',
      'market',
      'status',
      'validation', 'consent',
      'unit_price',
      'status', 'full_contract',
    ] }));
  };

  const onValidate = () => {
    setShowActionsMenu(false);
  };

  const onDeleteWorkOrder = () => {
    dispatch(deleteWorkOrder({uid}));
  };


  const onSendConsent = () => {
    dispatch(sendConsent({uid}));
  };

  const userCan = (key) => {
    if (workOrderData.userAvailableActions && workOrderData.userAvailableActions.length > 0) {
      return workOrderData.userAvailableActions.filter(i => i === key).length > 0;
    }
    return true;
  };

  const onFinalise = (finaliseResult) => {
    setShowActionsMenu(false);
    getWorkOrderData();
  };

  useEffect(() => {
    if (deleted) {
      navigate(Paths.WORK_ORDER_LIST);
    }
  }, [deleted]);

  useEffect(() => {
    if (error && error.response) {
      // navigate(`${Paths.ERROR.replace(':status', error.response.status)}?ressource_id=${uid}&ressource_type=work_order`);
    }
  }, [error]);

  useEffect(() => {
    if (workOrderData) {
      dispatch(setWorkOrder({ workOrder: workOrderData }));
      if (workOrderData.full_contract && workOrderData.full_contract.tenants && workOrderData.full_contract.tenants) {
        workOrderData.full_contract.tenants.forEach(contractTenant => {
          if (contractTenant.tenant.email !== null) {
            console.log(contractTenant.tenant.email)
            setHasValidEmail(true);
          }
        });
      }
    }
  }, [workOrderData]);

  useEffect(() => {
    getWorkOrderData();
  }, []);

  useEffect(() => {
    if (loading || !ready) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [loading, ready]);

  useEffect(() => {
    if (consentResult) {
      if (consentResult.email_error) {
        toast.error('Une erreur s\'est produite : l\'envoi du mail à échoué.');
      } else {
        toast.success(`Un email a été envoyé à ${consentResult.email_sent_to}.`);
        setShowConsentModal(false);
        setShowActionsMenu(false);
      }
      dispatch(resetConsent());
    }
  }, [consentResult]);

  return (
    <Page title="Détails du bon de travaux" breadcrumbs={[
        {
          name: 'Liste des bons de travaux',
          href: Paths.WORK_ORDER_LIST
        }
      ]}>
      {error && error.response && error.response.data &&
        <Alert text={error.response.data.message} />
      }

      <div className="relative">
        <Loader show={showLoader} /> 
        {workOrderData && ready &&
          <>
            <Section className="relative">
              {workOrderData && 
                <div className="relative">
                  <Button clicked={() => setShowActionsMenu(true)} 
                    bgColor="bg-transparent" noShadow 
                    className="absolute right-0"
                    disabled={showLoader}>
                    <FontAwesomeIcon icon={faCog} aria-hidden="true" className="text-gray-500 mr-4 flex-shrink-0 group-hover:text-primary text-2xl mr-auto"/>
                  </Button>
                  <WorkOrderRecap 
                    workOrder={workOrderData} 
                    // moduleData={moduleData}
                    showCurrentContract={false}
                    market={market}
                    tasks={workOrderData.details}
                    // onLoadingStart={() => setLoading(true)}
                    // onLoadingEnd={() => setLoading(false)}
                  />
                </div>}
              {error ?
                <>Certaines informations n'ont pas pu être chargées.</>
              : ''}
            </Section>
            <SlideOver show={showActionsMenu} onClose={() => setShowActionsMenu(false)}>
              <ol>
                {userCan('update') && !workOrderData.is_sync && <li>
                  <Button 
                    clicked={goToUpdate} 
                    bgColor="bg-transparent" 
                    textColor="text-primary-darker" 
                    fontWeight="font-bold"
                    border="border-0"
                    className="flex items-center gap-2 py-4"
                    fullwidth>
                    Modifier
                    <FontAwesomeIcon icon={faPencilRuler} aria-hidden="true" className="text-gray-500 mr-4 flex-shrink-0 group-hover:text-primary text-lg"/>
                  </Button>
                </li>}
                {userCan('finalise') && !workOrderData.ended_at && <li>
                  <WorkOrderFinalise 
                    workOrder={workOrderData}
                    onFinalise={onFinalise}
                    modalZIndex="z-20"
                    triggerElement={
                      <Button 
                        bgColor="bg-transparent" 
                        textColor="text-primary-darker" 
                        className="flex items-center gap-2 py-4"
                        fullwidth
                        fontWeight="font-bold">
                        Finaliser
                        <FontAwesomeIcon icon={faCommentCheck} aria-hidden="true" className="text-gray-500 mr-4 flex-shrink-0 group-hover:text-primary text-lg"/>
                      </Button>
                    }
                  />
                </li>}
                {userCan('validate') && !validation.is_valid && <li>
                  <WorkOrderValidate
                    workOrder={workOrderData}
                    onValidate={onValidate}
                    modalZIndex="z-20"
                    triggerElement={
                      <Button 
                        bgColor="bg-transparent" 
                        textColor="text-primary-darker" 
                        className="flex items-center gap-2 py-4"
                        fullwidth
                        fontWeight="font-bold">
                        Valider
                        <FontAwesomeIcon icon={faThumbsUp} aria-hidden="true" className="text-gray-500 mr-4 flex-shrink-0 group-hover:text-primary text-lg"/>
                      </Button>
                    }
                  />
                </li>}
                <li>
                  <Button 
                    clicked={goToComment} 
                    bgColor="bg-transparent" 
                    textColor="text-primary-darker" 
                    className="flex items-center gap-2 py-4"
                    fullwidth
                    fontWeight="font-bold">
                      Commenter
                      <FontAwesomeIcon icon={faComments} aria-hidden="true" className="text-gray-500 mr-4 flex-shrink-0 group-hover:text-primary text-lg"/>
                    </Button>
                </li>
                <li>
                  {workOrderData.intervention && 
                    <ButtonLink 
                      to={Paths.PUBLIC_INTERVENTION
                        .replace(':workOrderUid',workOrderData.uid)
                        .replace(':interventionUid',workOrderData.intervention.uid)}
                      bgColor="bg-transparent"
                      textColor="text-primary-darker"
                      className="flex items-center gap-2 py-4"
                      fullwidth
                      fontWeight="font-bold">
                      Intervention
                      <FontAwesomeIcon icon={faCalendarDay} aria-hidden="true" className="text-gray-500 mr-4 flex-shrink-0 group-hover:text-primary text-lg"/>
                    </ButtonLink>} 
                </li>
                {userCan('delete') && !workOrderData.is_sync && <li>
                  {workOrderData.is_sync && <Button clicked={() => setShowDeleteModal(true)}
                    bgColor="bg-transparent"
                    textColor="text-red-700"
                    className="flex items-center gap-2 py-4"
                    fullwidth
                    fontWeight="font-bold">
                    Supprimer
                    <FontAwesomeIcon icon={faTrash} aria-hidden="true" className="text-red-500 mr-4 flex-shrink-0 group-hover:text-red-500 text-lg"/>
                  </Button>}
                </li>}
                {userCan('askConsent') &&
                  patrimony && patrimony.moduleType && 
                  patrimony.moduleType.type === 'rented' &&
                  workOrderData.consent &&
                  !workOrderData.consent.consented_on 
                  && !workOrderData.consent.refused_on && <li>
                  <Button clicked={() => setShowConsentModal(true)}
                    bgColor="bg-transparent"
                    textColor="text-primary-darker"
                    className="flex items-center gap-2 py-4"
                    fullwidth
                    fontWeight="font-bold"
                    disabled={!hasValidEmail}>
                    Consentement
                    <FontAwesomeIcon icon={faEnvelope} aria-hidden="true" className="text-gray-500 mr-4 flex-shrink-0 group-hover:text-primary text-lg"/>
                  </Button>
                </li>}
              </ol>
            </SlideOver>
            
            {/*<Section className="mt-6 p-4 break-words overflow-scroll">
              <ReactJson id="json-pretty" src={workOrderData} collapsed={true} name="Bon de travaux"/>
            </Section>*/}
          </>
        }
      </div>
      <Modal 
        zIndex="z-20"
        show={showDeleteModal} 
        title="Supprimer le bon de travaux"
        onClose={() => setShowDeleteModal(false)}>
        <div className="flex flex-col mb-6">
          <div>Êtes-vous sûr de vouloir supprimer ce bon de travaux?</div>
          <div>Cette action est définitive.</div>
        </div>

        <div className="flex flex-row justify-between">
          <Button clicked={onDeleteWorkOrder} bgColor="bg-red-700">Supprimer définitivement</Button>
          <Button bgColor="bg-transparent" textColor="text-primary" border="border-primary">Annuler</Button>
        </div>
      </Modal>
      <Modal 
        zIndex="z-20"
        show={showConsentModal} 
        title="Demander le consentement locataire"
        onClose={() => setShowConsentModal(false)}>
        <Loader show={consentLoading}/>
        <div className="flex flex-col mb-6">
          <div>Êtes-vous sûr de vouloir envoyer une demande de consentement consernant le forfait social au locataire signataire?</div>
          {consentError && <Alert text={consentError.response && consentError.response.data ? consentError.response.data.message : 'Une erreur est survenue.'} />}
        </div>

        <div className="flex flex-row justify-between">
          <Button clicked={onSendConsent}>Envoyer une demande</Button>
          <Button 
            clicked={() => setShowConsentModal(false)}
            bgColor="bg-transparent" textColor="text-primary" border="border-primary">Annuler</Button>
        </div>

      </Modal>
    </Page>
  );
};

export default WorkOrderSingle;
