import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Page, Form, FormSection, Select, Textarea, Button, Section, Loader } from '@components';
import { Paths } from '@/Router';
import { useParams, useNavigate } from 'react-router-dom';
import { getWorkOrderStatus, createWorkOrderStatus } from '@store/workOrder/status/actions';
import { getPublicWorkOrder } from '@store/workOrder/actions';
import { selectList, isLoading as statusIsLoading } from '@store/workOrder/status/selector';
import { selectPublic, isLoading as workOrderIsLoading } from '@store/workOrder/selector';
import { useForm } from "react-hook-form";
import { fromStringToDatetime } from '@services/utils.service';
import { displayUid } from '../../../utils';

const WorkOrderStatus = ({ noLayout, isPublic }) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { uid } = useParams();
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState({
    key: 'start',
    type: 'Début',
  });
  const [comments, setComments] = useState('');
  const workOrderStatus = useSelector(selectList);
  const workOrder = useSelector(selectPublic);
  const statusLoading = useSelector(statusIsLoading);
  const workOrderLoading = useSelector(workOrderIsLoading);
  const [status, setStatus] = useState();

  const allStatus = [
    {
      key: 'start',
      type: 'Début',
    },
    {
      key: 'doing',
      type: 'En cours',
    },
    {
      key: 'end',
      type: 'Fin',
    }
  ];

  const sendStatus = (event) => {
    dispatch(createWorkOrderStatus({
      uid,
      comments,
      status: selectedStatus.key,
      isPublic,
    }));
  };

  const getStatusLabel = (statusKey) => {
    var found = allStatus.filter((item) => statusKey === item.key);
    return found && found.length > 0 ? found[0].type : statusKey;
  };

  const filterStatusList = () => {
    if (workOrderStatus.filter((item) => item.status === 'end').length) {
      setStatus([
        allStatus[2]
      ]);
      setSelectedStatus(allStatus[2]);
    } else if (workOrderStatus.filter((item) => item.status === 'doing').length) {
      setStatus([
        allStatus[1],
        allStatus[2]
      ]);
      setSelectedStatus(allStatus[1]);
    } else {
      setStatus(allStatus);
      setSelectedStatus(allStatus[0]);
    }
  };

  useEffect(() => {
    if (workOrderStatus && workOrderStatus.length) {
      filterStatusList();
    } else {
      setStatus(allStatus);
      setSelectedStatus(allStatus[0]);
    }
  }, [workOrderStatus]);

  useEffect(() => {
    dispatch(getWorkOrderStatus({ uid }));
    dispatch(getPublicWorkOrder({ uid }));
  }, [uid]);

  return (
    <>
      <Loader show={statusLoading || workOrderLoading} />
      {workOrder &&
        <Page title={`Statut du bon de travaux n°${workOrder.code}`} 
        breadcrumbs={!noLayout && [
          {
            name: 'Liste des bons de travaux',
            href: Paths.WORK_ORDER_LIST
          },
          {
            name: `Détails du bon de travaux`,
            href: Paths.WORK_ORDER_SINGLE.replace(':uid', uid)
          },
        ]} withLayout={!noLayout}>
          <div className="relative">
            {workOrderStatus && workOrderStatus.length > 0 && 
              <Section className="p-6 mb-4">
                <h3 className="text-lg font-medium">Historique</h3>
                <div className="divide-y">
                  {workOrderStatus.map((workOrderStat, key) => (
                    <div key={key} 
                      className="py-4 lg:grid lg:grid-cols-12 lg:gap-x-8">
                      <div className="lg:col-start-5 lg:col-span-8 xl:col-start-4 xl:col-span-9 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:items-start">
                        <div className="mt-4 lg:mt-6 xl:mt-0 xl:col-span-2">
                          <div
                            className="space-y-6 text-sm text-gray-500"
                            dangerouslySetInnerHTML={{ __html: workOrderStat.comments }}
                          />
                        </div>
                      </div>

                      <div className="mt-6 flex items-center text-sm lg:mt-0 lg:col-start-1 lg:col-span-4 lg:row-start-1 lg:flex-col lg:items-start xl:col-span-3">
                        <p className="font-medium text-gray-900">{getStatusLabel(workOrderStat.status)}</p>
                        {workOrderStat.user && 
                          <p className="font-medium text-gray-900">{workOrderStat.user.email}</p>
                        }
                        {workOrderStat.service_provider && 
                          <p className="font-medium text-gray-900">Fournisseur</p>
                        }
                        <time
                          dateTime={workOrderStat.created_at}
                          className="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0"
                        >
                          {fromStringToDatetime(workOrderStat.created_at)}
                        </time>
                      </div>
                    </div>
                  ))}
                </div>
              </Section>
            }

            <Form actions={<>
              <Button type="submit" 
                disabled={!selectedStatus || !comments}>Envoyer</Button>
            </>}
              onSubmit={handleSubmit(sendStatus)}
              contentPadding="p-6">
              <FormSection title="Publiez un nouveau commentaire pour ce bon de travaux">
                <div className="sm:col-span-6">
                  <div className="mb-4">
                    <Select 
                      options={status} 
                      displayedProperty="type" 
                      label="Statut"
                      required
                      selected={selectedStatus}
                      onSelection={setSelectedStatus} />
                  </div>
                  <div>
                    <Textarea
                      label="Commentaire" 
                      placeholder="Écrivez un commentaire concernant l'avancement du bon de travaux" 
                      rows={2}
                      required
                      onChange={(id, value) => setComments(value)}/>
                  </div>
                </div>
              </FormSection>
            </Form>
          </div>
        </Page>
      }
    </>
  );
};

export default WorkOrderStatus;