import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as api from './api';
import * as actions from './actions';
import * as constants from './constants';

export function* statusSaga() {
    yield takeLatest(constants.GET_WORK_ORDER_STATUS, getWorkOrderStatus);
    yield takeLatest(constants.CREATE_WORK_ORDER_STATUS, createWorkOrderStatus);
}

function* getWorkOrderStatus({ uid }) {
    try {
        const { data: payload } = yield api.getWorkOrderStatus({ uid });
        yield dispatch(actions.getWorkOrderStatusSuccess(payload));
    } catch (e) {
        yield dispatch(actions.getWorkOrderStatusFailure(e));
    }
}

function* createWorkOrderStatus({ uid, comments, status, isPublic }) {
    try {
        const { data: payload } = yield api.createWorkOrderStatus({ uid, comments, status, isPublic });
        yield dispatch(actions.createWorkOrderStatusSuccess(payload));
    } catch (e) {
        yield dispatch(actions.createWorkOrderStatusFailure(e));
    }
}
