export const GET_WORK_ORDERS = '[Work Order] Get List';
export const GET_WORK_ORDERS_SUCCESS = '[Work Order] Get List Success';
export const GET_WORK_ORDERS_FAILURE = '[Work Order] Get List Failure';

export const GET_WORK_ORDER = '[Work Order] Get Single';
export const GET_WORK_ORDER_SUCCESS = '[Work Order] Get Single Succes';
export const GET_WORK_ORDER_FAILURE = '[Work Order] Get Single Failure';

export const GET_WORK_ORDER_TASKS = '[Work Order] Get Tasks';
export const GET_WORK_ORDER_TASKS_SUCCESS = '[Work Order] Get Tasks Success';
export const GET_WORK_ORDER_TASKS_FAILURE = '[Work Order] Get Tasks Failure';

export const GET_WORK_ORDER_MARKET = '[Work Order] Get Market';
export const GET_WORK_ORDER_MARKET_SUCCESS = '[Work Order] Get Market Success';
export const GET_WORK_ORDER_MARKET_FAILURE = '[Work Order] Get Market Failure';

export const GET_WORK_ORDER_PATRIMONY = '[Work Order] Get Market';
export const GET_WORK_ORDER_PATRIMONY_SUCCESS = '[Work Order] Get Patrimony Success';
export const GET_WORK_ORDER_PATRIMONY_FAILURE = '[Work Order] Get Patrimony Failure';

export const SET_SETTINGS = '[Work Order] Set Settings';
export const SET_PATRIMONY = '[Work Order] Set Patrimony';
export const SET_MARKET = '[Work Order] Set Market';
export const SET_TASKS = '[Work Order] Set Tasks';
export const SET_CONSENT = '[Work Order] Set consent';
export const SET_WORK_ORDER = '[Work Order] Set work order';
export const SET_WORK_ORDER_SUCCESS = '[Work Order] Set work order success';
export const SET_TOTAL_COST = '[Work Order] SET_TOTAL_COST';
export const SET_VALIDATION = '[Work Order] SET_VALIDATION';
export const SET_READY = '[Work Order] SET_READY';
export const RESET_CURRENT = '[Work Order] Reset current work order';
export const RESET_SINGLE = '[Work Order] Reset single work order';

export const CREATE_WORK_ORDER = '[Work Order] Create';
export const CREATE_WORK_ORDER_SUCCESS = '[Work Order] Create Success';
export const CREATE_WORK_ORDER_FAILURE = '[Work Order] Create Failure';

export const CREATE_WORK_ORDER_TASK = '[Work Order] Create Task';
export const CREATE_WORK_ORDER_TASK_SUCCESS = '[Work Order] Create Task Success';
export const CREATE_WORK_ORDER_TASK_FAILURE = '[Work Order] Create Task Failure';

export const UPDATE_WORK_ORDER = '[Work Order] Update';
export const UPDATE_WORK_ORDER_SUCCESS = '[Work Order] Update Success';
export const UPDATE_WORK_ORDER_FAILURE = '[Work Order] Update Failure';

export const FINALISE_WORK_ORDER = '[Work Order] FINALISE_WORK_ORDER';
export const FINALISE_WORK_ORDER_SUCCESS = '[Work Order] FINALISE_WORK_ORDER_SUCCESS';
export const FINALISE_WORK_ORDER_FAILURE = '[Work Order] FINALISE_WORK_ORDER_FAILURE';

export const VALIDATE_WORK_ORDER = '[Work Order] VALIDATE_WORK_ORDER';
export const VALIDATE_WORK_ORDER_SUCCESS = '[Work Order] VALIDATE_WORK_ORDER_SUCCESS';
export const VALIDATE_WORK_ORDER_FAILURE = '[Work Order] VALIDATE_WORK_ORDER_FAILURE';

export const DELETE_WORK_ORDER = '[Work Order] DELETE_WORK_ORDER';
export const DELETE_WORK_ORDER_SUCCESS = '[Work Order] DELETE_WORK_ORDER_SUCCESS';
export const DELETE_WORK_ORDER_FAILURE = '[Work Order] DELETE_WORK_ORDER_FAILURE';

export const GET_PUBLIC_WORK_ORDER = '[Work Order] GET_PUBLIC_WORK_ORDER';
export const GET_PUBLIC_WORK_ORDER_SUCCESS = '[Work Order] GET_PUBLIC_WORK_ORDER_SUCCESS';
export const GET_PUBLIC_WORK_ORDER_FAILURE = '[Work Order] GET_PUBLIC_WORK_ORDER_FAILURE';
