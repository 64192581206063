import { TasksPriceTable, ModuleDetails, CommonDetails } from '@components';
import { fromStringToDate, toDateString } from '@services/utils.service';

const InterventionRecap = ({ workOrder }) => {
  return (
    <div>
      <div>
        <div className="w-full">
          <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
            <div className="p-2">Date d'éxécution estimée</div>
            <div className="p-2 text-right">du { fromStringToDate(workOrder.estimated_start) } au { fromStringToDate(workOrder.estimated_end) }</div>
          </div>
          {workOrder.started_at && <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
            <div className="p-2">Date d'éxécution réelle</div>
            <div className="p-2 text-right">du { fromStringToDate(workOrder.started_at) } au { fromStringToDate(workOrder.ended_at) }</div>
          </div>}
          <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
            <div className="p-2">Status</div>
            <div className="p-2 text-right">N/A</div>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <h2 className="text-lg font-bold text-primary-darker uppercase my-2 px-4 py-2">Patrimoine</h2>
        {workOrder.patrimony.module &&
          <>
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
              <div className="p-2">Type</div>
              <div className="p-2 text-right">Logement</div>
            </div>

            <ModuleDetails 
              {...workOrder.patrimony}
              moduleData={workOrder.patrimony.module}
              rowPadding="px-6"/>
          </>
        }

        {!workOrder.patrimony.module &&
          <>
            <div className="flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40 px-6 py-2">
              <div className="p-2">Type</div>
              <div className="p-2 text-right">Partie commune</div>
            </div>

            <CommonDetails 
              {...workOrder.patrimony}
              rowPadding="px-6" />
          </>
        }
      </div>

      <div>
        <h2 className="text-lg font-bold text-primary-darker uppercase my-2 px-4 py-2">Prestations</h2>
        <div className="px-4">
          <TasksPriceTable products={workOrder.details} />
        </div>
      </div>
    </div>
  );
};

export default InterventionRecap;
