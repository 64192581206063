import { useEffect, useState } from 'react';
import { Badge, Checkbox } from '@components';

const TasksPriceTable = ({ products, isBillable }) => {
  const [totalNotBillableHT, setTotalNotBillableHT] = useState(0);
  const [totalBillableHT, setTotalBillableHT] = useState(0);
  const [totalHT, setTotalHT] = useState(0);
  const [TVA, setTVA] = useState(0);
  const [forfaitSocial, setForfaitSocial] = useState(0);
  const [forfaitSocialTVA, setForfaitSocialTVA] = useState(0);
  const [consent, setConsent] = useState(false);

  const [billableProducts, setBillableProducts] = useState(false);
  const [notBillableProducts, setNotBillableProduct] = useState(false);

  useEffect(() => {
    let billableProducts = [];
    let notBillableProducts = [];

    let totalBillableTasks = 0;
    let totalNotBillableTasks = 0;
    let forfaitSocial = 0;

    let ttva = 0;
    let fstva = 0;

    if (products && products.length > 0) {
      products.forEach((item) => {
        if (item.price) {
          let totalLine = item.price.cost_excluding_tax * item.quantity;
          let billableTotalLine = 0;
          if (isBillable && item.price.is_billable == 1) {
            if (item.quantity == 0) {
              billableTotalLine = item.price.cost_excluding_tax * 1;
            } else billableTotalLine = totalLine;
            billableProducts.push(item);
            if (billableTotalLine >= 50) {
              forfaitSocial += (billableTotalLine / 2);
              fstva += forfaitSocial * (item.price.tva/100);
              if (item.quantity > 0) {
                totalBillableTasks += (billableTotalLine/2);
              } else {
                totalBillableTasks += (billableTotalLine/2);
              }
            } else {
              totalBillableTasks += billableTotalLine;
            }
          } else {
            notBillableProducts.push(item);
            totalNotBillableTasks += totalLine;
          }
          ttva += totalLine * (item.price.tva/100);
        }
      });
    }
    setBillableProducts(billableProducts);
    setNotBillableProduct(notBillableProducts);

    setForfaitSocial(forfaitSocial);
    setForfaitSocialTVA(fstva);
    setTotalBillableHT(totalBillableTasks);
    setTotalNotBillableHT(totalNotBillableTasks);
    setTotalHT(totalBillableTasks + totalNotBillableTasks);
    setTVA(ttva);

  }, [products, isBillable]);

  return (
    <div className="mt-10 lg:mt-0">
      <div className="mt-4 bg-white rounded-lg shadow-sm">
        {notBillableProducts && notBillableProducts.length > 0 &&
          <div className="border m-4 rounded-md">
            {isBillable && <h3 className="text-md text-center m-4 underline uppercase ">Non refacturables</h3>}
            <ul className="divide-y divide-gray-200">
              {notBillableProducts?.map((product, key) => {
                if (product.price) {
                  return (
                    <li key={key} className="flex py-6 px-4 sm:px-6 even:bg-white odd:bg-primary-clear odd:bg-opacity-40">
                      <div className="flex-1 flex flex-col">
                        <div className="flex">
                          <div className="min-w-0 flex-1">
                            <h4 className="text-md">
                              <span className="font-bold text-gray-700 hover:text-gray-800 flex flex-row gap-2">
                                {product && product.price && product.price.label.trim()} x {product.quantity}<br/>
                                {product.billable && <Badge text="R" type="warning" />}
                              </span>
                            </h4>
                            <p className="text-sm text-gray-500">
                              Type : {product && product.price && product.price.task_code || 'N/A'}<br/>
                              Prix unitaire : {product && product.price && product.price.cost_excluding_tax} € /  {product.price.unit}<br/>
                              TVA : {product && product.price && product.price.tva} % 
                            </p>
                          </div>
                        </div>

                        <div className="flex-1 mt-2 flex items-end justify-between font-bold">
                          <p className="mt-1 text-sm text-gray-900">
                            Total
                          </p>
                          <div className="ml-4 text-sm">
                            {(product.price.cost_excluding_tax * product.quantity).toFixed(2)} €
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                }
              })}
            </ul>
          </div>
        }
        {billableProducts && billableProducts.length > 0 && 
          <div className="border m-4 rounded-md">
            <h3 className="text-md text-center m-4 underline uppercase">Refacturables</h3>
            <ul className="divide-y divide-gray-200">
              {billableProducts?.map((product, key) => {
                if (product.price) {
                  let totalWithForfait = product.price.cost_excluding_tax * product.quantity;
                  let forfaitSocial = (totalWithForfait >= 50) ? ((totalWithForfait) / 2) : 0;
                  let totalWithoutForfait = totalWithForfait - forfaitSocial;

                  return (
                    <li key={key} className="flex py-6 px-4 sm:px-6 even:bg-white odd:bg-primary-clear odd:bg-opacity-40">
                      <div className="flex-1 flex flex-col">
                        <div className="flex">
                          <div className="min-w-0 flex-1">
                            <h4 className="text-md">
                              <span className="font-bold text-gray-700 hover:text-gray-800 flex flex-row gap-2">
                                {product && product.price && product.price.label.trim()} x {product.quantity}<br/>
                                {product.billable && <Badge text="R" type="warning" />}
                              </span>
                            </h4>
                            <p className="text-sm text-gray-500">
                              Type : {product && product.price && product.price.task_code || 'N/A'}<br/>
                              Prix unitaire : {product && product.price && product.price.cost_excluding_tax} € /  {product.price.unit}<br/>
                              TVA : {product && product.price && product.price.tva} %
                            </p>
                          </div>
                        </div>

                        <div className="flex-1 flex items-center justify-between text-sm text-gray-500 bg-gray-500 bg-opacity-10">
                          <p className="text-sm font-medium">
                            Total sans forfait social
                          </p>
                          <div className="ml-4">
                            {((product.price.cost_excluding_tax * product.quantity) - forfaitSocial).toFixed(2)} €
                          </div>
                        </div>

                        <div className="flex-1 flex items-center justify-between text-sm text-primary bg-primary bg-opacity-20">
                          <p className="text-sm font-medium">
                            Forfait social
                          </p>
                          <div className="ml-4">
                            {forfaitSocial.toFixed(2)} €
                          </div>
                        </div>

                        <div className="flex-1 mt-2 flex items-end justify-between font-bold">
                          <p className="mt-1 text-sm text-gray-900">
                            Total avec forfait social
                          </p>
                          <div className="ml-4 text-sm">
                            {(product.price.cost_excluding_tax * product.quantity).toFixed(2)} €
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                }
              })}
              {/*<li className="flex py-6 px-4 sm:px-6 even:bg-white odd:bg-primary-clear odd:bg-opacity-40">
                <div className="flex-1 flex flex-col border-t-4 border-double">
                  <div className="flex-1 flex items-center justify-between text-gray-500 bg-opacity-10">
                    <p className="text-sm font-medium">
                      Total sans forfait social
                    </p>
                    <div className="ml-4">
                      {(totalBillableHT - forfaitSocial).toFixed(2)} €
                    </div>
                  </div>
                  <div className="flex-1 flex items-center justify-between text-gray-500 bg-opacity-20">
                    <p className="text-sm font-medium">
                      Montant du forfait social hors-taxes
                    </p>
                    <div className="ml-4">
                      {forfaitSocial.toFixed(2)} €
                    </div>
                  </div>
                  <div className="flex-1 flex items-center justify-between text-primary bg-opacity-20">
                    <p className="text-sm font-medium">
                      Montant du forfait social
                    </p>
                    <div className="ml-4">
                      {(forfaitSocial + forfaitSocialTVA).toFixed(2)} €
                    </div>
                  </div>
                  <div className="flex-1 mt-2 flex items-end justify-between font-bold">
                    <p className="mt-1 text-sm text-gray-900">
                      Total avec forfait social
                    </p>
                    <div className="ml-4">
                      {totalBillableHT.toFixed(2)} €
                    </div>
                  </div>
                </div>
              </li>*/}
            </ul>
          </div>
        }
        <dl className="border-t border-t-4 border-primary border-opacity-50 space-y-2">
          <div className="flex items-center justify-between border-t border-gray-200 px-4 pt-6">
            <dt className="text-sm">Sous-total hors-taxes</dt>
            <dd className="text-sm font-medium text-gray-900">{(totalHT - forfaitSocial).toFixed(2)} €</dd>
          </div>
          { totalBillableHT > 0 &&
            <>
              <div className="space-y-2">
                <div className="flex items-center justify-between px-4">
                  <dt className="text-sm">Montant du forfait social hors-taxes</dt>
                  <dd className="text-sm font-medium text-gray-900">{forfaitSocial.toFixed(2)} €</dd>
                </div>
              </div>
              <div className="space-y-2">
                <div className="flex items-center justify-between px-4 text-primary">
                  <dt className="text-sm">Montant du forfait social TTC</dt>
                  <dd className="text-sm font-medium">{(forfaitSocial + forfaitSocialTVA).toFixed(2)} €</dd>
                </div>
              </div>
            </>
          }
          <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-primary-darker px-4">
            <dt className="text-base font-bold">Total hors-taxes</dt>
            <dd className="text-base font-bold text-gray-900">{totalHT.toFixed(2)} €</dd>
          </div>
          <div className="flex items-center justify-between pb-6 text-primary-darker px-4">
            <dt className="text-base font-bold">Total TTC</dt>
            <dd className="text-base font-bold text-gray-900">{(totalHT + TVA).toFixed(2)} €</dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default TasksPriceTable;
