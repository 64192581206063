export const GET_CONSENT_BY_TOKEN = '[Consent] Get by token';
export const GET_CONSENT_BY_TOKEN_SUCCESS = '[Consent] Get by token success';
export const GET_CONSENT_BY_TOKEN_FAILURE = '[Consent] Get by token failure';

export const UPDATE_CONSENT = '[Consent] Update';
export const UPDATE_CONSENT_SUCCESS = '[Consent] Update success';
export const UPDATE_CONSENT_FAILURE = '[Consent] Update failure';

export const SEND_CONSENT = '[Consent] Send';
export const SEND_CONSENT_SUCCESS = '[Consent] Send success';
export const SEND_CONSENT_FAILURE = '[Consent] Send failure';

export const RESET_CONSENT = '[Consent] Reset consent';
