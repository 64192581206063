import * as constants from "./constants";

const initialState = {
  loading: false,
  user: null,
  workOrders: {
    list: {
      data: [],
      pagination: {
        page: 1,
      }
    },
    pending: {
      data: [],
      pagination: {
        page: 1,
      }
    },
    refused: {
      data: [],
      pagination: {
        page: 1,
      }
    }
  }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_USER_PROFILE:
        case constants.GET_USER_WORK_ORDERS:
        case constants.GET_USER_PENDING_WORK_ORDERS:
          return startRequest(state);
          break;
        case constants.GET_USER_PROFILE_SUCCESS:
          return onGetUserProfileSuccess(state, action)
          break;
        case constants.GET_USER_PROFILE_FAILURE:
          return onGetUserProfileFailure(state, action);
          break;
        case constants.GET_USER_WORK_ORDERS_SUCCESS:
          return onGetUserWorkOrdersSuccess(state, action)
          break;
        case constants.GET_USER_PENDING_WORK_ORDERS_SUCCESS:
          return onGetUserPendingWorkOrdersSuccess(state, action)
          break;
        case constants.GET_USER_WORK_ORDERS_FAILURE:
          return onGetUserWorkOrdersFailure(state, action);
          break;
        case constants.GET_USER_PENDING_WORK_ORDERS_FAILURE:
          return onGetUserPendingWorkOrdersFailure(state, action);
          break;
        case constants.RESET_USER:
          return onResetUser(state);
          break;
        case constants.SET_USER:
          return onSetUser(state, action);
          break;
        default:
          return state;
    }
};

const startRequest = (state) => ({ 
  ...state, 
  error: null, 
  loading: true,
});

const onGetUserProfileSuccess = (state, { user }) => ({ 
  ...state, 
  user, 
  loading: false, 
});

const onGetUserProfileFailure = (state, { error }) => ({ 
  ...state, 
  error, 
  loading: false, 
});

const onGetUserWorkOrdersSuccess = (state, { data, pagination }) => ({ 
  ...state, 
  workOrders: {
    ...state.workOrders,
    list: {
      data,
      pagination,
    }
  }, 
  loading: false, 
});

const onGetUserWorkOrdersFailure = (state, { error }) => ({ 
  ...state, 
  error, 
  loading: false, 
});

const onGetUserPendingWorkOrdersSuccess = (state, { data, pagination }) => ({ 
  ...state, 
  workOrders: {
    ...state.workOrders,
    pending: {
      data,
      pagination,
    }
  }, 
  loading: false, 
});

const onGetUserPendingWorkOrdersFailure = (state, { error }) => ({ 
  ...state, 
  error, 
  loading: false, 
});

const onResetUser = (state) => ({
  ...state,
  user: null,
});

const onSetUser = (state, { user }) => ({
  ...state,
  user,
});

export default reducer;