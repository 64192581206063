import { fromStringToDate } from '@services/utils.service';

const ModuleDetails = ({ 
  moduleData, 
  program, 
  building, 
  staircase, 
  floor, 
  agency,
  contract,
  keepers,
  rowPadding,
}) => {
  return (
    <>
      {moduleData && 
        <div className="w-full">
          <div className={`flex justify-between even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding:''}`}>
            <div className="p-2">Code</div>
            <div className="p-2 text-right">
              { moduleData.code }
            </div>
          </div>
          {program && <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding:''}`}>
            <div className="p-2">Programme</div>
            <div className="p-2 text-right">
              { program.label }<br/>
              {agency && agency.label}
            </div>
          </div>}
          <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding:''}`}>
            <div className="p-2">Localisation</div>
            <div className="p-2 text-right">
              {building && <>Bâtiment { building.label }<br/></>}
              {floor && <>Étage { floor.number }<br/></>}
              {staircase && <>
                { staircase.street_number } { staircase.street } <br/>
                { staircase.postal_code } { staircase.city }</>}
            </div>
          </div>
          <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding:''}`}>
            <div className="p-2">Caractéristiques</div>
            <div className="p-2 text-right">
              { moduleData.typology } | { moduleData.living_space }m2<br/>
              { moduleData.management_type }
            </div>
          </div>
          {contract &&
            <>
              {contract && contract.tenants && 
                <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding:''}`}>
                  <div className="p-2">Locataire signataire</div>
                  <div className="p-2 text-right">
                    { contract.tenants.map((tenantContract) => {
                      return <div key={tenantContract.uid}>
                        {tenantContract.tenant.lastname} {tenantContract.tenant.firstname} - {tenantContract.tenant.email || <strong className="text-red-500">Pas d'adresse email</strong>}
                      </div>;
                    })}
                  </div>
                </div>
              }
            </>
          }
          {keepers && keepers.length > 0 && 
            <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding:''}`}>
              <div className="p-2">Resid'Manager</div>
              <div className="p-2 text-right">
                {keepers.map(keeper => {
                  return <div key={keeper.uid}>
                    {keeper.staff.firstname.substring(0,1)}. {keeper.staff.lastname} - <a href={`mailto:${keeper.staff.email}`}>{keeper.staff.email}</a>
                  </div>;
                })}
              </div>
            </div>
          }
        </div>
      }
    </>
  );
};

export default ModuleDetails;
