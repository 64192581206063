import { takeLatest, put as dispatch } from 'redux-saga/effects';

import { setItem, removeItem, keys } from '@services/localStorage.service';
import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import { getUserProfile, resetUser, setUser } from '@store/user/actions';

export function* authSaga() {
    yield takeLatest(constants.LOGIN, login);
    yield takeLatest(constants.LOGOUT, logout);
    yield takeLatest(constants.SET_TOKEN, setToken);
    yield takeLatest(constants.CREATE_ACCOUNT, createAccount);
    yield takeLatest(constants.FORGOT_PASSWORD, forgotPassword);
    yield takeLatest(constants.RESET_PASSWORD, resetPassword);
}

function* login({ type, data }) {
    try {
        const { data: payload } = yield api.login(data);
        setItem(keys.TOKEN, payload.access_token);
        yield dispatch(setUser({ user: payload.user }));
        yield dispatch(actions.loginSuccess(payload));
    } catch (error) {
        yield dispatch(actions.loginFailure({ error }));
    }
}

function* logout({ type, data }) {
    removeItem(keys.TOKEN);
    dispatch(resetUser());
    yield;
}

function* setToken() {
    yield dispatch(getUserProfile());
}

function* createAccount({ email, password }) {
    try {
        const { data } = yield api.createAccount({ email, password });
        setItem(keys.TOKEN, data.access_token);
        yield dispatch(actions.createAccountSuccess(data));
    } catch (error) {
        yield dispatch(actions.createAccountFailure({ error }));
    }
}

function* forgotPassword({ email }) {
    try {
        const { data } = yield api.forgotPassword({ email });
        yield dispatch(actions.forgotPasswordSuccess({ data }));
    } catch (error) {
        yield dispatch(actions.forgotPasswordFailure({ error }));
    }
}

function* resetPassword({ email, password, password_confirmation, token }) {
    try {
        const { data } = yield api.resetPassword({ email, password, password_confirmation, token });
        yield dispatch(actions.resetPasswordSuccess({ data }));
    } catch (error) {
        yield dispatch(actions.resetPasswordFailure({ error }));
    }
}
