import { useEffect, useState } from 'react';
import { CheckIcon, ClipboardListIcon } from '@heroicons/react/solid';
import { SlideOver, Alert, } from '@components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/pro-duotone-svg-icons';

const Steps = ({ steps, onStepChange, currentStepKey }) => {
  const [openSlideOver, setOpenSlideOver] = useState(false);

  const stepClicked = (step) => {
    setOpenSlideOver(false);
    onStepChange(step);
  };

  const StepsRendered = () => {
    return (
      <nav aria-label="Progress">
        <ol className="rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
          {Object.keys(steps).map((key, index) => (
            <li key={steps[key].name} className="relative md:flex-1 md:flex">
              {steps[key].status === 'complete' ? (
                <button onClick={() => stepClicked(key)} type="button" className="group flex items-center w-full">
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary rounded-full group-hover:bg-indigo-800">
                      <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">{steps[key].name}</span>
                  </span>
                </button>
              ) : steps[key].status === 'active' ? (
                <button onClick={() => stepClicked(key)} type="button" className="text-primary px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary rounded-full">
                    <span>{index + 1}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium">{steps[key].name}</span>
                </button>
              ) : (
                <button disabled type="button" className="group flex items-center">
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">{index + 1}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{steps[key].name}</span>
                  </span>
                </button>
              )}

              {index !== Object.keys(steps).length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
    )
  };

  return (
    <>
      <div className="hidden md:block">
        <StepsRendered />
      </div>

      <div className="md:hidden">
        <button type="button" className="p-4 w-full flex flex-row items-center justify-between" onClick={() => setOpenSlideOver(true)}>
          <span>Étape {steps[currentStepKey].order} - {steps[currentStepKey].name}</span>
          <FontAwesomeIcon icon={faListAlt} className="text-3xl text-primary"/>
        </button>
        <SlideOver 
          show={openSlideOver} 
          onClose={() => setOpenSlideOver(false)}
          title="Cliquez sur une étape terminée pour naviguer">
          <StepsRendered />
        </SlideOver>
      </div>
    </>
  )
};

export default Steps;
