import * as constants from "./constants";

const initialState = {
  loading: false,
  error: null,
  list: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_WORK_TYPES:
          return onLoading(state);
          break;
        case constants.GET_WORK_TYPES_SUCCESS:
          return onGetWorkTypesSuccess(state, action);
          break;
        case constants.GET_WORK_TYPES_FAILURE:
          return onFailure(state, action);
          break;
        default:
          return state;
    }
};

const onLoading = (state) => ({ 
  ...state, 
  loading: true,
  error: null,
});

const onFailure = (state, { error }) => ({ 
  ...state, 
  loading: false,
  error: error,
  list: null,
});

const onGetWorkTypesSuccess = (state, { data }) => ({ 
  ...state, 
  loading: false,
  error: null,
  list: data,
});

export default reducer;