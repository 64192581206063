export const keys = {
    TOKEN: 'lrye_bt_token'
};

const isLocalStorageReady = () => {
    return window ? true : false;
};

export const setItem = (key, value) => {
    if (!isLocalStorageReady()) {
        throw 'localStorage is not ready.';
    } else {
        localStorage.setItem(key, JSON.stringify(value));
    }
};

export const getItem = (key) => {
    if (!isLocalStorageReady()) {
        throw 'localStorage is not ready.';
    } else {
        return JSON.parse(localStorage.getItem(key));
    }
};

export const removeItem = (key) => {
    if (!isLocalStorageReady()) {
        throw 'localStorage is not ready.';
    } else {
        return localStorage.removeItem(key);
    }
};
