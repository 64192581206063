import * as constants from "./constants";

export const getWorkOrderStatus = ({ uid }) => {
    return {
        type: constants.GET_WORK_ORDER_STATUS,
        uid
    };
};

export const getWorkOrderStatusSuccess = ({ data }) => {
    return {
        type: constants.GET_WORK_ORDER_STATUS_SUCCESS,
        data,
    };
};

export const getWorkOrderStatusFailure = (error) => {
    return {
        type: constants.GET_WORK_ORDER_STATUS_FAILURE,
        error,
    };
};

export const createWorkOrderStatus = ({ uid, comments, status, isPublic = false }) => {
    return {
        type: constants.CREATE_WORK_ORDER_STATUS,
        uid,
        comments,
        status,
        isPublic,
    };
};

export const createWorkOrderStatusSuccess = (data) => {
    return {
        type: constants.CREATE_WORK_ORDER_STATUS_SUCCESS,
        data,
    };
};

export const createWorkOrderStatusFailure = (error) => {
    return {
        type: constants.CREATE_WORK_ORDER_STATUS_FAILURE,
        error,
    };
};
