import React, { useEffect, useState } from 'react';
import { fromStringToDate } from '@services/utils.service';
import { getWorkType } from '@services/workOrder.service';

const MarketDetails = ({ unitPrice, rowPadding, ...props }) => {
  return (
    <>
      <div className="w-full">
        {unitPrice && 
          <>
            <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding : ''}`}>
              <div className="p-2">Bordereau de prix</div>
              <div className="p-2 text-right">
                { unitPrice.label }<br/>
                { unitPrice.type }
              </div>
            </div>

            {unitPrice.market &&
              <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding : ''}`}>
                <div className="p-2">Marché</div>
                <div className="p-2 text-right">
                  du {fromStringToDate(unitPrice.market.lot_start_date)} au {fromStringToDate(unitPrice.market.lot_end_date)}<br/>
                  {unitPrice.market.work_type && <>{unitPrice.market.work_type.label}<br/></>}
                  {unitPrice.market.label}<br/>
                  {unitPrice.market.software_key}<br/>
                </div>
              </div>
            }

            {unitPrice.service_provider &&
              <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding : ''}`}>
                <div className="p-2">Fournisseur</div>
                <div className="p-2 text-right">
                  {unitPrice.service_provider.label} - {unitPrice.service_provider.siret}<br/>
                  {unitPrice.service_provider.main_address && <div>{unitPrice.service_provider.main_address}</div>}
                  {unitPrice.service_provider.second_address && <div>{unitPrice.service_provider.second_address}</div>}
                  <div>{unitPrice.service_provider.postal_code} {unitPrice.service_provider.city}</div>
                </div>
              </div>
            }
          </>
        }
    {/*    {(building || floor || staircase) && 
          <div className={`flex justify-between items-center even:bg-white odd:bg-primary-clear odd:bg-opacity-40${rowPadding?' '+rowPadding :''}`}>
            <div className="p-2">Localisation</div>
            <div className="p-2 text-right">
              {building && <>Bâtiment { building.label }<br/></>}
              {floor && <>Étage { floor.number }<br/></>}
              {staircase && <>
                { staircase.street_number } { staircase.street } <br/>
                { staircase.postal_code } { staircase.city }</>}
            </div>
          </div>
        }*/}
      </div>
    </>
  );
};

export default MarketDetails;
