import { takeLatest, put as dispatch, select } from 'redux-saga/effects';

import * as api from './api';
import * as actions from './actions';
import * as constants from './constants';
import { selectSingle } from './selector';

export function* interventionSaga() {
    yield takeLatest(constants.GET_INTERVENTION, getIntervention);
    yield takeLatest(constants.SAVE_INTERVENTION, saveIntervention);
}

function* getIntervention({ workOrderUid, interventionUid }) {
    try {
        const { data } = yield api.getIntervention({ workOrderUid, interventionUid });
        yield dispatch(actions.getInterventionSuccess({ data }));
    } catch (error) {
        yield dispatch(actions.getInterventionFailure({ error }));
    }
}

function* saveIntervention({ workOrderUid, interventionUid }) {
    try {
        const intervention = yield select(selectSingle);
        const { data } = yield api.updateIntervention({ workOrderUid, interventionUid, intervention });
        yield dispatch(actions.saveInterventionSuccess({ data }));
    } catch (error) {
        yield dispatch(actions.saveInterventionFailure({ error }));
    }
}
