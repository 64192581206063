export const GET_PROGRAMS = '[Patrimony] Load Programs';
export const GET_PROGRAMS_SUCCESS = '[Patrimony] Load Programs Success';
export const GET_PROGRAMS_FAILURE = '[Patrimony] Load Programs Failure';

export const GET_PROGRAM = '[Patrimony] Load Program';
export const GET_PROGRAM_SUCCESS = '[Patrimony] Load Program Success';
export const GET_PROGRAM_FAILURE = '[Patrimony] Load Program Failure';

export const GET_STAIRCASES = '[Patrimony] Load Staircases';
export const GET_STAIRCASES_SUCCESS = '[Patrimony] Load Staircases Success';
export const GET_STAIRCASES_FAILURE = '[Patrimony] Load Staircases Failure';

export const GET_STAIRCASE = '[Patrimony] Load Staircase';
export const GET_STAIRCASE_SUCCESS = '[Patrimony] Load Staircase Success';
export const GET_STAIRCASE_FAILURE = '[Patrimony] Load Staircase Failure';

export const GET_FLOORS = '[Patrimony] Load Floors';
export const GET_FLOORS_SUCCESS = '[Patrimony] Load Floors Success';
export const GET_FLOORS_FAILURE = '[Patrimony] Load Floors Failure';

export const GET_FLOOR = '[Patrimony] Load Floor';
export const GET_FLOOR_SUCCESS = '[Patrimony] Load Floor Success';
export const GET_FLOOR_FAILURE = '[Patrimony] Load Floor Failure';

export const GET_BUILDINGS = '[Patrimony] Load Buildings';
export const GET_BUILDINGS_SUCCESS = '[Patrimony] Load Buildings Success';
export const GET_BUILDINGS_FAILURE = '[Patrimony] Load Buildings Failure';

export const GET_BUILDING = '[Patrimony] Load Building';
export const GET_BUILDING_SUCCESS = '[Patrimony] Load Building Success';
export const GET_BUILDING_FAILURE = '[Patrimony] Load Building Failure';

export const GET_MODULES = '[Patrimony] Load Modules';
export const GET_MODULES_SUCCESS = '[Patrimony] Load Modules Success';
export const GET_MODULES_FAILURE = '[Patrimony] Load Modules Failure';

export const GET_MODULE = '[Patrimony] Load Module';
export const GET_MODULE_SUCCESS = '[Patrimony] Load Module Success';
export const GET_MODULE_FAILURE = '[Patrimony] Load Module Failure';
export const REMOVE_MODULE = '[Patrimony] Remove module';

export const SET_SELECTED_PATRIMONY = '[Patrimony] Set selected patrimony';
export const REMOVE_SELECTED_KEYS = '[Patrimony] Remove selected keys';

export const GET_MODULE_CONTRACT = '[Patrimony] GET_MODULE_CONTRACT';
export const GET_MODULE_CONTRACT_SUCCESS = '[Patrimony] GET_MODULE_CONTRACT_SUCCESS';
export const GET_MODULE_CONTRACT_FAILURE = '[Patrimony] GET_MODULE_CONTRACT_FAILURE';

export const RESET_PATRIMONY = '[Patrimony] RESET_PATRIMONY';
