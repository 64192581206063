const Button = ({ 
    bgColor = 'bg-primary', 
    textColor = 'text-white',
    fontWeight = 'font-normal',
    border = 'border-0',
    clicked, 
    fullwidth = false, 
    type = 'button', 
    disabled = false,
    noShadow,
    className,
    children, 
    ...props 
}) => {
    let baseClasses = 'flex justify-center py-2 px-4 border border-transparent rounded-md transition-all text-sm hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary';
    baseClasses += ` ${bgColor} ${fontWeight} ${textColor} ${border}`;
    baseClasses += className ? ` ${className}` : '';
    baseClasses += fullwidth ? ` w-full` : '';
    baseClasses += disabled ? ' cursor-not-allowed opacity-50': '';
    baseClasses += noShadow ? '':' shadow-sm';

    return <button 
        type={type} 
        className={baseClasses} 
        onClick={clicked} 
        disabled={disabled} 
        {...props}>
        {children}
    </button>;
};

export default Button;
