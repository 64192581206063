import api from '../../Api';

export function login(data) {
    return api.post('/auth/login', data);
}

export function createAccount({ email, password }) {
    return api.post('/auth/register', { email, password });
}

export function forgotPassword({ email }) {
    return api.post('/auth/forgot-password', { email });
}

export function resetPassword({ email, password, password_confirmation, token }) {
    return api.post('/auth/reset-password', { email, password, password_confirmation, token });
}
