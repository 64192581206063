import * as constants from "./constants";

const initialState = {
  loading: false,
  error: null,
  consent: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_CONSENT_BY_TOKEN:
        case constants.UPDATE_CONSENT:
        case constants.SEND_CONSENT:
          return onLoading(state, action);
        case constants.GET_CONSENT_BY_TOKEN_SUCCESS:
          return onGetConsentSuccess(state, action);
        case constants.UPDATE_CONSENT_SUCCESS:
          return onUpdateConsentSuccess(state, action);
        case constants.SEND_CONSENT_SUCCESS:
          return onSendConsentSuccess(state, action);
        case constants.GET_CONSENT_BY_TOKEN_FAILURE:
        case constants.UPDATE_CONSENT_FAILURE:
        case constants.SEND_CONSENT_FAILURE:
          return onFailure(state, action);
       case constants.RESET_CONSENT:
         return onReset(state);
        default:
          return state;
    }
};

const onLoading = (state) => ({ 
  ...state, 
  loading: true,
  error: null,
});

const onGetConsentSuccess = (state, { data }) => ({
  ...state,
  loading: false,
  error: null,
  consent: data,
});


const onUpdateConsentSuccess = (state, { data }) => ({
  ...state,
  loading: false,
  error: null,
  consent: data,
});

const onSendConsentSuccess = (state, { data }) => ({
  ...state,
  loading: false,
  error: null,
  consent: data,
});

const onFailure = (state, { error }) => ({
  ...state,
  loading: false,
  error,
  consent: null,
});

const onReset = (state) => ({
  ...state,
  loading: false,
  error: null,
  consent: null,
});

export default reducer;