import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { forwardRef } from 'react';

const DatePicker = forwardRef(({ 
  label, 
  selected, 
  placeholder, 
  disabled, 
  onDateChange = null,
  isClearable,
  closeOnScroll,
  showTimeSelect,
  minDate,
  maxDate,
  errors,
  control,
  name,
  rules,
  required = false,
  ...props // See https://reactdatepicker.com/ for more props
}, ref) => {
  return (
    <>
      {label && <label className="font-bold block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>}
      <div className="mt-1">
      {control ?
        <>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <ReactDatePicker 
                className={`shadow-sm block sm:text-sm rounded-md${errors ? ' border border-red-500 hover:border-red-500':' border-gray-300'}`}
                ref={ref}
                locale="fr"
                selected={selected} 
                disabled={disabled}
                closeOnScroll={closeOnScroll}
                dateFormat={showTimeSelect ? "dd/MM/yyyy H:mm":"dd/MM/yyyy"}
                isClearable={isClearable}
                placeholderText={placeholder} 
                minDate={minDate}
                maxDate={maxDate}
                showTimeSelect={showTimeSelect}
                {...field}
                {...props} /> 
            )}
          />
          {errors && <div className="text-red-500">{errors.message}</div>}  
        </> :
          <ReactDatePicker 
            className={`shadow-sm block sm:text-sm rounded-md border-gray-300`}
            locale="fr"
            name={name}
            selected={selected} 
            onChange={onDateChange}
            disabled={disabled}
            closeOnScroll={closeOnScroll}
            dateFormat={showTimeSelect ? "dd/MM/yyyy H:mm":"dd/MM/yyyy"}
            isClearable={isClearable}
            placeholderText={placeholder} 
            minDate={minDate}
            maxDate={maxDate}
            showTimeSelect={showTimeSelect}
            {...props} />
        }
      </div>
    </>
  );
});

export default DatePicker;
