import * as constants from "./constants";

export const getWorkOrders = (params) => {
    return {
        type: constants.GET_WORK_ORDERS,
        params
    };
};

export const getWorkOrdersSuccess = ({ data, pagination }) => {
    return {
        type: constants.GET_WORK_ORDERS_SUCCESS,
        data,
        pagination,
    };
};

export const getWorkOrdersFailure = (error) => {
    return {
        type: constants.GET_WORK_ORDERS_FAILURE,
        error,
    };
};

export const getWorkOrder = ({ uid, include }) => {
    return {
        type: constants.GET_WORK_ORDER,
        uid,
        include
    };
};

export const getWorkOrderSuccess = (data) => {
    return {
        type: constants.GET_WORK_ORDER_SUCCESS,
        data,
    };
};

export const getWorkOrderFailure = (error) => {
    return {
        type: constants.GET_WORK_ORDER_FAILURE,
        error,
    };
};

export const getWorkOrderTasks = ({ uid }) => {
    return {
        type: constants.GET_WORK_ORDER_TASKS,
        uid
    };
};

export const getWorkOrderTasksSuccess = ({ data, pagination }) => {
    return {
        type: constants.GET_WORK_ORDER_TASKS_SUCCESS,
        data,
        pagination
    };
};

export const getWorkOrderTasksFailure = (error) => {
    return {
        type: constants.GET_WORK_ORDER_TASKS_FAILURE,
        error,
    };
};

export const getWorkOrderMarket = ({ uid }) => {
    return {
        type: constants.GET_WORK_ORDER_MARKET,
        uid,
    };
};

export const getWorkOrderMarketSuccess = (data) => {
    return {
        type: constants.GET_WORK_ORDER_MARKET_SUCCESS,
        data,
    };
};

export const getWorkOrderMarketFailure = (error) => {
    return {
        type: constants.GET_WORK_ORDER_MARKET_FAILURE,
        error,
    };
};

export const getWorkOrderPatrimony = ({ uid }) => {
    return {
        type: constants.GET_WORK_ORDER_PATRIMONY,
        uid,
    };
};

export const getWorkOrderPatrimonySuccess = ({ moduleData, program, floor, staircase, building }) => {
    return {
        type: constants.GET_WORK_ORDER_PATRIMONY_SUCCESS,
        moduleData,
        program,
        floor,
        staircase,
        building
    };
};

export const getWorkOrderPatrimonyFailure = (error) => {
    return {
        type: constants.GET_WORK_ORDER_PATRIMONY_FAILURE,
        error,
    };
};

export const setSettings = ({ description, workable }) => {
    return {
        type: constants.SET_SETTINGS,
        description,
        workable,
    };
};

export const setMarket = ({ workType, unitPrice, market, startAt, endAt }) => {
    return {
        type: constants.SET_MARKET,
        workType,
        unitPrice,
        market,
        startAt,
        endAt,
    };
};

export const setTasks = ({ tasks }) => {
    return {
        type: constants.SET_TASKS,
        tasks,
    };
};

export const createWorkOrder = () => {
    return {
        type: constants.CREATE_WORK_ORDER
    };
};

export const createWorkOrderSuccess = (data) => {
    return {
        type: constants.CREATE_WORK_ORDER_SUCCESS,
        data,
    };
};

export const createWorkOrderFailure = (error) => {
    return {
        type: constants.CREATE_WORK_ORDER_FAILURE,
        error,
    };
};

export const createWorkOrderTask = ({ task }) => {
    return {
        type: constants.CREATE_WORK_ORDER_TASK,
        task,
    };
};

export const createWorkOrderTaskSuccess = (data) => {
    return {
        type: constants.CREATE_WORK_ORDER_TASK_SUCCESS,
        data,
    };
};

export const createWorkOrderTaskFailure = (error) => {
    return {
        type: constants.CREATE_WORK_ORDER_TASK_FAILURE,
        error,
    };
};

export const setPatrimony = ({ 
    moduleType, module, floor, staircase, building, program, contract, }) => {
    return {
        type: constants.SET_PATRIMONY,
        moduleType,
        module,
        floor,
        staircase,
        building,
        program,
        contract,
    };
};

export const setConsent = ({ consent }) => {
    return {
        type: constants.SET_CONSENT,
        consent,
    };
};

export const setWorkOrder = ({ workOrder, market }) => {
    return {
        type: constants.SET_WORK_ORDER,
        workOrder,
        market,
    };
};

export const setWorkOrderSuccess = () => {
    return {
        type: constants.SET_WORK_ORDER_SUCCESS,
    };
};

export const setTotalCost = ({ amount }) => {
    return {
        type: constants.SET_TOTAL_COST,
        amount
    };
};

export const resetCurrent = () => {
    return {
        type: constants.RESET_CURRENT,
    };
};

export const resetSingle = () => {
    return {
        type: constants.RESET_SINGLE
    }
};

export const updateWorkOrder = ({ uid }) => {
    return {
        type: constants.UPDATE_WORK_ORDER,
        uid
    };
};

export const updateWorkOrderSuccess = (data) => {
    return {
        type: constants.UPDATE_WORK_ORDER_SUCCESS,
        data,
    };
};

export const updateWorkOrderFailure = (error) => {
    return {
        type: constants.UPDATE_WORK_ORDER_FAILURE,
        error,
    };
};

export const finaliseWorkOrder = ({ uid, comments, startedAt, endedAt }) => {
    return {
        type: constants.FINALISE_WORK_ORDER,
        uid,
        comments,
        startedAt,
        endedAt,
    };
};

export const finaliseWorkOrderSuccess = (data) => {
    return {
        type: constants.FINALISE_WORK_ORDER_SUCCESS,
        data,
    };
};

export const finaliseWorkOrderFailure = (error) => {
    return {
        type: constants.FINALISE_WORK_ORDER_FAILURE,
        error,
    };
};

export const validateWorkOrder = ({ uid }) => {
    return {
        type: constants.VALIDATE_WORK_ORDER,
        uid,
    };
};

export const validateWorkOrderSuccess = (data) => {
    return {
        type: constants.VALIDATE_WORK_ORDER_SUCCESS,
        data,
    };
};

export const validateWorkOrderFailure = (error) => {
    return {
        type: constants.VALIDATE_WORK_ORDER_FAILURE,
        error,
    };
};

export const deleteWorkOrder = ({ uid }) => {
    return {
        type: constants.DELETE_WORK_ORDER,
        uid,
    };
};

export const deleteWorkOrderSuccess = () => {
    return {
        type: constants.DELETE_WORK_ORDER_SUCCESS,
    };
};

export const deleteWorkOrderFailure = (error) => {
    return {
        type: constants.DELETE_WORK_ORDER_FAILURE,
        error,
    };
};

export const setReady = () => {
    return {
        type: constants.SET_READY,
    };
};

export const setValidation = ({ validation }) => {
    return {
        type: constants.SET_VALIDATION,
        validation,
    };
};

export const getPublicWorkOrder = ({ uid }) => {
    return {
        type: constants.GET_PUBLIC_WORK_ORDER,
        uid,
    };
};

export const getPublicWorkOrderSuccess = (data) => {
    return {
        type: constants.GET_PUBLIC_WORK_ORDER_SUCCESS,
        data,
    };
};

export const getPublicWorkOrderFailure = (error) => {
    return {
        type: constants.GET_PUBLIC_WORK_ORDER_FAILURE,
        error,
    };
};
