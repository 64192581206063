import { truncate } from '@services/utils.service';
import { Loader } from '@components';

const Table = ({ columns, items, actions, totalItems, itemsPerPage, clicked,
loading = false }) => {
  const formatCell = (item, column) => {
    let formattedValue = column.format ? column.format(item) : item[column.key];
    if (column.maxLength) {
      formattedValue = truncate(formattedValue, column.maxLength, true);
    }
    return formattedValue;
  };

  const clickOnRow = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    clicked(item);
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg relative">
            <Loader show={loading} />
            <table className="min-w-full divide-y divide-gray-200 text-center">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {column.title}
                      </th>
                    );
                  })}
                  {actions && 
                  <th scope="col" className="relative px-6 py-3">

                  </th>}
                </tr>
              </thead>
              {items && items.length > 0 ? 
                <tbody className="bg-white divide-y divide-gray-200">
                  {items.map((item, index) => (
                    <tr key={index} onClick={(e) => clickOnRow(e, item)} className={clicked ? 'cursor-pointer hover:bg-gray-50':''}>
                      {columns.map((column, idx) => {
                        return <td className="px-6 py-4 whitespace-nowrap" key={idx}>
                          <div className="text-sm text-gray-900">{
                            formatCell(item, column)
                          }
                          </div>
                        </td>
                      })}
                      {actions && 
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {actions.map((action, index) => 
                            <button type="button" 
                              className={`text-4xl mr-2 text-gray-400 disabled:hover:text-gray-400 disabled:cursor-not-allowed ${action.className}`} 
                              disabled={action.disable(item)}
                              key={index}
                              onClick={(e) => action.callback(e, item)}>{action.label}</button>)}
                      </td>}
                    </tr>
                  ))}
                </tbody> :
                <tbody>
                  <tr className="text-center py-4 font-medium">
                    <td colSpan={columns.length + 1} className="py-4">Aucun résultat</td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
