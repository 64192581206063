import { useEffect, useCallback, useState } from 'react';
import { Combobox, Select, Loader } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { 
  getPrograms,
  getProgram,
  getBuilding,
  getStaircase,
  // getFloor,
  removeSelectedKeys,
} from '@store/patrimony/actions';
import { 
  isLoading, 
  selectError ,
  selectPrograms,
  selectedPatrimony
} from '@store/patrimony/selector';
import { 
  selectCurrent
} from '@store/workOrder/selector';

const SearchCommon = ({
  onSelection,
  onWorkableChange,
  onPatrimonyChange,
}) => {
  const dispatch = useDispatch();
  const programs = useSelector(selectPrograms);
  const loading = useSelector(isLoading);
  const error = useSelector(selectError);
  const { 
    program,building,staircase
  } = useSelector(selectedPatrimony);
  const { 
    patrimony: currentPatrimony
  } = useSelector(selectCurrent);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [filteredBuildings, setFilteredBuildings] = useState([]);

  useEffect(() => {
    dispatch(getPrograms({ size: 1000 }));
    if (currentPatrimony.program) {
      changeProgram(currentPatrimony.program);
    }
    if (currentPatrimony.building) {
      changeBuilding(currentPatrimony.building);
    }
    if (currentPatrimony.staircase) {
      changeStaircase(currentPatrimony.staircase);
    }
  }, []);

  const changeProgram = (item) => {
    dispatch(getProgram({uid: item.uid, include: ['buildings']}));
    onWorkableChange('program');
    dispatch(removeSelectedKeys(['building','staircase','floor']));
    onPatrimonyChange({building:null,staircase:null});
  };

  const changeBuilding = (item) => {
    dispatch(getBuilding({uid: item.uid, include: ['staircases']}));
    onWorkableChange('building');
    dispatch(removeSelectedKeys(['staircase','floor']));
    onPatrimonyChange({staircase:null});
  };

  const changeStaircase = (item) => {
    dispatch(getStaircase({uid: item.uid, include: ['floors']}));
    onWorkableChange('staircase');
  };

  const filterPrograms = (value) => {
    let search = value.trim().toLowerCase();
    setFilteredPrograms(programs.data.filter(item => {
      return (item.code && item.code.toLowerCase().indexOf(search) > -1)
    }));
  };
  const filterBuildings = (value) => {
    let search = value.trim().toLowerCase();
    setFilteredBuildings(program.buildings.filter(item => {
      return ((item.code && item.code.toLowerCase().indexOf(search) > -1) || item.label.toLowerCase().indexOf(search) > -1)
    }));
  };

  const debounceFilterPrograms = useCallback(debounce(filterPrograms, 500, true),
    [programs.data]
  );
  const debounceFilterBuildings = useCallback(debounce(filterBuildings, 500, true),
    [program?.buildings]
  );

  useEffect(() => {
    if (programs.data && programs.data.length) {
      setFilteredPrograms(programs.data);
    }
  }, [programs.data]);

  useEffect(() => {
    if (program && program.buildings && program.buildings.length) {
      setFilteredBuildings(program.buildings);
    }
  }, [program]);

  return (
    <div className="relative">
      <Loader show={loading} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        {programs && <div>
          <Combobox 
            label="Programme"
            items={filteredPrograms}
            formatDisplay={(item) => `${item.code} - ${item.label}`}
            selectedItem={program}
            displayKey="label"
            onSelection={changeProgram}
            onSearch={debounceFilterPrograms}
            placeholder="Cherchez un programme par code"
            required />
        </div>}
        {program && <div>
          <Combobox 
            label="Bâtiment"
            items={filteredBuildings}
            formatDisplay={(item) => `${item.label}${item.code ? '('+item.code+')':''}`}
            selectedItem={building}
            displayKey="label"
            onSelection={changeBuilding}
            onSearch={debounceFilterBuildings}
            placeholder="Cherchez un bâtiment par code"
            required />
        </div>}
        {building && <div>
          <Select 
            label="Escalier"
            options={building.staircases}
            selected={staircase}
            displayedProperty="street"
            onSelection={changeStaircase}
            required />
        </div>}
      </div>
    </div>
  );
};

export default SearchCommon;
