import { useEffect } from 'react';
import { Page, Table, WorkOrderBadges, Button } from '@components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getUserWorkOrders, getUserPendingWorkOrders } from '@store/user/actions';
import { selectUser, selectWorkOrders, selectPendingWorkOrders, selectIsLoading } from '@store/user/selector';
import { fromStringToDate } from '@services/utils.service';
import { getInitialWorkable }  from '@services/workOrder.service';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@/Router';

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const workOrdersList = useSelector(selectWorkOrders, shallowEqual);
  const pendingWorkOrdersList = useSelector(selectPendingWorkOrders, shallowEqual);
  const itemsPerPage = 5;
  const navigate = useNavigate();
  const loading = useSelector(selectIsLoading, shallowEqual);

  useEffect(() => {
    dispatch(getUserWorkOrders({
      page:1,size:itemsPerPage,
    }));
    dispatch(getUserPendingWorkOrders({
      page:1,size:itemsPerPage,
    }));
  }, [user]);

  const columns = [
  {
    title: 'Code',
    key: 'code',
  },
  {
    title: 'Année',
    key: 'year',
  },{
    title: 'Type',
    format: (item) => {
      return (
        <span>{item.market?.work_type?.label || '-'}</span>
      );
    }
  },{
    title: 'Statut',
    format: (item) => {
      return (
        <div className="flex flex-row items-center justify-center">
          <WorkOrderBadges workOrder={item} />
        </div>
      );
    }
  },
  {
    title: 'Créé le',
    format: (item) => {
      return fromStringToDate(item.created_at);
    }
  }];

  const goToView = (item) => {
    navigate(Paths.WORK_ORDER_SINGLE.replace(':uid', item.uid));
  };

  return (
    <Page title="Tableau de bord">
      <div>
        <h2 className="font-medium">Mes derniers bons de travaux</h2>
        {workOrdersList.data && 
          <Table 
            columns={columns} 
            items={workOrdersList.data}
            itemsPerPage={itemsPerPage}
            clicked={(item) => goToView(item)}
            loading={loading} />
        }
      </div>
      <div className="my-4">
        <h2 className="font-medium">Mes bons de travaux en attente de validation</h2>
        {pendingWorkOrdersList.data && 
          <Table 
            columns={columns} 
            items={pendingWorkOrdersList.data}
            itemsPerPage={itemsPerPage}
            clicked={(item) => goToView(item)}
            loading={loading} />
        }
      </div>
    </Page>
  );
}

export default Dashboard;
