import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ModuleDetails, Loader, Alert } from '@components';
import { 
  getModule,
  getFloor,
  getStaircase,
  getBuilding,
  getProgram,
} from '@store/patrimony/actions';
import { 
  selectedPatrimony,
  isLoading,
} from '@store/patrimony/selector';
import { selectSingle, selectCurrent } from '@store/workOrder/selector';
import { fromStringToDate } from '@services/utils.service';

const ModuleLoader = ({ 
  module: uid, 
  rowPadding, 
  colPadding, 
  showCurrentContract,
  onLoaded = null 
}) => {
  const dispatch = useDispatch();

  const { module: moduleData,floor,staircase,building,program,
  } = useSelector(selectedPatrimony, shallowEqual);
  const current = useSelector(selectCurrent);
  const loading = useSelector(isLoading);

  useEffect(() => {
    if (uid && (!moduleData || (moduleData && moduleData.uid !== uid) || !moduleData.floor)) {
      dispatch(getModule({ uid, include: [
        'floor','staircase','building','program','current_contract'] }));
    }
  }, [uid]);

  useEffect(() => {
    if (moduleData) {
      onLoaded && onLoaded({
        moduleData,
        contract: moduleData.current_contract?.contract || null,
        program: moduleData.program,
        building: moduleData.building,
        staircase: moduleData.staircase,
        floor: moduleData.floor,
      });
    }
  }, [moduleData]);

  return (
    <div className="relative">
      <Loader loading={loading} />
      <div>
        {current && current.patrimony &&
          <ModuleDetails
            moduleData={moduleData}
            program={current.patrimony.program}
            building={current.patrimony.building}
            staircase={current.patrimony.staircase}
            floor={current.patrimony.floor}
            contract={showCurrentContract ? moduleData?.current_contract?.contract : current.patrimony.contract}
            keepers={moduleData ? moduleData.keepers : []}
            rowPadding={rowPadding} />
        }
      </div>
    </div>
  );
};

export default ModuleLoader;
