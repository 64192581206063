import * as constants from "./constants";

export const getPrograms = ({ page, size }) => {
  return {
    type: constants.GET_PROGRAMS,
    page,
    size,
  };
};

export const getProgramsSuccess = ({ data, pagination }) => {
  return {
    type: constants.GET_PROGRAMS_SUCCESS,
    data,
    pagination,
  };
};

export const getProgramsFailure = ({ error }) => {
  return {
    type: constants.GET_PROGRAMS_FAILURE,
    error,
  };
};

export const getProgram = ({ uid, include }) => {
  return {
    type: constants.GET_PROGRAM,
    uid,
    include
  };
};

export const getProgramSuccess = ({ program }) => {
  return {
    type: constants.GET_PROGRAM_SUCCESS,
    program,
  };
};

export const getProgramFailure = ({ error }) => {
  return {
    type: constants.GET_PROGRAM_FAILURE,
    error,
  };
};

export const getBuildings = ({ page, size }) => {
  return {
    type: constants.GET_BUILDINGS,
    page,
    size,
  };
};

export const getBuildingsSuccess = ({ data, pagination }) => {
  return {
    type: constants.GET_BUILDINGS_SUCCESS,
    data,
    pagination,
  };
};

export const getBuildingsFailure = ({ error }) => {
  return {
    type: constants.GET_BUILDINGS_FAILURE,
    error,
  };
};

export const getBuilding = ({ uid, include }) => {
  return {
    type: constants.GET_BUILDING,
    uid,
    include,
  };
};

export const getBuildingSuccess = ({ building }) => {
  return {
    type: constants.GET_BUILDING_SUCCESS,
    building,
  };
};

export const getBuildingFailure = ({ error }) => {
  return {
    type: constants.GET_BUILDING_FAILURE,
    error,
  };
};

export const getModules = ({ page, size, moduleType, search }) => {
  return {
    type: constants.GET_MODULES,
    page,
    size,
    moduleType,
    search
  };
};

export const getModulesSuccess = ({ data, pagination }) => {
  return {
    type: constants.GET_MODULES_SUCCESS,
    data,
    pagination,
  };
};

export const getModulesFailure = ({ error }) => {
  return {
    type: constants.GET_MODULES_FAILURE,
    error,
  };
};

export const removeModule = () => {
  return {
    type: constants.REMOVE_MODULE,
  };
};

export const getModule = ({ uid, include }) => {
  return {
    type: constants.GET_MODULE,
    uid,
    include,
  };
};

export const getModuleSuccess = ({ module }) => {
  return {
    type: constants.GET_MODULE_SUCCESS,
    module,
  };
};

export const getModuleFailure = ({ error }) => {
  return {
    type: constants.GET_MODULE_FAILURE,
    error,
  };
};

export const getStaircases = ({ page, size }) => {
  return {
    type: constants.GET_STAIRCASES,
    page,
    size,
  };
};

export const getStaircasesSuccess = ({ data, pagination }) => {
  return {
    type: constants.GET_STAIRCASES_SUCCESS,
    data,
    pagination,
  };
};

export const getStaircasesFailure = ({ error }) => {
  return {
    type: constants.GET_STAIRCASES_FAILURE,
    error,
  };
};

export const getStaircase = ({ uid, include }) => {
  return {
    type: constants.GET_STAIRCASE,
    uid,
    include,
  };
};

export const getStaircaseSuccess = ({ staircase }) => {
  return {
    type: constants.GET_STAIRCASE_SUCCESS,
    staircase,
  };
};

export const getStaircaseFailure = ({ error }) => {
  return {
    type: constants.GET_STAIRCASE_FAILURE,
    error,
  };
};

export const getFloors = ({ page, size }) => {
  return {
    type: constants.GET_FLOORS,
    page,
    size,
  };
};

export const getFloorsSuccess = ({ data, pagination }) => {
  return {
    type: constants.GET_FLOORS_SUCCESS,
    data,
    pagination,
  };
};

export const getFloorsFailure = ({ error }) => {
  return {
    type: constants.GET_FLOORS_FAILURE,
    error,
  };
};

export const getFloor = ({ uid, include }) => {
  return {
    type: constants.GET_FLOOR,
    uid,
    include,
  };
};

export const getFloorSuccess = ({ floor }) => {
  return {
    type: constants.GET_FLOOR_SUCCESS,
    floor,
  };
};

export const getFloorFailure = ({ error }) => {
  return {
    type: constants.GET_FLOOR_FAILURE,
    error,
  };
};

export const removeSelectedKeys = (keys) => {
  return {
    type: constants.REMOVE_SELECTED_KEYS,
    keys
  };
};

export const getModuleContract = ({ moduleUid, include }) => {
  return {
    type: constants.GET_MODULE_CONTRACT,
    moduleUid,
    include,
  };
};

export const getModuleContractSuccess = (data) => {
  return {
    type: constants.GET_MODULE_CONTRACT_SUCCESS,
    data,
  };
};

export const getModuleContractFailure = ({ error }) => {
  return {
    type: constants.GET_MODULE_CONTRACT_FAILURE,
    error,
  };
};

export const resetPatrimony = () => {
  return {
    type: constants.RESET_PATRIMONY,
  };
};
