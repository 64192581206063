import { useEffect, useState } from 'react';
import { PageHeader } from '@components';
import { MenuIcon } from '@heroicons/react/outline';
import { Helmet } from '@components';

const Page = ({ title, breadcrumbs, withLayout = true, hideHeader = false, children }) => {
  return (
    <main className={`${withLayout ? 'md:pl-64 ' : ''}flex flex-col flex-1`}>
      <Helmet title={title} />
      <div>
        {!hideHeader &&
          <div className={`px-4 sm:px-6 md:px-8 shadow-md py-4 bg-white`}>
            <PageHeader breadcrumbs={breadcrumbs} withLayout={withLayout} 
              className={withLayout ? '' : 'max-w-7xl mx-auto '}
              title={title} />
          </div>
        }
        <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
          {children}
        </div>
      </div>
    </main>
  );
};

export default Page;
