import { useEffect, createRef } from "react";

const Checkbox = ({ 
  label,
  id,
  subLabel,
  name,
  onCheck,
  onUncheck,
  defaultChecked,
  ...props
}) => {
  const checkbox = createRef();
  const toggle = (e) => {
    if (e.target.checked) {
      onCheck && onCheck(e.target.id);
    }
    if (!e.target.checked) {
      onUncheck && onUncheck(e.target.id);
    }
  }

  useEffect(() => {
    if (defaultChecked && !checkbox.current.checked) {
      checkbox.current.click();
    }
  }, [defaultChecked]);

  return (
    <>
      <div className={`flex items-center h-5${props.disabled ? ' opacity-60':''}`}>
        <input 
          type="checkbox" 
          name={name}
          id={id}
          className="focus:ring-indigo-500 h-4 w-4 text-primary border-gray-300 rounded" 
          onChange={toggle} 
          defaultChecked={defaultChecked}
          ref={checkbox}
          {...props}></input>
      </div>
      <div className={`ml-3 text-sm${props.disabled ? ' opacity-60':' cursor-pointer'}`}>
        <label htmlFor={id} className="font-medium text-gray-700">{label}</label>
        {subLabel && 
          <div className="text-gray-500">{subLabel}</div>
        }
      </div>
    </>

  );
};
  
export default Checkbox;
