import { Helmet as ReactHelmet, HelmetProvider } from 'react-helmet-async';

const Helmet = ({ title }) => {
  return (
    <HelmetProvider>
      <ReactHelmet
        titleTemplate="Les résidences - %s">
        <title>{title}</title>
      </ReactHelmet>
    </HelmetProvider>
  );
};

export default Helmet;
