export const GET_MARKETS = '[Market] Load Markets';
export const GET_MARKETS_SUCCESS = '[Market] Load Markets Success';
export const GET_MARKETS_FAILURE = '[Market] Load Markets Failure';

export const GET_MARKET = '[Market] Load Market';
export const GET_MARKET_SUCCESS = '[Market] Load Market Success';
export const GET_MARKET_FAILURE = '[Market] Load Market Failure';
export const RESET_MARKET = '[Market] RESET_MARKET';

export const GET_SERVICE_PROVIDERS = '[Market] Load Service Providers';
export const GET_SERVICE_PROVIDERS_SUCCESS = '[Market] Load Service Providers Success';
export const GET_SERVICE_PROVIDERS_FAILURE = '[Market] Load Service Providers Failure';

export const GET_UNIT_PRICES = '[Market] Load Unit Prices';
export const GET_UNIT_PRICES_SUCCESS = '[Market] Load Unit Prices Success';
export const GET_UNIT_PRICES_FAILURE = '[Market] Load Unit Prices Failure';

export const GET_UNIT_PRICE_ITEMS = '[Market] Load Unit Price Items';
export const GET_UNIT_PRICE_ITEMS_SUCCESS = '[Market] Load Unit Price Items Success';
export const GET_UNIT_PRICE_ITEMS_FAILURE = '[Market] Load Unit Price Items Failure';