const Loader = ({ show, className, bgColor = 'bg-white' }) => {
  return (
    <>
      { show && 
        <div className={`${className ? className : ''} ${bgColor} flex items-center justify-center absolute top-0 bottom-0 left-0 right-0 bg-opacity-50 z-[10]`}>
          <div className="loader"></div>
        </div>
      }
    </>
  );
};

export default Loader;
