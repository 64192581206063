export const GET_USER_PROFILE = '[USER] GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = '[USER] GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = '[USER] GET_USER_PROFILE_FAILURE';

export const GET_USER_WORK_ORDERS = '[USER] GET_USER_WORK_ORDERS';
export const GET_USER_WORK_ORDERS_SUCCESS = '[USER] GET_USER_WORK_ORDERS_SUCCESS';
export const GET_USER_WORK_ORDERS_FAILURE = '[USER] GET_USER_WORK_ORDERS_FAILURE';

export const GET_USER_PENDING_WORK_ORDERS = '[USER] GET_USER_PENDING_WORK_ORDERS';
export const GET_USER_PENDING_WORK_ORDERS_SUCCESS = '[USER] GET_USER_PENDING_WORK_ORDERS_SUCCESS';
export const GET_USER_PENDING_WORK_ORDERS_FAILURE = '[USER] GET_USER_PENDING_WORK_ORDERS_FAILURE';

export const RESET_USER = '[USER] RESET_USER';
export const SET_USER = '[USER] SET_USER';
