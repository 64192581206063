import { useState } from 'react';
import { Section, Button } from '@components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/pro-duotone-svg-icons';

const ErrorPanel = ({ status, ressourceType, ressourceID, ressourceAction }) => {
  const [ressourceTypes] = useState({
    'work_order': 'Bon de travaux'
  });
  const [errorMessages] = useState({
    404: "La ressource que vous cherchez n'existe pas ou a été supprimée.",
    403: "Vous n'avez pas la permission d'effectuer cette action."
  });

  return (<Section className="p-4 text-center leading-10">
    <FontAwesomeIcon icon={faBan} className="text-5xl text-red-500"/>
    <div className="text-xl font-bold uppercase">
      Erreur {status}
    </div>
    <div className="text-sm text-gray-500">
      {ressourceType && <div>{`Type de ressource : ${ressourceTypes[ressourceType]}`}</div>}
      {ressourceAction && <div>{ressourceAction ? `Type d'action : ${ressourceAction}`:''}</div>}
      {ressourceID && <div>{ressourceID ? `ID de la ressource : ${ressourceID}`:''}</div>}
    </div>
    <div className="text-md font-medium">
      {errorMessages[status]}<br/>
    </div>
  </Section>);
};

export default ErrorPanel;
