import { takeLatest, put as dispatch } from "@redux-saga/core/effects";
import {
  GET_MARKETS,
  GET_MARKET,
  GET_SERVICE_PROVIDERS,
  GET_UNIT_PRICES,
  GET_UNIT_PRICE_ITEMS,
} from "./constants";

import * as api from './api';
import * as marketActions from "./actions";

export function* marketSaga() {
  yield takeLatest(GET_MARKETS, getMarkets);
  yield takeLatest(GET_MARKET, getMarket);
  yield takeLatest(GET_SERVICE_PROVIDERS, getServiceProviders);
  yield takeLatest(GET_UNIT_PRICES, getUnitPrices);
  yield takeLatest(GET_UNIT_PRICE_ITEMS, getUnitPriceItems);
}

function* getMarkets({ page = 1, size = 1000, workType, program }) {
  try {
    const { data, pagination } = yield api.getMarkets({ page, size, workType, program });
    yield dispatch(marketActions.getMarketsSuccess(data));
  } catch (e) {
    yield dispatch(marketActions.getMarketsFailure(e));
  }
}

function* getMarket({ uid }) {
  try {
    const { data } = yield api.getMarket({ 
      uid,
      include: [
        'service_provider',
        'unit_prices',
        'work_type'
      ]
    });
    yield dispatch(marketActions.getMarketSuccess(data));
  } catch (e) {
    yield dispatch(marketActions.getMarketFailure(e));
  }
}

function* getServiceProviders({ page, size }) {
  try {
    const { data: payget } = yield api.getServiceProviders({ page, size });
    yield dispatch(marketActions.getServiceProvidersSuccess(payget));
  } catch (e) {
    yield dispatch(marketActions.getServiceProvidersFailure(e));
  }
}

function* getUnitPrices({ page, size, program, workType }) {
  try {
    const { data: payget } = yield api.getUnitPrices({ page, size, program, workType });
    yield dispatch(marketActions.getUnitPricesSuccess(payget));
  } catch (e) {
    yield dispatch(marketActions.getUnitPricesFailure(e));
  }
}

function* getUnitPriceItems({ page, size, unitPriceUid, moduleType, roomsNumber }) {
  try {
    const { data: payget } = yield api.getUnitPriceItems({ page, size, unitPriceUid, moduleType, roomsNumber });
    yield dispatch(marketActions.getUnitPriceItemsSuccess(payget));
  } catch (e) {
    yield dispatch(marketActions.getUnitPriceItemsFailure(e));
  }
}