import * as constants from './constants';

export const getUserProfile = () => {
    return {
        type: constants.GET_USER_PROFILE,
    };
};

export const getUserProfileSuccess = ({ user }) => {
    return {
        type: constants.GET_USER_PROFILE_SUCCESS,
        user
    };
};

export const getUserProfileFailure = ({ error }) => {
    return {
        type: constants.GET_USER_PROFILE_FAILURE,
        error
    };
};

export const getUserWorkOrders = ({ page, size, filters, sort }) => {
    return {
        type: constants.GET_USER_WORK_ORDERS,
        page,
        size,
        filters,
        sort,
    };
};

export const getUserWorkOrdersSuccess = ({ data, pagination }) => {
    return {
        type: constants.GET_USER_WORK_ORDERS_SUCCESS,
        data,
        pagination
    };
};

export const getUserWorkOrdersFailure = ({ error }) => {
    return {
        type: constants.GET_USER_WORK_ORDERS_FAILURE,
        error
    };
};

export const getUserPendingWorkOrders = ({ page, size, filters, sort }) => {
    return {
        type: constants.GET_USER_PENDING_WORK_ORDERS,
        page,
        size,
        filters,
        sort,
    };
};

export const getUserPendingWorkOrdersSuccess = ({ data, pagination }) => {
    return {
        type: constants.GET_USER_PENDING_WORK_ORDERS_SUCCESS,
        data,
        pagination
    };
};

export const getUserPendingWorkOrdersFailure = ({ error }) => {
    return {
        type: constants.GET_USER_PENDING_WORK_ORDERS_FAILURE,
        error
    };
};

export const resetUser = () => {
    return {
        type: constants.RESET_USER,
    };
};

export const setUser = ({ user }) => {
    return {
        type: constants.SET_USER,
        user,
    };
};
