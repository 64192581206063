import { useLocation, Link } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { Paths } from '@/Router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTable } from '@fortawesome/pro-duotone-svg-icons';

const Breadcrumb = ({ breadcrumbs, currentTitle }) => {
  const location = useLocation();

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link to={Paths.DASHBOARD} className="text-gray-400 hover:text-gray-500 cursor-pointer">
              <FontAwesomeIcon icon={faHome} aria-hidden="true" className="text-gray-500 mr-4 flex-shrink-0 group-hover:text-primary text-lg"/>
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {breadcrumbs && breadcrumbs.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
              <Link to={page.href}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
        <li>
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <span
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              {currentTitle}
            </span>
          </div>
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
