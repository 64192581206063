import {
  Form,
  FormSection,
  Textarea,
  Input,
  Checkbox,
  Badge,
  Alert,
  HelpBubble,
} from '@/components'

import { useEffect, useState } from "react";

const TaskCheckboxes = ({ 
  tasks,
  onToggle,
  onQuantityChange,
  onCommentChange,
  isToggleAllowed,
  disabled = false,
  displayBillable = false,
}) => {

  return (
    <>
      {disabled && <Alert type="warning" text="Vous avez atteint le nombre maximum de prestations autorisées pour ce fournisseur."/>}
      {tasks && tasks.map((option, index) => (
        <div key={index} className="py-4">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="relative flex items-start">
              <Checkbox 
                id={`task_${index}`} 
                label={<span 
                  className="flex flex-col md:flex-row items-start md:items-center gap-2 cursor-pointer">
                    {option.label.trim()} 
                    {option.price.is_billable > 0 && displayBillable && <Badge text="Refacturable"/>}
                </span>}
                subLabel={<ul className={`${option.checked ? 'text-black ':'text-gray-500 '}text-sm`}>
                  <li>type: {option.price.task_code || 'N/A'}</li>
                  <li>PU: {option.price.cost_excluding_tax} € / {option.price.unit}</li>
                </ul>}
                name={option.label}
                onClick={(e) => isToggleAllowed(e,option)}
                onCheck={onToggle}
                onUncheck={onToggle}
                defaultChecked={option.checked}
                disabled={(!option.checked && disabled) || option.disabled ? true : false}
              />
            </div>
            {option.isMandatory &&
              <div>
                <HelpBubble title="Cette prestation est obligatoire."/>
              </div>
            }
            {option.disabled && option.cantSelectBecause && option.cantSelectBecause.length > 0 && 
              <div>
                <HelpBubble title="Cette prestation est indisponible.">
                  Les prestations suivantes sont incompatibles avec cette prestation :
                  <ul>
                    {option.cantSelectBecause.map(item => (
                      <li key={item.price.uid}>- {item.label}</li>
                    ))}
                  </ul>
                </HelpBubble>
              </div>}
            {option.checked &&
              <div className="pl-6 flex items-center gap-3">
                <div className="sm:col-span-1 flex items-center gap-2 max-w-[150px]">
                  <Input
                    defaultValue={option.quantity}
                    label="Qté"
                    type="number"
                    id={`quantity_${index}`}
                    name={`quantity_${index}`}
                    onChange={onQuantityChange}
                    placeholder="Quantités"
                    min={0}
                    disabled={(option.price && option.price.is_quantity_locked)  || option.disabled ? true : false}
                  ></Input>
                </div>
              </div>
            }
          </div>
          {option.checked && 
            <div className="pl-6 pt-2">
              <Input
                type="text"
                id={`comments_${index}`}
                name={`comments_${index}`}
                defaultValue={option.comments}
                placeholder="Entrez ici vos observations concernant la prestation."
                onChange={onCommentChange}
              />
          </div>}
        </div>
      ))}
    </>
  );
};
  
export default TaskCheckboxes;
