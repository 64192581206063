export const getSmallMaintenanceWorkTypes = () => {
  return [
    {
      type: 'electricity',
      name: 'Électricité',
    },
    {
      type: 'masonry',
      name: 'Maçonnerie',
    },
    {
      type: 'plumbing',
      name: 'Plomberie',
    },
    {
      type: 'locksmithing',
      name: 'Serrurerie',
    },
    {
      type: 'cleaning',
      name: 'Curage',
    },
    {
      type: 'glassmaking',
      name: 'Vitrerie',
    },
    {
      type: 'woodworking',
      name: 'Menuiserie',
    },
    {
      type: 'diagnostic',
      name: 'Diagnostic amiante',
    },
    {
      type: 'surface',
      name: 'Revêtement',
    },
    {
      type: 'misc',
      name: 'Divers',
    },
  ];
};

export const getVacantWorkTypes = () => {
  return [
    {
      type: 'refurbishment',
      name: 'Remise en état des logements',
    }
  ];
};

export const getWorkType = (type) => {
  let found = getSmallMaintenanceWorkTypes().filter(item => item.type === type);
  if (!found) {
    let found = getVacantWorkTypes().filter(item => item.type === type);
  }
  return found ? found[0] : null;
};

export const prepareTasks = (tasks) => {
  tasks.map(task => {
    task.checked = true;
    return task;
  });
  return tasks;
};

export const getSelectedWorkable = ({ program, building, staircase, floor, module: moduleData }) => {
  if (program && !building) {
    return { key: 'programs', value: [program.uid] };
  } else if (program && building && !staircase) {
    return { key: 'buildings', value: [building.uid] };
  } else if (program && building && staircase && !floor) {
    return { key: 'staircases', value: [staircase.uid] };
  } else if (program && building && staircase && floor && !moduleData) {
    return { key: 'floors', value: [floor.uid] };
  } else if (program && building && staircase && floor && moduleData) {
    return { key: 'modules', value: [moduleData.uid] };
  }
  return false;
};

export const getInitialWorkable = ({ programs, buildings, staircases, floors, modules }) => {
  if (programs && programs.length) return 'program';
  if (buildings && buildings.length) return 'building';
  if (staircases && staircases.length) return 'staircase';
  if (floors && floors.length) return 'floor';
  if (modules && modules.length) return 'module';
  return false;
};
