
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Paths } from '@/Router';
import { selectAuthIsConnected } from '@store/auth/selector';
import { selectUser } from '@store/user/selector';

const PrivateRoute = ({ component: RouteComponent }) => {
  const user = useSelector(selectUser);
  const isConnected = useSelector(selectAuthIsConnected);

  if (isConnected) {
    return <RouteComponent />;
  }

  return <Navigate to={Paths.LOGIN} />
}

export default PrivateRoute;
