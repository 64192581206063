import axios from 'axios';
import store from '@/store';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 25000,
    headers: {
        Client: process.env.REACT_APP_API_CLIENT_PK
    },
});

instance.interceptors.request.use(
    (config) => {
        if (store.getState().auth.token) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${store.getState().auth.token}`,
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        if (response.data.error) {
            return Promise.reject(response);
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;