import { 
  Page, 
  Section,
  Button,
  ButtonLink,
} from '@components';
import { useSelector } from "react-redux";
import { Paths } from '@/Router';
import { useParams } from 'react-router-dom';
import { selectUser } from '@store/user/selector';
import { selectCurrentValidation } from "@store/workOrder/selector";

const WorkOrderConfirmation = (props) => {
  const { uid } = useParams();
  const validation = useSelector(selectCurrentValidation);
  const user = useSelector(selectUser);

  return (
    <Page title="Confirmation de sauvegarde">
        <Section className="p-4 text-center flex flex-col items-center justify-center">
          <h2 className="text-xl font-bold">Le bon de travaux a été sauvegardé.</h2>
          <h3 className="text-md font-bold">
            Un email de confirmation vous a été envoyé.
          </h3>
          {validation && !validation.is_valid &&
            <div className="text-red-500 font-bold">
              Ce bon est en attente de validation d'un de vos supérieur.
            </div>
          }
          <div className="my-2 flex gap-2">
            <ButtonLink 
              to={Paths.WORK_ORDER_SINGLE.replace(':uid', uid)}
              bgColor="bg-primary-darker" 
              fontWeight="font-bold">Voir</ButtonLink>
            <ButtonLink 
              to={Paths.WORK_ORDER_UPDATE.replace(':uid', uid)} 
              bgColor="bg-primary-lighter" 
              textColor="text-primary-darker" 
              fontWeight="font-bold">Modifier</ButtonLink>
          </div>
        </Section>
    </Page>
  );
}

export default WorkOrderConfirmation;
