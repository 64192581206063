const Alert = ({ type = 'error', text, className }) => {
    var baseClassName = '';
    if (type === 'error') {
        baseClassName += 'bg-red-100 bg-opacity-50 text-red-800';
    } else if (type === 'success') {
        baseClassName += 'bg-green-100 bg-opacity-50 text-green-800';
    } else {
        baseClassName += 'bg-red-100 bg-opacity-50 text-red-800';
    }

    return (
        <div className={`${baseClassName} rounded-md p-2 my-4 ${className}`}>
          {text}
        </div>
    );
};

export default Alert;
