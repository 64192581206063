import * as constants from "./constants";

export const updateIntervention = (data) => {
    return {
        type: constants.UPDATE_INTERVENTION,
        data,
    };
};

export const saveIntervention = ({ workOrderUid, interventionUid }) => {
    return {
        type: constants.SAVE_INTERVENTION,
        workOrderUid,
        interventionUid,
    };
};

export const saveInterventionSuccess = ({ data }) => {
    return {
        type: constants.SAVE_INTERVENTION_SUCCESS,
        data,
    };
};

export const saveInterventionFailure = ({ error }) => {
    return {
        type: constants.SAVE_INTERVENTION_FAILURE,
        error,
    };
};

export const getIntervention = ({ workOrderUid, interventionUid }) => {
    return {
        type: constants.GET_INTERVENTION,
        workOrderUid,
        interventionUid,
    };
};

export const getInterventionSuccess = ({ data }) => {
    return {
        type: constants.GET_INTERVENTION_SUCCESS,
        data,
    };
};

export const getInterventionFailure = ({ error }) => {
    return {
        type: constants.GET_INTERVENTION_FAILURE,
        error,
    };
};
