import api from '../../Api';

export function programs({ page = 1, size = 15 }) {
  return api.get(`/programs?page[number]=${page}&page[size]=${size}`);
}

export function program({ uid, include }) {
  return api.get(`/programs/${uid}${include ? `?include=${include.join(',')}` : ''}`);
}

export function buildings({ page, size = 15 }) {
  return api.get(`/buildings?page[number]=${page}&page[size]=${size}`);
}

export function building({ uid, include }) {
  return api.get(`/buildings/${uid}${include ? `?include=${include.join(',')}` : ''}`);
}

export function modules({ page, size = 15, moduleType = 'all', search = null }) {
  let url = `/modules/search?page[number]=${page}&page[size]=${size}&type=${moduleType}`;

  if (search) {
    url += `&search=${search}`;
  }

  return api.post(url);
}

export function module({ uid, include }) {
  return api.get(`/modules/${uid}?join_tenants${include ? `&include=${include.join(',')}` : ''}`);
}

export function staircases({ page, size = 15 }) {
  return api.get(`/staircases?page[number]=${page}&page[size]=${size}`);
}

export function staircase({ uid, include }) {
  return api.get(`/staircases/${uid}${include ? `?include=${include.join(',')}` : ''}`);
}

export function floors({ page, size = 15 }) {
  return api.get(`/floors?page[number]=${page}&page[size]=${size}`);
}

export function floor({ uid, include }) {
  return api.get(`/floors/${uid}${include ? `?include=${include.join(',')}` : ''}`);
}

export function currentContract({ moduleUid, include }) {
  return api.get(`/modules/${moduleUid}/contracts/current${include ? `?include=${include.join(',')}` : ''}`);
}
