import { addDays } from "date-fns";
import * as constants from "./constants";

const defaultCurrent = {
  description: null,
  patrimony: {
    moduleType: null,
    module: null,
    floor: null,
    staircase: null,
    building: null,
    program: null,
    contract: null,
  },
  workType: null,
  market: null,
  unitPrice: null,
  startAt: new Date((new Date()).valueOf() + 1000*3600*24),
  endAt: addDays(new Date(), 10),
  tasks: [],
  consent: null,
  workable: null,
  ready: false,
  total: 0,
  validation: null,
};

const initialState = {
  loading: false,
  error: null,
  current: defaultCurrent,
  single: {
    data: null,
    loading: false,
    patrimony: {
      loading: false
    },
    market: {
      loading: false
    },
    tasks: {
      loading: false,
      data: [],
      pagination: {
        page: 1,
        total: null,
        count: null,
        next: null,
        previous: null,
      },
      saved: false
    },
  },
  list: {
    data: [],
    pagination: {
      page: 1,
      total: null,
      count: null,
      next: null,
      previous: null,
    }
  },
  finalise: null,
  deleted: false,
  saved: false,
  public: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_WORK_ORDERS:
        case constants.CREATE_WORK_ORDER:
        case constants.UPDATE_WORK_ORDER:
        case constants.FINALISE_WORK_ORDER:
        case constants.DELETE_WORK_ORDER:
        case constants.VALIDATE_WORK_ORDER:
        case constants.GET_PUBLIC_WORK_ORDER:
          return onLoading(state, action);
        case constants.GET_WORK_ORDER:
          return onWorkOrderLoading(state, action);
        case constants.GET_WORK_ORDER_TASKS:
          return onWorkOrderTasksLoading(state, action);
        case constants.GET_WORK_ORDER_MARKET:
          return onWorkOrderMarketLoading(state, action);
        case constants.SET_WORK_ORDER:
          return onSetWorkOrderLoading(state, action);
        case constants.GET_WORK_ORDERS_FAILURE:
        case constants.GET_WORK_ORDER_MARKET_FAILURE:
        case constants.GET_WORK_ORDER_FAILURE:
        case constants.GET_WORK_ORDER_TASKS_FAILURE:
        case constants.FINALISE_WORK_ORDER_FAILURE:
        case constants.DELETE_WORK_ORDER_FAILURE:
        case constants.VALIDATE_WORK_ORDER_FAILURE:
          return onFailure(state, action);
        case constants.GET_WORK_ORDERS_SUCCESS:
          return getWorkOrdersSuccess(state, action);
        case constants.GET_WORK_ORDER_SUCCESS:
          return getWorkOrderSuccess(state, action);
        case constants.GET_PUBLIC_WORK_ORDER_SUCCESS:
          return getWorkOrderPublicSuccess(state, action);
        case constants.GET_WORK_ORDER_TASKS_SUCCESS:
          return getWorkOrderTasksSuccess(state, action);
        case constants.GET_WORK_ORDER_MARKET_SUCCESS:
          return getWorkOrderMarketSuccess(state, action);
        case constants.SET_SETTINGS:
          return onSetSettings(state, action);
        case constants.SET_MARKET:
          return onSetMarket(state, action);
        case constants.SET_TASKS:
          return onSetTasks(state, action);
        case constants.SET_PATRIMONY:
          return onSetPatrimony(state, action);
        case constants.SET_CONSENT:
          return onSetConsent(state, action);
        case constants.SET_TOTAL_COST:
          return onSetTotalCost(state, action);
        case constants.SET_VALIDATION:
          return onSetValidation(state, action);
        case constants.RESET_CURRENT:
          return onResetCurrent(state);
        case constants.RESET_SINGLE:
          return onResetSingle(state);
        case constants.CREATE_WORK_ORDER_SUCCESS:
          return onCreateWorkOrderSuccess(state, action);
        case constants.UPDATE_WORK_ORDER_SUCCESS:
          return onUpdateWorkOrderSuccess(state, action);
        case constants.CREATE_WORK_ORDER_TASK_SUCCESS:
          return onCreateWorkOrderTasksSuccess(state, action);
        case constants.CREATE_WORK_ORDER_FAILURE:
        case constants.UPDATE_WORK_ORDER_FAILURE:
        case constants.CREATE_WORK_ORDER_TASK_FAILURE:
          return onCreateWorkOrderFailure(state, action);
        case constants.FINALISE_WORK_ORDER_SUCCESS:
          return onFinaliseWorkOrderSuccess(state, action);
        case constants.SET_WORK_ORDER_SUCCESS:
          return onSetWorkOrderSuccess(state, action);
        case constants.DELETE_WORK_ORDER_SUCCESS:
          return onDeleteWorkOrderSuccess(state, action);
        case constants.VALIDATE_WORK_ORDER_SUCCESS:
          return onValidateWorkOrderSuccess(state, action);
        case constants.GET_PUBLIC_WORK_ORDER_FAILURE:
          return onGetPublicWorkOrderFailure(state, action);
        case constants.SET_READY:
          return onSetReady(state);
        default:
          return state;
    }
};

const onLoading = (state) => ({ 
  ...state, 
  loading: true,
  error: null,
  deleted: false,
});

const onWorkOrderLoading = (state) => ({
  ...state, 
  loading: true,
  ready: false,
  current: defaultCurrent,
  single: {
    ...state.single,
    loading: true,
    data: null
  },
  error: null
});


const onWorkOrderTasksLoading = (state) => ({ 
  ...state, 
  single: {
    ...state.single,
    tasks: {
      ...state.single.tasks,
      loading: true,
      data: null
    }
  },
  error: null
});

const onWorkOrderMarketLoading = (state) => ({ 
  ...state, 
  single: {
    ...state.single,
    market: {
      ...state.single.market,
      loading: true,
      data: null
    }
  },
  error: null
});

const onSetWorkOrderLoading = (state) => ({
  ...state,
  current: {
    ...state.current,
    ready: false,
  }
});

const onFailure = (state, { error }) => ({
  ...state, 
  loading: false,
  error
});

const getWorkOrdersSuccess = (state, { data, pagination }) => ({ 
  ...state, 
  list: {
    ...state.list,
    data,
    pagination,
  },
  loading: false,
  error: null
});

const getWorkOrderSuccess = (state, { data }) => ({
  ...state, 
  single: {
    ...state.single,
    loading: false,
    data,
  },
  error: null
});

const getWorkOrderPublicSuccess = (state, { data }) => ({
  ...state, 
  public: data,
  loading: false,
  error: null
});

const getWorkOrderTasksSuccess = (state, { data }) => ({ 
  ...state, 
  loading: false,
  single: {
    ...state.single,
    tasks: {
      ...state.single.tasks,
      loading: false,
      data,
    }
  },
  error: null
});

const getWorkOrderMarketSuccess = (state, { data }) => ({ 
  ...state, 
  loading: false,
  single: {
    ...state.single,
    market: {
      ...state.single.market,
      loading: false,
      data,
    }
  },
  error: null,
});

const onSetSettings = (state, { description, workable }) => ({
  ...state,
  current: {
    ...state.current,
    description,
    workable
  },
  saved: false,
});

const onSetMarket = (state, { workType, market, unitPrice, startAt, endAt }) => {
  if (state.current.unitPrice && unitPrice && 
    (state.current.unitPrice.uid !== unitPrice.uid)) {
    state.current.tasks = [];
  }
  return {
    ...state,
    current: {
      ...state.current,
      workType,
      unitPrice,
      market,
      startAt,
      endAt
    },
    saved: false,
  }
};

const onSetTasks = (state, { tasks }) => {
  return {
    ...state,
    current: {
      ...state.current,
      tasks,
    },
    saved: false,
  }
};

const onSetPatrimony = (state, { 
  moduleType = null, module = null, floor, staircase, building, program, contract = null }) => {
  if (state.current && state.current.program && program && (state.current.program.uid !== program.uid)) {
    state.current.unitPrice = null;
    state.current.tasks = [];
  }
  return {
    ...state,
    current: {
      ...state.current,
      patrimony: {
        ...state.current.patrimony,
        moduleType,
        module,
        floor,
        staircase,
        building,
        program, 
        contract, 
      }
    },
    saved: false,
  }
};

const onSetConsent = (state, { consent }) => ({
  ...state,
  current: {
    ...state.current,
    consent,
  },
  loading: false,
  saved: false,
});


const onResetCurrent = (state) => ({
  ...state,
  current: defaultCurrent,
  single: {
    ...state.single,
    data: null,
    tasks: {
      ...state.single.tasks,
      data: null,
      saved: false,
    },
    market: {
      loading: false
    },
    patrimony: {
      loading: false
    }
  },
  saved: false
});

const onResetSingle = (state) => ({
  ...state,
  single: {
    ...state.single,
    data: null,
    tasks: {
      ...state.single.tasks,
      data: null,
      saved: false,
    },
    market: {
      loading: false
    },
    patrimony: {
      loading: false
    }
  },
  saved: false
});

const onCreateWorkOrderSuccess = (state, { data }) => ({
  ...state, 
  loading: false,
  current: defaultCurrent,
  single: {
    ...state.single,
    loading: false,
    data
  },
  error: null,
  saved: true
});

const onUpdateWorkOrderSuccess = (state, { data }) => ({
  ...state, 
  loading: false,
  single: {
    ...state.single,
    loading: false,
    data
  },
  error: null,
  saved: true
});

const onCreateWorkOrderTasksSuccess = (state, { data }) => ({
  ...state, 
  loading: false,
  single: {
    ...state.single,
    loading: false,
    tasks: {
      ...state.single.tasks,
      data,
      saved: true
    }
  },
  error: null,
});

const onCreateWorkOrderFailure = (state, { error }) => ({
  ...state,
  loading: false,
  saved: false,
  error
});

const onFinaliseWorkOrderSuccess = (state, { data }) => ({
  ...state, 
  loading: false,
  finalise: data,
  error: null,
  saved: true
});

const onSetWorkOrder = (state, { data }) => ({
  ...state, 
  loading: false,
  finalise: [
    data,
    ...state.finalise,
  ],
  error: null,
  saved: true
});

const onSetWorkOrderSuccess = (state, { data }) => ({
  ...state, 
  current: {
    ...state.current,
    ready: true,
  }
});

const onDeleteWorkOrderSuccess = (state) => ({
  ...state, 
  loading: false,
  deleted: true,
});

const onValidateWorkOrderSuccess = (state, { data }) => ({
  ...state, 
  loading: false,
  current: {
    ...state.current, 
    validation: data.validation
  }
});

const onSetTotalCost = (state, { amount }) => ({
  ...state, 
  current: {
    ...state.current, 
    total: amount,
  }
});

const onSetReady = (state) => ({
  ...state, 
  current: {
    ...state.current, 
    ready: true,
  }
});

const onSetValidation = (state, { validation }) => ({
  ...state, 
  current: {
    ...state.current, 
    validation
  }
});

const onGetPublicWorkOrderFailure = (state, { error }) => ({
  ...state,
  loading: false,
  public: null,
  error,
});

export default reducer;