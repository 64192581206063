export const selectWorkOrderState = (state) => state.workOrder;
export const isLoading = (state) => selectWorkOrderState(state).loading;
export const selectError = (state) => selectWorkOrderState(state).error;
export const selectSingle = (state) => selectWorkOrderState(state).single;
export const selectList = (state) => selectWorkOrderState(state).list;
export const selectSaved = (state) => selectWorkOrderState(state).saved;
export const selectDeleted = (state) => selectWorkOrderState(state).deleted;

export const selectSingleTasks = (state) => selectSingle(state).tasks;
export const selectSingleData = (state) => selectSingle(state).data;
export const selectSingleMarketData = (state) => selectSingle(state).market;
export const selectSingleTasksData = (state) => selectSingleTasks(state).data;
export const selectSingleTasksPagination = (state) => selectSingleTasks(state).pagination;
export const selectSingleTasksSaved = (state) => selectWorkOrderState(state).single.tasks.saved;

export const selectListData = (state) => selectList(state).data;
export const selectListPagination = (state) => selectList(state).pagination;
export const selectListPaginationTotal = (state) => selectListPagination(state).total;
export const selectListPaginationCount = (state) => selectListPagination(state).count;
export const selectListPaginationNext = (state) => selectListPagination(state).next;
export const selectListPaginationPrevious = (state) => selectListPagination(state).previous;

export const selectCurrent = (state) => selectWorkOrderState(state).current;
export const selectCurrentModuleType = (state) => selectCurrent(state).patrimony.moduleType;
export const selectCurrentModule = (state) => selectCurrent(state).patrimony.module;
export const selectCurrentDescription = (state) => selectCurrent(state).description;
export const selectCurrentWorkType = (state) => selectCurrent(state).workType;
export const selectCurrentUnitPrice = (state) => selectCurrent(state).unitPrice;
export const selectCurrentStartAt = (state) => selectCurrent(state).startAt;
export const selectCurrentEndAt = (state) => selectCurrent(state).endAt;
export const selectCurrentTasks = (state) => selectCurrent(state).tasks;
export const selectCurrentLastSaved = (state) => selectCurrent(state).lastSaved;
export const selectCurrentIsReady = (state) => selectCurrent(state).ready;
export const selectCurrentPatrimony = (state) => selectCurrent(state).patrimony;
export const selectCurrentValidation = (state) => selectCurrent(state).validation;

export const selectFinalise = (state) => selectWorkOrderState(state).finalise;
export const selectPublic = (state) => selectWorkOrderState(state).public;