import * as constants from "./constants";
  
export const getMarkets = ({ page, size, workType, program }) => {
  return {
    type: constants.GET_MARKETS,
    page,
    size,
    workType,
    program
  };
};
  
export const getMarketsSuccess = ({ data, pagination }) => {
  return {
    type: constants.GET_MARKETS_SUCCESS,
    data,
    pagination,
  };
};

export const getMarketsFailure = ({ error }) => {
  return {
    type: constants.GET_MARKETS_FAILURE,
    error,
  };
};

export const getMarket = ({ uid }) => {
  return {
    type: constants.GET_MARKET,
    uid
  };
};
  
export const getMarketSuccess = ({ data, pagination }) => {
  return {
    type: constants.GET_MARKET_SUCCESS,
    data,
    pagination,
  };
};

export const getMarketFailure = ({ error }) => {
  return {
    type: constants.GET_MARKET_FAILURE,
    error,
  };
};

export const getServiceProviders = ({ page, size }) => {
  return {
    type: constants.GET_SERVICE_PROVIDERS,
    page,
    size,
  };
};
  
export const getServiceProvidersSuccess = ({ data, pagination }) => {
  return {
    type: constants.GET_SERVICE_PROVIDERS_SUCCESS,
    data,
    pagination,
  };
};

export const getServiceProvidersFailure = ({ error }) => {
  return {
    type: constants.GET_SERVICE_PROVIDERS_FAILURE,
    error,
  };
};

export const getUnitPrices = ({ page, size, program, workType }) => {
  return {
    type: constants.GET_UNIT_PRICES,
    page,
    size,
    program,
    workType
  };
};
  
export const getUnitPricesSuccess = ({ data, pagination }) => {
  return {
    type: constants.GET_UNIT_PRICES_SUCCESS,
    data,
    pagination,
  };
};

export const getUnitPricesFailure = ({ error }) => {
  return {
    type: constants.GET_UNIT_PRICES_FAILURE,
    error,
  };
};

export const getUnitPriceItems = ({ 
  page, size, unitPriceUid, moduleType, roomsNumber = null, 
}) => {
  return {
    type: constants.GET_UNIT_PRICE_ITEMS,
    page,
    size,
    unitPriceUid,
    moduleType,
    roomsNumber,
  };
};
  
export const getUnitPriceItemsSuccess = ({ data, pagination }) => {
  return {
    type: constants.GET_UNIT_PRICE_ITEMS_SUCCESS,
    data,
    pagination,
  };
};

export const getUnitPriceItemsFailure = ({ error }) => {
  return {
    type: constants.GET_UNIT_PRICE_ITEMS_FAILURE,
    error,
  };
};

export const resetMarket = () => {
  return {
    type: constants.RESET_MARKET,
  };
};
