import api from '../../../Api';

export function getConsentByToken({ 
    token }) {
    return api.get(`/work-orders/public/consent/${token}`);
}

export function updateConsent({ token, 
    consent }) {
    if (consent) {
        return api.put(`/work-orders/public/consent/${token}/give`);
    } else {
        return api.put(`/work-orders/public/consent/${token}/withdraw`);
    }
}

export function sendConsent({ 
    uid }) {
    return api.post(`/work-orders/${uid}/ask-consent`);
}
