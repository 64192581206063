import * as constants from "./constants";

const defaultListData = {
  data: [],
  pagination: {
    page: 1,
    total: null,
    count: null,
    next: null,
    previous: null,
  }
};

const initialState = {
  isLoading: false,
  error: null,
  single: null,
  markets: defaultListData,
  serviceProviders: defaultListData,
  unitPrices: defaultListData,
  unitPriceItems: defaultListData,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_MARKETS:
    case constants.GET_MARKET:
    case constants.GET_SERVICE_PROVIDERS:
    case constants.GET_UNIT_PRICES:
    case constants.GET_UNIT_PRICE_ITEMS:
      return onLoading(state, action);
    case constants.GET_MARKETS_FAILURE:
    case constants.GET_MARKET_FAILURE:
    case constants.GET_SERVICE_PROVIDERS_FAILURE:
    case constants.GET_UNIT_PRICES_FAILURE:
    case constants.GET_UNIT_PRICE_ITEMS_FAILURE:
      return onFailure(state, action);
    case constants.GET_MARKETS_SUCCESS:
      return onGetMarketsSuccess(state, action);
    case constants.GET_SERVICE_PROVIDERS_SUCCESS:
      return onGetServiceProvidersSuccess(state, action);
    case constants.GET_UNIT_PRICES_SUCCESS:
      return onGetUnitPricesSuccess(state, action);
    case constants.GET_UNIT_PRICE_ITEMS_SUCCESS:
      return onGetUnitPriceItemsSuccess(state, action);
    case constants.GET_MARKET_SUCCESS:
      return onGetMarketSuccess(state, action);
    case constants.RESET_MARKET:
      return onResetMarket(state, action);
    default:
      return state;
  }
};

const onLoading = (state) => ({ 
  ...state, 
  isLoading: true,
  error: null
});

const onFailure = (state, error) => {
  return {
    ...state, 
    isLoading: false,
    error
  }
}

const onGetMarketsSuccess = (state, { data, pagination }) => {
  return {
    ...state,
    isLoading: false,
    markets: {
      ...state.markets,
      data,
      pagination,
    }
  }
}

const onGetMarketSuccess = (state, { data }) => {
  return {
    ...state,
    isLoading: false,
    single: data
  }
}

const onGetServiceProvidersSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: false,
  serviceProviders: {
    ...state.serviceProviders,
    data,
    pagination,
  }
})

const onGetUnitPricesSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: false,
  unitPrices: {
    ...state.unitPrices,
    data,
    pagination,
  }
})

const onGetUnitPriceItemsSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: false,
  unitPriceItems: {
    ...state.unitPriceItems,
    data,
    pagination,
  }
})

const onResetMarket = (state) => ({
  ...state,
  ...{
    markets: defaultListData,
    serviceProviders: defaultListData,
    unitPrices: defaultListData,
    unitPriceItems: defaultListData,
  }
})

export default reducer;
