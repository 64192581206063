import { useEffect, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';

const Input = forwardRef(({ 
  label, 
  min = 0, 
  max,
  placeholder, 
  onChange,
  defaultValue = "", 
  rows = 1, 
  name, 
  required = false,
  errors,
  register,
  rules,
  onClear = null,
  ...props 
}, ref) => {

  const textChanged = (e) => {
    if (props.type === 'number') {
      if (e.target.value < min) {
        onChange(e.target.id, min);
        e.target.value = min;
      } else if (max && e.target.value > max) {
        onChange(e.target.id, max);
        e.target.value = max;
      } else {
        onChange(e.target.id, e.target.value);
      }
    } else {
      onChange(e.target.id, e.target.value);
    }
  };

  const clear = (e) =>{
    if (ref && ref.current) {
      ref.current.value = '';
      onClear();
    } else {
      console.warn('The input you want to clear was not assigned a ref. Please pass a ref to your Input component.')
    }
  };

  return (
    <>
      {label && <label htmlFor={name} className="font-bold block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>}
      <div className="mt-1">
        {register && rules ?
          <>
            <div className="relative">
              <input
                ref={ref}
                name={name}
                placeholder={placeholder}
                className={`shadow-sm block w-full sm:text-sm rounded-md${errors ? ' border border-red-500 hover:border-red-500':' border-gray-300'}`}
                defaultValue={defaultValue}
                min={min}
                max={max}
                {...register(name, {...rules, onChange: textChanged})}
                {...props}
              />
              {onClear && defaultValue &&
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <button type="button" className="cursor-pointer flex items-center" onClick={clear}>
                    <FontAwesomeIcon icon={faTimesCircle} className="h-5 w-5 text-gray-400 hover:text-gray-800" aria-hidden="true" />
                  </button>
                </div>
              }
            </div>
            {errors && <div className="text-red-500">{errors.message}</div>}
          </>
          :
          <div className="relative">
            <input
              ref={ref}
              name={name}
              placeholder={placeholder}
              className={`shadow-sm block w-full sm:text-sm rounded-md${errors ? ' border border-red-500 hover:border-red-500':' border-gray-300'}`}
              defaultValue={defaultValue}
              onChange={textChanged}
              min={min}
              max={max}
              {...props}
            />
            {onClear && defaultValue && 
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <button type="button" className="cursor-pointer flex items-center" onClick={clear}>
                  <FontAwesomeIcon icon={faTimesCircle} className="h-5 w-5 text-gray-400 hover:text-gray-800" aria-hidden="true" />
                </button>
              </div>
            }
          </div>
        }
      </div>
    </>
  );
});

export default Input;
