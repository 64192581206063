import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Header, Footer } from '@components';
import { Router } from './Router';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import './App.css';
import "react-datepicker/dist/react-datepicker.css";
import { getItem, keys } from '@services/localStorage.service';
import { setToken, setReady } from '@store/auth/actions';
import { selectAuthToken, selectAuthReady } from '@store/auth/selector';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);
const noLayoutRoutes = [
  '/login',
  '/public',
  '/recuperation-mot-de-passe'
];

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showLayout, setShowLayout] = useState(false);
  const authReady = useSelector(selectAuthReady);

  useEffect(() => {
    initUser();
  }, []);
  
  useEffect(() => {
    const match = noLayoutRoutes.find(element => {
      return location.pathname.indexOf(element) !== -1
    });
    if (match) {
      setShowLayout(false);
    } else setShowLayout(true);
  }, [location]);

  const initUser = () => {
    const token = getItem(keys.TOKEN);
    if (token) {
      dispatch(setToken({ token }));
    } else dispatch(setReady());
  };

  return (
    <div className="App h-full">
      {showLayout && <Header />}
      {authReady && <Router />}
      {showLayout && <Footer />}
      <ToastContainer />
    </div>
  );
}

export default App;
