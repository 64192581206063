import * as constants from "./constants";

const initialState = {
  loading: false,
  error: null,
  list: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_WORK_ORDER_STATUS:
        case constants.CREATE_WORK_ORDER_STATUS:
          return onLoading(state, action);
        case constants.GET_WORK_ORDER_STATUS_FAILURE:
        case constants.CREATE_WORK_ORDER_STATUS_FAILURE:
          return onFailure(state, action);
        case constants.GET_WORK_ORDER_STATUS_SUCCESS:
          return onGetWorkOrderStatusSuccess(state, action);
        case constants.CREATE_WORK_ORDER_STATUS_SUCCESS:
          return onCreateWorkOrderStatusSuccess(state, action);
        default:
          return state;
    }
};

const onLoading = (state) => ({ 
  ...state, 
  loading: true,
  single: null,
  error: null,
});

const onFailure = (state, { error }) => ({ 
  ...state, 
  loading: false,
  error: error,
});

const onGetWorkOrderStatusSuccess = (state, { data }) => ({
  ...state, 
  loading: false,
  list: data,
  error: null,
});

const onCreateWorkOrderStatusSuccess = (state, { data }) => ({
  ...state, 
  loading: false,
  list: [
    data,
    ...state.list,
  ],
  error: null,
});

export default reducer;