import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Alert } from '@components';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Select = ({ 
  label,
  options = [], 
  name, 
  displayedProperty = 'uid',
  formatDisplay = null,
  selected = null, 
  placeholder = 'Sélectionnez une option', 
  onSelection,
  required = false,
  disabledMessage = null,
  ...props 
}) => {
  const [optionsFixed, setOptionsFixed] = useState(options);
  const [selectedValue, setSelectedValue] = useState(null);

  const getValue = (item) => {
    var splitted = displayedProperty.split('.');
    if (typeof(splitted) === 'object' && splitted.length > 1) {
      var selectedValue = null;
      for (let i = 0; i < splitted.length; i++) {
        if (!selectedValue) {
          selectedValue = item[splitted[i]];
        } else selectedValue = selectedValue[splitted[i]];
      }
      return selectedValue;
    } else {
      return item[displayedProperty];
    }
  };

  const getOptionsValues = () => {
    setOptionsFixed(options.map((option, key) => {
      if (formatDisplay) {
        option.optionValue = formatDisplay(option);
      } else {
        option.optionValue = getValue(option);
      }
      return option;
    }));
  };

  useEffect(() => {
    if (options.length) {
      getOptionsValues();
    } else if (optionsFixed.length > 0) {
      setOptionsFixed([]);
    }
  }, [options]);

  useEffect(() => {
    if (selected && selected !== selectedValue) {
      setSelectedValue(getValue(selected));
    } else setSelectedValue(null);
  }, [selected]);

  return (
    <>
      {label && <label htmlFor={name} className="font-bold block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>}

      <div className="mt-1 rounded-md">
        <Listbox value={selected} onChange={onSelection} {...props}>
          {({ open, disabled }) => (
            <>
              <div className="mt-1 relative">
                <Listbox.Button className={`${disabled ? 'form-item-disabled cursor-not-allowed bg-gray-200':'form-item-enabled'} relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left sm:text-sm`}>
                  <span className="block truncate">{selected ? selectedValue : placeholder}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto sm:text-sm">
                    {optionsFixed.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-primary' : 'text-gray-900',
                            'cursor-pointer select-none relative py-2 pl-3 pr-9'
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {option.optionValue}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        { props.disabled && disabledMessage && <Alert type="warning" text={disabledMessage}/>}
      </div>
    </>
  );
};

export default Select;
