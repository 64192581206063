import { takeLatest, put as dispatch } from "@redux-saga/core/effects";
import * as actions from "./actions";
import * as constants from "./constants";

import * as api from './api';


export function* patrimonySaga() {
  yield takeLatest(constants.GET_PROGRAMS, getPrograms);
  yield takeLatest(constants.GET_PROGRAM, getProgram);
  yield takeLatest(constants.GET_BUILDINGS, getBuildings);
  yield takeLatest(constants.GET_BUILDING, getBuilding);
  yield takeLatest(constants.GET_MODULES, getModules);
  yield takeLatest(constants.GET_MODULE, getModule);
  yield takeLatest(constants.GET_FLOOR, getFloor);
  yield takeLatest(constants.GET_STAIRCASE, getStaircase);
  yield takeLatest(constants.GET_MODULE_CONTRACT, getModuleContract);
}

function* getPrograms({ page, size }) {
  try {
    const { data: payget } = yield api.programs({ page, size });
    yield dispatch(actions.getProgramsSuccess(payget));
  } catch (e) {
    yield dispatch(actions.getProgramsFailure(e));
  }
}

function* getProgram({ uid, include = [] }) {
  try {
    const { data: program } = yield api.program({ uid, include });
    yield dispatch(actions.getProgramSuccess({ program }));
  } catch (e) {
    yield dispatch(actions.getProgramFailure(e));
  }
}

function* getBuildings({ page, size }) {
  try {
    const { data: payget } = yield api.buildings({ page, size });
    yield dispatch(actions.getBuildingsSuccess(payget));
  } catch (e) {
    yield dispatch(actions.getBuildingsFailure(e));
  }
}

function* getBuilding({ uid, include = [] }) {
  try {
    const { data: building } = yield api.building({ uid, include });
    yield dispatch(actions.getBuildingSuccess({ building }));
  } catch (e) {
    yield dispatch(actions.getBuildingFailure(e));
  }
}

function* getModules({ page, size, moduleType, search }) {
  try {
    const { data: payget } = yield api.modules({ page, size, moduleType, search });
    yield dispatch(actions.getModulesSuccess(payget));
  } catch (e) {
    yield dispatch(actions.getModulesFailure(e));
  }
}

function* getModule({ uid, include = [] }) {
  try {
    const { data: module } = yield api.module({ uid, include });
    yield dispatch(actions.getModuleSuccess({ module }));
  } catch (e) {
    yield dispatch(actions.getModuleFailure(e));
  }
}

function* getFloor({ uid, include = [] }) {
  try {
    const { data: floor } = yield api.floor({ uid, include });
    yield dispatch(actions.getFloorSuccess({ floor }));
  } catch (e) {
    yield dispatch(actions.getFloorFailure(e));
  }
}

function* getStaircase({ uid, include = [] }) {
  try {
    const { data: staircase } = yield api.staircase({ uid, include });
    yield dispatch(actions.getStaircaseSuccess({ staircase }));
    return staircase;
  } catch (e) {
    yield dispatch(actions.getStaircaseFailure(e));
  }
}

function* getBulding({ uid, include = [] }) {
  try {
    const { data: building } = yield api.building({ uid, include });
    yield dispatch(actions.getBuildingSuccess({ building }));
    return building;
  } catch (e) {
    yield dispatch(actions.getBuildingFailure(e));
  }
}

function* getModuleContract({ moduleUid, include = [] }) {
  try {
    const { data } = yield api.currentContract({ moduleUid: moduleUid, include });
    yield dispatch(actions.getModuleContractSuccess(data));
    return data;
  } catch (e) {
    yield dispatch(actions.getModuleContractFailure(e));
  }
}