import * as constants from "./constants";

const initialState = {
  loading: false,
  error: null,
  programs: {
    data: [],
    pagination: {
      page: 1,
      total: null,
      count: null,
      next: null,
      previous: null,
    }
  },
  staircases: {
    data: [],
    pagination: {
      page: 1,
      total: null,
      count: null,
      next: null,
      previous: null,
    }
  },
  buildings: {
    data: [],
    pagination: {
      page: 1,
      total: null,
      count: null,
      next: null,
      previous: null,
    }
  },
  modules: {
    data: [],
    pagination: {
      page: 1,
      total: null,
      count: null,
      next: null,
      previous: null,
    }
  },
  selected: {
    program: null,
    building: null,
    staircase: null,
    floor: null,
    module: null,
  },
  currentContract: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
      case constants.GET_PROGRAMS:
      case constants.GET_PROGRAM:
      case constants.GET_BUILDINGS:
      case constants.GET_BUILDING:
      case constants.GET_MODULES:
      case constants.GET_MODULE:
      case constants.GET_STAIRCASES:
      case constants.GET_STAIRCASE:
      case constants.GET_FLOORS:
      case constants.GET_FLOOR:
      case constants.GET_MODULE_CONTRACT:
        return onLoading(state, action);
      case constants.GET_PROGRAMS_FAILURE:
      case constants.GET_PROGRAM_FAILURE:
      case constants.GET_BUILDINGS_FAILURE:
      case constants.GET_BUILDING_FAILURE:
      case constants.GET_MODULES_FAILURE:
      case constants.GET_MODULE_FAILURE:
      case constants.GET_STAIRCASE_FAILURE:
      case constants.GET_STAIRCASES_FAILURE:
      case constants.GET_FLOORS_FAILURE:
      case constants.GET_FLOOR_FAILURE:
      case constants.GET_MODULE_CONTRACT_FAILURE:
        return onFailure(state, action);
      case constants.GET_PROGRAMS_SUCCESS:
        return onLoadProgramsSuccess(state, action);
      case constants.GET_BUILDINGS_SUCCESS:
        return onLoadBuildingsSuccess(state, action);
      case constants.GET_MODULES_SUCCESS:
        return onLoadModulesSuccess(state, action);
      case constants.GET_MODULE_SUCCESS:
        return onLoadModuleSuccess(state, action);
      case constants.GET_FLOOR_SUCCESS:
        return onLoadFloorSuccess(state, action);
      case constants.GET_STAIRCASE_SUCCESS:
        return onLoadStaircaseSuccess(state, action);
      case constants.GET_BUILDING_SUCCESS:
        return onLoadBuildingSuccess(state, action);
      case constants.GET_PROGRAM_SUCCESS:
        return onLoadProgramSuccess(state, action);
      case constants.REMOVE_MODULE:
        return removeModule(state);
      case constants.REMOVE_SELECTED_KEYS:
        return onRemoveSelectedKeys(state, action);
      case constants.GET_MODULE_CONTRACT_SUCCESS:
        return onGetModuleContractSuccess(state, action);
      case constants.RESET_PATRIMONY:
        return onResetPatrimony(state, action);
      default:
        return state;
  }
};

const onLoading = (state) => ({ 
  ...state, 
  loading: true,
  error: null
});

const onFailure = (state, error) => ({
  ...state, 
  loading: false,
  error
});

const onLoadProgramsSuccess = (state, { data, pagination }) => ({
  ...state,
  loading: false,
  programs: {
    ...state.programs,
    data,
    pagination,
  }
})

const onLoadBuildingsSuccess = (state, { data, pagination }) => ({
  ...state,
  loading: false,
  buildings: {
    ...state.buildings,
    data,
    pagination,
  }
})

const onLoadModulesSuccess = (state, { data, pagination }) => ({
  ...state,
  loading: false,
  modules: {
    ...state.modules,
    data,
    pagination,
  }
})

const onLoadModuleSuccess = (state, { module: moduleData }) => ({
  ...state,
  loading: false,
  selected: {
    module: moduleData
  }
})

const onLoadFloorSuccess = (state, { floor }) => ({
  ...state,
  loading: false,
  selected: {
    ...state.selected,
    floor
  }
})

const onLoadStaircaseSuccess = (state, { staircase }) => ({
  ...state,
  loading: false,
  selected: {
    ...state.selected,
    staircase,
  }
})

const onLoadBuildingSuccess = (state, { building }) => ({
  ...state,
  loading: false,
  selected: {
    ...state.selected,
    building,
  }
})

const onLoadProgramSuccess = (state, { program }) => ({
  ...state,
  loading: false,
  selected: {
    ...state.selected,
    program,
  }
})

const removeModule = (state) => ({
  ...state,
  selected: {
    ...state.selected,
    module: null,
  }
})

const removeFloor = (state) => ({
  ...state,
  selected: {
    ...state.selected,
    floor: null,
  }
})

const onRemoveSelectedKeys = (state, { keys }) => {
  let selected = state.selected;
  keys.forEach(key => {
    selected[key] = null;
  });
  return {
    ...state,
    selected: selected
  }
}

const onGetModuleContractSuccess = (state, { data }) => ({
  ...state,
  loading: false,
  currentContract: data
});


const onResetPatrimony = (state) => ({
  ...state,
  loading: false,
  // selected: {
  //   program: null,
  //   building: null,
  //   staircase: null,
  //   floor: null,
  //   module: null,
  // },
  currentContract: null,
});

export default reducer;
